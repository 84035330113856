import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FolderIcon } from "@heroicons/react/24/solid";
import * as yup from "yup";
import { AccountInfo, Location } from "../../../services/api";
import Loading from "../../../components/Loading";
import { LocationSelector } from "./Inputs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useApi } from "../../../App";
import { classNames } from "../../../util/general";
import { notifications } from "@mantine/notifications";

export default function DefaultProjectModal({
  open,
  setOpen,
  locations,
  account,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  locations?: Location[];
  account?: AccountInfo;
}) {
  const api = useApi();
  const cancelButtonRef = useRef(null);
  const [saving, setSaving] = useState(false);

  const validationSchema = yup.object().shape({
    incomesProject: yup.number().nullable(),
    expensesProject: yup.number().nullable(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      incomesProject:
        locations?.find(
          (location) => location.ProjectId === account?.IncomesProjectId
        )?.Id ?? 0,
      expensesProject:
        locations?.find(
          (location) => location.ProjectId === account?.ExpensesProjectId
        )?.Id ?? 0,
    },
  };

  const { setValue, handleSubmit, watch } = useForm(formOptions);

  async function onSubmit(data: { [x: string]: any }) {
    setSaving(true);

    const { incomesProject, expensesProject } = data;

    const incomesProjectId =
      incomesProject === 0
        ? undefined
        : locations?.find((location) => location.Id === incomesProject)
            ?.ProjectId;

    const expensesProjectId =
      expensesProject === 0
        ? undefined
        : locations?.find((location) => location.Id === expensesProject)
            ?.ProjectId;

    const updateResult = await api.subjects.subjectSetDefaultProjects(
      account!.Id,
      {
        incomesProjectId,
        expensesProjectId,
      }
    );

    if (updateResult.ok) {
      setSaving(false);
      setOpen(false);

      notifications.show({
        color: "#22c55e",
        title: "Povedlo se!",
        message: "Výchozí složky pro zařazování byly uloženy.",
      });
    } else {
      console.error(updateResult.error);

      notifications.show({
        color: "#dc2626",
        title: "Něco se nepovedlo.",
        message: updateResult.error?.message,
      });
    }
  }

  if (!locations || !account) {
    return (
      <div className="flex justify-center my-12">
        <Loading />
      </div>
    );
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 text-left transition-all transform bg-white shadow-xl overflow-shown sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="sm:flex sm:items-start">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FolderIcon
                        className="w-6 h-6 text-sky-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-neutral-900"
                      >
                        Nastavení výchozích složek pro zařazování
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-neutral-500">
                          Tady si můžete pro účet{" "}
                          <span className="font-bold">{account.Name}</span>{" "}
                          nastavit výchozí složky pro automatické zařazování
                          příjmů a výdajů ze zpracovaných vyúčtování.
                        </p>
                      </div>

                      <div className="flex flex-col flex-1 my-8 space-y-4">
                        <dl className="flex items-start justify-between w-full">
                          <dt className="block py-2 text-sm font-medium text-neutral-700">
                            Příjmy
                          </dt>
                          <dd className="w-64">
                            <LocationSelector
                              valueKey="incomesProject"
                              locations={locations}
                              watch={watch}
                              setValue={setValue}
                            />
                          </dd>
                        </dl>

                        <dl className="flex items-start justify-between w-full">
                          <dt className="block py-2 text-sm font-medium text-neutral-700">
                            Výdaje
                          </dt>
                          <dd className="w-64">
                            <LocationSelector
                              valueKey="expensesProject"
                              locations={locations}
                              watch={watch}
                              setValue={setValue}
                            />
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      disabled={saving}
                      className={classNames(
                        saving ? "cursor-not-allowed" : "cursor-pointer",
                        "inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                      )}
                    >
                      {saving && <Loading />}
                      Uložit
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zpět
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
