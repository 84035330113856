import { DocumentHeader } from "./api";

const documentExpr = /(\d{8}_\d+(-\d{2})?)_.{3}(_\d)?\.[^?]+/g;

export function filenameFromLink(fileLink: string) {
  const filename = fileLink.match(documentExpr);

  if (!filename) {
    return "Přidaný dokument";
  }

  return filename[0];
}

export function isValidLink(submission: string) {
  return (
    submission.includes("www.dropbox.com") &&
    submission.match(documentExpr) !== null
  );
}

export function isUniqueDocumentLink(filenames: string[], fileLink: string) {
  return !filenames.includes(filenameFromLink(fileLink));
}
