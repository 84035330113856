import React, { useEffect, useState } from "react";
import { Decimal } from "decimal.js";
import { useApi } from "../../../App";
import { QueryRefetch } from "../../../util/api";
import { useForm, useFieldArray } from "react-hook-form";
import {
  GhostAffiliation,
  Location,
  TransactionToApprove,
} from "../../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  LocationSelector,
  AmountInput,
  TitleInput,
  VATSelector,
} from "../../transactions/components/Inputs";
import Loading from "../../../components/Loading";
import { classNames } from "../../../util/general";
import { XCircleIcon, PlusIcon } from "@heroicons/react/24/solid";

const affilToGhost = (affiliation: any): GhostAffiliation => ({
  LocationId: affiliation.location,
  Amount: affiliation.amount.replace(",", "."),
  Title: affiliation.title,
  VATDeduction: affiliation.vatrate,
});

const ghostToAffil = (affiliation: GhostAffiliation) => ({
  location: affiliation.LocationId,
  amount: affiliation.Amount.replace(".", ","),
  title: affiliation.Title,
  vatrate: affiliation.VATDeduction,
});

const ApproveForm = ({
  refetch,
  affiliations,
  locations,
  transaction,
}: {
  refetch: QueryRefetch<any>;
  affiliations: GhostAffiliation[];
  locations: Location[];
  transaction: TransactionToApprove;
}) => {
  const api = useApi();
  const [sumError, setSumError] = useState(false);
  const [saving, setSaving] = useState(false);

  const validationSchema = yup.object().shape({
    affiliations: yup.array().of(
      yup.object().shape({
        location: yup.number().required().moreThan(0),
        amount: yup
          .string()
          .required()
          .matches(/^-?([0-9] ?)(\d ?)*(,\d+)?$/),
        title: yup.string().required(),
        vatrate: yup.number().required(),
      })
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const {
    watch,
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);
  const { fields, append, remove } = useFieldArray({
    name: "affiliations",
    control,
  });

  function addAffiliation() {
    append({ location: 0, amount: "", title: "", vatrate: 0 });
  }

  useEffect(() => {
    reset({
      affiliations: affiliations.map(ghostToAffil),
    });
  }, [affiliations]);

  async function onSubmit({ affiliations }: { [x: string]: any }) {
    setSaving(true);

    const affiliationSum = affiliations.reduce(
      (sum: Decimal, { amount }: { amount: string }) =>
        sum.plus(new Decimal(amount.replace(",", "."))),
      new Decimal(0)
    );

    if (
      affiliationSum.toDP(2).cmp(new Decimal(0)) !== 0 &&
      affiliationSum.toDP(2).cmp(new Decimal(transaction.Amount)) !== 0 &&
      !sumError
    ) {
      setSaving(false);
      setSumError(true);

      return;
    }

    const affilResult = await api.transactions.putAffiliations(transaction.Id, {
      affiliations: affiliations.map(affilToGhost),
      lockTransaction: true,
    });

    if (affilResult.ok) {
      refetch();
      setSaving(false);
    }
  }

  return (
    <form
      className={classNames("col-span-24 px-8 bg-neutral-50")}
      onSubmit={handleSubmit(onSubmit)}
    >
      {fields.map((item, i) => (
        <div
          key={item.id}
          className="grid w-full grid-cols-12 col-span-6 py-1 first:pt-8 gap-x-4 bg-neutral-50"
        >
          <div className="col-span-4">
            <LocationSelector
              locations={locations}
              rowIndex={i}
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
              disabled={false}
            />
          </div>
          <div className="col-span-3">
            <TitleInput
              rowIndex={i}
              register={register}
              errors={errors}
              disabled={false}
            />
          </div>
          <div className="col-span-2">
            <AmountInput
              rowIndex={i}
              currency={transaction.Currency}
              amount={transaction.Amount}
              register={register}
              setValue={setValue}
              errors={errors}
              disabled={false}
            />
          </div>

          <div className="grid grid-cols-3 col-span-3">
            <VATSelector
              rowIndex={i}
              watch={watch}
              setValue={setValue}
              disabled={false}
            />
            <div className="flex justify-end">
              <div className="flex flex-col">
                <button
                  type="button"
                  className={classNames(
                    "cursor-pointer hover:bg-neutral-200",
                    "inline-flex items-center p-1 mt-auto mb-1 ml-4 bg-transparent border border-transparent rounded-full text-neutral-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                  )}
                  onClick={() => remove(i)}
                >
                  <XCircleIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="flex justify-between col-span-8 py-8">
        <div className="space-x-2 flex-items-center">
          <button
            className={classNames(
              "cursor-pointer hover:bg-neutral-50",
              "inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm  text-neutral-700 border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
            )}
            type="button"
            onClick={addAffiliation}
          >
            <PlusIcon className="w-4 h-4 mr-2 text-neutral-700" />
            Přidat řádek
          </button>
        </div>

        <div className="flex items-center space-x-2">
          {sumError && (
            <span className="text-sm text-red-600">
              Součet částek neodpovídá transakci. Pokud to nevadí, ulož znovu.
            </span>
          )}

          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-green-700 border border-transparent shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700"
          >
            {saving && <Loading />}
            Schválit transakci
          </button>
        </div>
      </div>
    </form>
  );
};

export default ApproveForm;
