import React, { useState } from "react";
import { useApi } from "../../../App";
import {
  ArticleInsufficientWarehouseWithStatus,
  warehouseUpdatedMessage,
} from "../../../util/general";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { NumericInput } from "../../catalogue/components/NumericInput";
import { MagicNumericInput } from "./MagicNumericInput";
import { notifications } from "@mantine/notifications";

export function InsufficientStocksRow({
  details,
  updateWarehouseRowState,
}: {
  details: ArticleInsufficientWarehouseWithStatus;
  updateWarehouseRowState: (id: number) => void;
}) {
  const api = useApi();
  const [innerRowState, setInnerRowState] = useState<
    "waiting" | "saving" | "done"
  >("waiting");
  const [submittedValues, setSubmittedValues] = useState<{
    price: number;
    amount: number;
  } | null>(null);

  const validationSchema = yup.object().shape({
    price: yup.number().required().min(0),
    amount: yup.number().required().min(details.$Missing),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: { price: 0, amount: details.$Missing },
  };

  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(formOptions);

  async function onSubmit(data: { [x: string]: any }) {
    const { price, amount } = data;

    setInnerRowState("saving");
    setSubmittedValues({ price: parseFloat(price), amount: parseInt(amount) });

    const submitResult = await api.warehouse.warehouseCreateSingle({
      articleId: details.Id,
      stock: amount,
      buyingPrice: price.toString(),
    });

    if (submitResult.ok) {
      setInnerRowState("done");

      updateWarehouseRowState(details.Id);
    } else {
      setInnerRowState("waiting");

      notifications.show({
        color: "#dc2626",
        title: "Naskladnění se nezdařilo.",
        message: submitResult.error,
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-12">
      <div className="flex justify-between col-span-5 px-4 py-3 text-sm font-medium sm:pl-6">
        <div className="pr-3">
          <div className="text-neutral-900">{details.ArticleName}</div>
          <div className="text-neutral-500">{details.OwnerName}</div>
        </div>
      </div>
      <div className="flex flex-col justify-center col-span-1 px-4 py-3 border-r border-neutral-300">
        {innerRowState === "done" ? (
          <span className="text-sm font-bold text-right text-neutral-900">
            0
          </span>
        ) : (
          <span className="text-sm font-bold text-right text-red-700">
            {details.$Missing}
          </span>
        )}
      </div>
      {innerRowState === "done" ? (
        <div className="flex flex-col justify-center col-span-6 px-4 py-3">
          <div className="text-sm text-neutral-900">
            {warehouseUpdatedMessage(
              submittedValues?.amount,
              submittedValues?.price
            )}
          </div>
        </div>
      ) : (
        <div className="flex col-span-6 px-4 py-3 space-x-2">
          <div className="flex-1">
            <NumericInput
              valueKey="amount"
              watch={watch}
              register={register}
              errors={errors}
            />
          </div>
          <div className="flex-1">
            <MagicNumericInput
              valueKey="price"
              watch={watch}
              register={register}
              errors={errors}
              setValue={setValue}
              articleId={details.Id}
            />
          </div>
          <div className="flex-1">
            <button
              type="submit"
              disabled={innerRowState === "saving"}
              className="inline-flex items-center justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:text-sm"
            >
              {innerRowState === "saving" ? (
                <svg
                  className={"animate-spin h-5 w-5"}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <span>Naskladnit</span>
              )}
            </button>
          </div>
        </div>
      )}
    </form>
  );
}
