import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import {
  DistributionFragmentTSOA,
  DistributionFragmentTypeTSOA,
  Subject,
} from "../../../services/api";
import { usePopper } from "react-popper";

const DistributionPopover = ({
  distribution,
}: {
  distribution: (DistributionFragmentTSOA & { Subject: Subject })[];
}) => {
  let [referenceElement, setReferenceElement] = useState<Element | null>();
  let [popperElement, setPopperElement] = useState<HTMLElement | null>();

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "left",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="underline cursor-pointer text-neutral-500 hover:no-underline focus:outline-none"
          >
            Rozdělení
          </Popover.Button>

          {open && (
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              static
              className="absolute z-10 px-4 mt-3 transform -translate-x-1/2 translate-y-0 border opacity-100  w-96 border-neutral-200 sm:px-0"
            >
              <div className="relative flex flex-col px-3 py-4 space-y-4 bg-white ">
                {distribution.some(
                  (d) => d.Type === DistributionFragmentTypeTSOA.MERCH
                ) && (
                  <div className="flex flex-col">
                    <div className="flex items-center w-full px-2 mb-3 space-x-3">
                      <h3 className="text-lg font-medium text-left truncate text-neutral-900">
                        Dělení nákladů
                      </h3>
                      <div className="flex-1 h-px border-b border-neutral-300"></div>
                    </div>

                    {distribution
                      .filter(
                        (d) => d.Type === DistributionFragmentTypeTSOA.MERCH
                      )
                      .map(({ Id, Percentage, Subject: { Name } }) => (
                        <div
                          key={Id}
                          className="flex justify-between px-2 py-0.5 transition duration-150 ease-in-out  hover:bg-neutral-50 focus:outline-none"
                        >
                          <div className="flex items-center space-x-1">
                            <span className="font-medium">{Name}</span>
                          </div>
                          <span className="font-medium">
                            {Percentage}
                            {" %"}
                          </span>
                        </div>
                      ))}
                  </div>
                )}

                <div className="flex flex-col">
                  <div className="flex items-center w-full px-2 mb-3 space-x-3">
                    <h3 className="text-lg font-medium text-left truncate text-neutral-900">
                      Dělení výnosů
                    </h3>
                    <div className="flex-1 h-px border-b border-neutral-300"></div>
                  </div>

                  {distribution
                    .filter(
                      (d) => d.Type === DistributionFragmentTypeTSOA.INCOME
                    )
                    .map(({ Id, Percentage, Subject: { Name }, Role }) => (
                      <div
                        key={Id}
                        className="flex justify-between px-2 py-0.5 transition duration-150 ease-in-out  hover:bg-neutral-50 focus:outline-none"
                      >
                        <div className="flex items-center space-x-1">
                          <span className="font-medium">{Name}</span>
                          <span className="font-normal text-neutral-400">
                            {Role}
                          </span>
                        </div>
                        <span className="font-medium">
                          {Percentage}
                          {" %"}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};

export default DistributionPopover;
