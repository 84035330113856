import React, { useState } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../../App";
import { classNames } from "../../../util/general";
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { ArrowUturnUpIcon } from "@heroicons/react/24/solid";

export const MagicNumericInput = ({
  valueKey,
  register,
  errors,
  articleId,
  setValue,
}: {
  valueKey: string;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: any;
  };
  articleId: number;
  setValue: UseFormSetValue<FieldValues>;
}) => {
  const api = useApi();
  const [focused, setFocused] = useState(false);
  const [fetch, setFetch] = useState(false);

  const { isLoading } = useQuery(
    ["warehouse", articleId],
    async () => await api.warehouse.warehouseGetAll({ articleId }),
    {
      select: (x) => x.data,
      enabled: fetch,
      onSuccess: (x) => {
        setValue(
          valueKey,
          parseFloat(
            x[0]?.warehouse.findLast((x) => x.Direction === "IN")
              ?.BuyingPrice || "0"
          ) || 0
        );

        setFetch(false);
      },
    }
  );

  return (
    <>
      <div
        className={classNames(
          errors[valueKey] === undefined
            ? classNames(
                "border-neutral-300",
                focused && "ring-neutral-900 border-neutral-900 ring-1"
              )
            : classNames(
                "border-red-300 text-red-900",
                focused && "ring-red-500 border-red-500 ring-1"
              ),
          "flex border shadow-sm overflow-clip pl-3 pr-10 relative"
        )}
      >
        <input
          type="text"
          id={valueKey}
          {...register(valueKey)}
          onBlur={() => setFocused(false)}
          onFocus={(event) => {
            setFocused(true);
            event.target.select();
          }}
          className={classNames(
            "block flex-1 border-0 ring-transparent focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm px-0"
          )}
        />
        <button
          type="button"
          title="Použít cenu z posledního naskladnění"
          className="absolute inset-y-0 right-0 flex items-center px-2 hover:bg-neutral-50 text-neutral-400 hover:text-neutral-700"
          onClick={() => setFetch(true)}
        >
          {isLoading ? (
            <svg
              className={"animate-spin h-5 w-5"}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <ArrowUturnUpIcon className="w-5 h-5" aria-hidden="true" />
          )}
        </button>
      </div>
    </>
  );
};
