import React, { Fragment } from "react";
import { Listbox, Transition, Label } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../util/general";

import {
  BillingSourceName,
  BillingSourceNames,
  BillingSourceCurrencies,
} from "../../../util/enums";

interface BillingSourceSelectorProps {
  selected?: string;
  setSelected: (val?: string) => void;
  currency: string;
  onChangeFn?: () => void;
}

export default function BillingSourceSelector({
  selected = "",
  setSelected,
  currency,
  onChangeFn,
}: BillingSourceSelectorProps) {
  return (
    <Listbox
      value={selected}
      onChange={(v) => {
        setSelected(v);
        onChangeFn?.();
      }}
    >
      {({ open }) => (
        <div className="flex flex-col w-full">
          <Label className="block text-sm font-medium text-neutral-700">
            Zdroj příjmů
          </Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default border-neutral-300 focus:outline-none focus:ring-1 focus:ring-black focus:border-black sm:text-sm">
              <span className="block truncate">
                {BillingSourceNames[
                  selected as keyof typeof BillingSourceNames
                ] || "\u00A0"}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-neutral-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {Object.entries(BillingSourceName)
                  .filter(
                    ([a]) =>
                      BillingSourceCurrencies[
                        a as keyof typeof BillingSourceCurrencies
                      ] === currency
                  )
                  .map(([key, value], index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-sky-600" : "text-neutral-900",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={key}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {value}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-sky-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
