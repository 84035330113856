import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import type {
  UseFormSetValue,
  UseFormWatch,
  FieldValues,
} from "react-hook-form";
import { Location } from "../../../services/api";
import { classNames } from "../../../util/general";

import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/24/solid";

export const LocationSelector = ({
  valueKey,
  locations,
  watch,
  setValue,
}: {
  valueKey: string;
  locations: Location[];
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}) => {
  const locationId = watch(valueKey);
  const onLocationChange = (val: Location) => setValue(valueKey, val.Id);

  const displayTitle = (id: number) => {
    const location = locations.find((location) => location.Id === id);

    if (!location) {
      return;
    }

    const { Title } = location;

    if (location.Parent !== undefined) {
      return `${
        locations.find((parent) => location.Parent === parent.SubjectId)!.Title
      }: ${Title}`;
    }

    return Title;
  };

  return (
    <Listbox value={locationId} onChange={onLocationChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={classNames(
                "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900",

                "relative w-full py-2 pl-3 pr-10 text-left bg-white border  shadow-sm cursor-default focus:outline-none focus:ring-1 sm:text-sm"
              )}
            >
              <span className="block truncate">{displayTitle(locationId)}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-neutral-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {locations.map(
                  (location) =>
                    location.Id !== 0 && (
                      <Listbox.Option
                        key={location.Id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-sky-500"
                              : "text-neutral-900",
                            "cursor-default select-none relative py-1.5 pl-3 pr-9 flex"
                          )
                        }
                        value={location}
                      >
                        {({ active }) => (
                          <>
                            {location.Parent !== undefined && "└\u00A0\u00A0"}

                            <span
                              className={classNames(
                                location.Id === locationId
                                  ? "font-semibold"
                                  : "font-normal",
                                "block truncate"
                              )}
                            >
                              {location.Title}
                            </span>
                            {location.Id === locationId ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-sky-500",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
