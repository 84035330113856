import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../../App";
import Loading from "../../../components/Loading";
import TransactionRow from "../../transactions/components/TransactionRow";
import {
  ArrowPathIcon,
  CheckBadgeIcon,
  InformationCircleIcon,
  QueueListIcon,
} from "@heroicons/react/24/solid";
import Pagination from "../../transactions/components/Pagination";
import { classNames } from "../../../util/general";
import { useNavigate } from "react-router-dom";
import { declineTransactions } from "../../../util/transactions";

const Approvals = () => {
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    window.document.title = "Ke schválení — Accountant";
  }, []);

  const { data: locations, isLoading: isLoadingLocations } = useQuery(
    ["locations"],
    async () => await api.locations.getLocations(),
    { select: (x) => x.data.locations }
  );

  const {
    data: transactionsWrapper,
    isLoading: isLoadingTransactions,
    isFetching,
    refetch,
  } = useQuery(
    ["transactions"],
    async () =>
      await api.transactions.getTransactions([], { state: "PENDING" }),
    { select: (x) => x.data, enabled: false }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="flex flex-col flex-1 pt-10 pb-16 bg-neutral-100">
      <main className="flex-1">
        <div className="h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
          <div className="flex items-end justify-between mt-6 mb-10">
            <div className="flex space-x-2">
              <button
                className={
                  "inline-flex items-center pl-3 pr-4 py-2 text-sm font-medium text-white border border-transparent  shadow-sm bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                }
                onClick={() => navigate("/approve/queue")}
              >
                <QueueListIcon className="w-4 h-4 mr-2 text-white" />
                Přejít do fronty
              </button>

              {/* <button
                className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
                onClick={() => {}}
              >
                tady by mel byt jeste confirmation modal
                Schválit všechny transakce
              </button> */}
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => refetch()}
                title="Obnovit"
                className="inline-flex items-center p-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
              >
                <ArrowPathIcon
                  className={classNames(
                    isFetching && "animate-spin",
                    "w-4 h-4 text-neutral-900"
                  )}
                />
              </button>
              <div className="inline-flex py-2 pl-3 pr-4 text-sm font-medium bg-white shadow text-neutral-900">
                <InformationCircleIcon className="w-5 h-5 mr-2 text-neutral-700" />

                <div>
                  {transactionsWrapper?.page.totalRecords ??
                    "zatím nevím kolik"}
                  {` ${declineTransactions(
                    transactionsWrapper?.page.totalRecords!
                  )} ke schválení`}
                </div>
              </div>
            </div>
          </div>

          {isLoadingLocations ||
          isLoadingTransactions ||
          locations === undefined ||
          transactionsWrapper?.transactions === undefined ? (
            <div className="flex justify-center">
              <Loading />
            </div>
          ) : (
            <div className="mb-8">
              <div className="overflow-visible border-b shadow border-neutral-200 ">
                <div className="grid w-full grid-cols-24">
                  <div className="flex items-center justify-start col-span-2 px-4 text-xs font-medium tracking-wider uppercase border-b rounded-tl-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    ID
                  </div>
                  <div className="flex items-center justify-start col-span-2 px-4 text-xs font-medium tracking-wider uppercase border-b text-neutral-500 border-neutral-200 bg-neutral-50">
                    Datum
                  </div>
                  <div className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase border-b col-span-14 text-neutral-500 border-neutral-200 bg-neutral-50">
                    Transakce
                  </div>
                  <div className="col-span-3 py-3 text-xs font-medium tracking-wider text-right uppercase border-b rounded-tl-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    Částka
                  </div>
                  <div className="flex items-center justify-end col-span-3 px-4 text-xs font-medium tracking-wider text-right uppercase border-b rounded-tr-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    Zařazení
                  </div>
                </div>
                <div className="w-full">
                  {transactionsWrapper.transactions
                    .flatMap(({ transactions }) => transactions)
                    .map((transaction, index) => (
                      <TransactionRow
                        stateIcons={false}
                        disabled={false}
                        key={transaction.Id}
                        refetch={refetch}
                        locations={locations}
                        transaction={transaction}
                        isLastRow={
                          index === transactionsWrapper.transactions.length - 1
                        }
                        approvalMode={true}
                      />
                    ))}
                </div>
                {transactionsWrapper.transactions.length !== 0 && (
                  <Pagination info={transactionsWrapper.page} />
                )}
              </div>

              {transactionsWrapper.transactions.length === 0 && (
                <div className="px-6 py-12 overflow-hidden text-center bg-white shadow rounded-b-lgg">
                  <CheckBadgeIcon className="w-12 h-12 mx-auto text-neutral-400" />

                  <h3 className="mt-2 text-sm font-medium text-neutral-900">
                    Všechno jsi schválil.
                  </h3>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Approvals;
