import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useApi } from "../../../App";
import { ExactPeriod } from "../../../services/api";
import Loading from "../../../components/Loading";
import TransactionRow from "./TransactionRow";
import Filters from "./Filters";
import Pagination from "./Pagination";
import { Bars4Icon } from "@heroicons/react/24/solid";
import { getAuth } from "firebase/auth";
import { whitelist } from "../../../util/authWhitelist";

const Transactions = () => {
  const auth = getAuth();
  const api = useApi();
  const [transactionsQuery, setTransactionsQuery] = useState<{
    timeframes: ExactPeriod[];
    filterNote?: string;
    assigned?: boolean;
    documents?: boolean;
  }>({ timeframes: [] });

  const filtersActive =
    transactionsQuery.timeframes.length ||
    transactionsQuery.filterNote !== undefined ||
    transactionsQuery.assigned !== undefined ||
    transactionsQuery.documents !== undefined;

  const { page } = useParams();
  useEffect(() => {
    window.document.title = "Přehled transakcí — Accountant";
  }, []);

  const { data: locations, isLoading: isLoadingLocations } = useQuery(
    ["locations"],
    async () => await api.locations.getLocations(),
    { select: (x) => x.data.locations }
  );

  const {
    data: transactionsWrapper,
    isLoading: isLoadingTransactions,
    refetch,
  } = useQuery(
    ["transactions", transactionsQuery, page],
    async () =>
      await api.transactions.getTransactions(transactionsQuery.timeframes, {
        ...transactionsQuery,
        page: filtersActive ? undefined : parseInt(page || "1"),
      }),
    {
      select: (x) => x.data,
    }
  );

  return (
    <div className="flex flex-col flex-1 pt-10 pb-16 bg-neutral-100">
      <main className="flex-1">
        <div className="h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
          <Filters setQuery={setTransactionsQuery} />

          {isLoadingLocations ||
          isLoadingTransactions ||
          locations === undefined ||
          transactionsWrapper?.transactions === undefined ? (
            <div className="flex justify-center">
              <Loading />
            </div>
          ) : (
            <div className="mb-8">
              <div className="overflow-visible border-b shadow border-neutral-200 ">
                <div className="grid w-full grid-cols-24">
                  <div className="flex items-center justify-start col-span-2 px-4 text-xs font-medium tracking-wider uppercase border-b rounded-tl-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    ID
                  </div>
                  <div className="flex items-center justify-start col-span-2 px-4 text-xs font-medium tracking-wider uppercase border-b text-neutral-500 border-neutral-200 bg-neutral-50">
                    Datum
                  </div>
                  <div className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase border-b col-span-14 text-neutral-500 border-neutral-200 bg-neutral-50">
                    Transakce
                  </div>
                  <div className="col-span-3 py-3 text-xs font-medium tracking-wider text-right uppercase border-b rounded-tl-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    Částka
                  </div>
                  <div className="flex items-center justify-end col-span-3 px-4 text-xs font-medium tracking-wider text-right uppercase border-b rounded-tr-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    Zařazení
                  </div>
                </div>
                <div className="w-full">
                  {transactionsWrapper.transactions
                    .flatMap(({ transactions }) => transactions)
                    .map((transaction, index) => (
                      <TransactionRow
                        disabled={
                          transaction.State !== "DEFAULT" &&
                          !whitelist.includes(auth.currentUser?.email || "")
                        }
                        key={transaction.Id}
                        refetch={refetch}
                        locations={locations}
                        transaction={transaction}
                        isLastRow={
                          index === transactionsWrapper.transactions.length - 1
                        }
                        approvalMode={false}
                      />
                    ))}
                </div>
                {transactionsWrapper.transactions.length !== 0 && (
                  <Pagination info={transactionsWrapper.page} />
                )}
              </div>

              {transactionsWrapper.transactions.length === 0 && (
                <div className="px-6 py-12 overflow-hidden text-center bg-white shadow rounded-b-lgg">
                  <Bars4Icon className="w-12 h-12 mx-auto text-neutral-400" />

                  <h3 className="mt-2 text-sm font-medium text-neutral-900">
                    Bez položek
                  </h3>
                  <p className="mt-1 text-sm text-neutral-500">
                    Pro zvolené období a filtr nebyly nalezeny žádné položky.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Transactions;
