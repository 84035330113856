import React, { useEffect, useState } from "react";
import { Decimal } from "decimal.js";
import { useApi } from "../../../App";
import {
  BankTransactionDetails,
  DocumentHeader,
  QueryRefetch,
} from "../../../util/api";
import { useForm, useFieldArray, FieldValues } from "react-hook-form";
import { GhostAffiliation, Location } from "../../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  LocationSelector,
  AmountInput,
  TitleInput,
  VATSelector,
} from "./Inputs";
import Loading from "../../../components/Loading";
import { classNames, currencySymbol } from "../../../util/general";
import {
  XCircleIcon,
  PlusIcon,
  DocumentChartBarIcon,
} from "@heroicons/react/24/solid";

const affilToGhost = (affiliation: any): GhostAffiliation => ({
  LocationId: affiliation.location,
  Amount: affiliation.amount.replace(",", "."),
  Title: affiliation.title,
  VATDeduction: affiliation.vatrate,
});

const ghostToAffil = (affiliation: GhostAffiliation) => ({
  location: affiliation.LocationId,
  amount: affiliation.Amount.replace(".", ","),
  title: affiliation.Title,
  vatrate: affiliation.VATDeduction,
});

const AffiliationForm = ({
  disabled,
  refetch,
  documents,
  affiliations,
  locations,
  transaction,
  isLastRow,
  close,
  setDocCount,
  openBillingModal,
  approvalMode,
}: {
  disabled: boolean;
  refetch: QueryRefetch<any>;
  documents: DocumentHeader[];
  affiliations: GhostAffiliation[];
  locations: Location[];
  transaction: BankTransactionDetails;
  isLastRow: boolean;
  close: VoidFunction;
  setDocCount: React.Dispatch<React.SetStateAction<number>>;
  openBillingModal: () => void;
  approvalMode?: boolean;
}) => {
  const api = useApi();
  const [sumError, setSumError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sum, setSum] = useState("0");

  const validationSchema = yup.object().shape({
    affiliations: yup.array().of(
      yup.object().shape({
        location: yup.number().required().moreThan(0),
        amount: yup
          .string()
          .required()
          .matches(/^-?([0-9] ?)(\d ?)*(,\d+)?$/),
        title: yup.string().required(),
        vatrate: yup.number().required(),
      })
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const {
    watch,
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);
  const { fields, append, remove } = useFieldArray({
    name: "affiliations",
    control,
  });

  function addAffiliation() {
    const affiliations = watch("affiliations");
    const lastVatrate =
      affiliations && affiliations.length > 0
        ? affiliations[affiliations.length - 1].vatrate
        : 0;

    append({ location: 0, amount: "", title: "", vatrate: lastVatrate });
  }

  useEffect(() => {
    reset({
      affiliations: affiliations.map(ghostToAffil),
    });
  }, [affiliations]);

  useEffect(() => {
    const subscription = watch((value: FieldValues) => {
      const affiliationSum = (
        value.affiliations as { amount: string }[]
      )?.reduce(
        (sum: Decimal, { amount }: { amount: string }) =>
          sum.plus(
            amount.length === 0 ? 0 : new Decimal(amount.replace(",", "."))
          ),
        new Decimal(0)
      );

      setSum(
        affiliationSum ? affiliationSum.toFixed(2).replace(".", ",") : "0"
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  async function onSubmit({ affiliations }: { [x: string]: any }) {
    setSaving(true);

    const affiliationSum = affiliations.reduce(
      (sum: Decimal, { amount }: { amount: string }) =>
        sum.plus(new Decimal(amount.replace(",", "."))),
      new Decimal(0)
    );

    if (
      affiliationSum.toDP(2).cmp(new Decimal(0)) !== 0 &&
      affiliationSum.toDP(2).cmp(new Decimal(transaction.Amount)) !== 0 &&
      !sumError
    ) {
      setSaving(false);
      setSumError(true);

      return;
    }

    let affilPostOk = true;

    if (!disabled) {
      const affilResult = await api.transactions.putAffiliations(
        transaction.Id,
        {
          affiliations: affiliations.map(affilToGhost),
          lockTransaction: approvalMode || false,
        }
      );

      affilPostOk = affilResult.ok;
    }

    const docResult = await api.documents.updateTransactionDocuments(
      transaction.Id,
      documents.map((document) => document.FileLink)
    );

    if (affilPostOk && docResult.ok) {
      setDocCount(documents.length);
      refetch();
      close();
    }
  }

  return (
    <form
      className={classNames(
        isLastRow && "rounded-b-md",
        "col-span-24 px-8 bg-neutral-50"
      )}
      onSubmit={handleSubmit(onSubmit)}
    >
      {fields.map((item, i) => (
        <div
          key={item.id}
          className="grid w-full grid-cols-12 col-span-6 py-1 first:pt-8 gap-x-4 bg-neutral-50"
        >
          <div className="col-span-4">
            <LocationSelector
              locations={locations}
              rowIndex={i}
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
              disabled={disabled}
            />
          </div>
          <div className="col-span-3">
            <TitleInput
              rowIndex={i}
              register={register}
              errors={errors}
              disabled={disabled}
            />
          </div>
          <div className="col-span-2">
            <AmountInput
              rowIndex={i}
              currency={transaction.Currency}
              amount={transaction.Amount}
              register={register}
              setValue={setValue}
              errors={errors}
              disabled={disabled}
            />
          </div>

          <div className="grid grid-cols-3 col-span-3">
            <VATSelector
              rowIndex={i}
              watch={watch}
              setValue={setValue}
              disabled={disabled}
            />
            <div className="flex justify-end">
              <div className="flex flex-col">
                <button
                  disabled={disabled}
                  type="button"
                  className={classNames(
                    disabled
                      ? "cursor-not-allowed"
                      : "cursor-pointer hover:bg-neutral-200",
                    "inline-flex items-center p-1 mt-auto mb-1 ml-4 bg-transparent border border-transparent rounded-full text-neutral-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                  )}
                  onClick={() => remove(i)}
                >
                  <XCircleIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
      {fields.length > 0 ? (
        <div className="grid w-full grid-cols-12 col-span-6 py-1 first:pt-8 gap-x-4 bg-neutral-50">
          <div className="col-span-2 col-start-8">
            <div className="relative">
              <input
                type="text"
                id="amount"
                disabled={true}
                value={sum}
                className={classNames(
                  sum.replace(",", ".").replace(".00", "") ===
                    transaction.Amount
                    ? "text-green-700"
                    : "text-red-600",
                  "pointer-events-none bg-neutral-100 border-neutral-300 focus:ring-neutral-500 focus:border-neutral-500 cursor-not-allowed block w-full py-2 pl-3 pr-12 mt-1 border shadow-sm focus:outline-none sm:text-sm"
                )}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <span
                  className="text-neutral-400 sm:text-sm"
                  id="price-currency"
                >
                  {currencySymbol(transaction.Currency)}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="flex justify-between col-span-8 py-8">
        {!disabled ? (
          <>
            <div className="space-x-2 flex-items-center">
              <button
                disabled={disabled}
                className={classNames(
                  disabled
                    ? "cursor-not-allowed"
                    : "cursor-pointer hover:bg-neutral-50",
                  "inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm  text-neutral-700 border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                )}
                type="button"
                onClick={addAffiliation}
              >
                <PlusIcon className="w-4 h-4 mr-2 text-neutral-700" />
                Přidat řádek
              </button>

              {!transaction.$IsBillingSource && !approvalMode && (
                <button
                  disabled={disabled}
                  className={classNames(
                    disabled
                      ? "cursor-not-allowed"
                      : "cursor-pointer hover:bg-neutral-50",
                    "inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm  text-neutral-700 border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                  )}
                  type="button"
                  onClick={openBillingModal}
                >
                  <DocumentChartBarIcon className="w-4 h-4 mr-2 text-neutral-700" />
                  Nahrát externí vyúčtování
                </button>
              )}
            </div>
          </>
        ) : (
          <div></div>
        )}

        <div className="flex items-center space-x-2">
          {sumError && (
            <span className="text-sm text-red-600">
              Součet částek neodpovídá transakci. Pokud to nevadí, ulož znovu.
            </span>
          )}

          <button
            type="button"
            onClick={close}
            className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
          >
            Zavřít
          </button>

          {approvalMode === true ? (
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-green-700 border border-transparent shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700"
            >
              {saving && <Loading />}
              Schválit transakci
            </button>
          ) : (
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
            >
              {saving && <Loading />}
              Uložit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default AffiliationForm;
