import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useApi } from "../../../App";
import { useQuery } from "react-query";
import ArticleTable from "./ArticleTable";
import UpsertModal from "../../upsert-articles/components/UpsertModal";
import ArticleRemovalModal from "./RemoveModal";

import AddAlbumModal from "../../add-group/components/AddGroupModal";
import Loading from "../../../components/Loading";

import { ArticleType, ArticleWithAlias } from "../../../services/api";
import Toolbar from "./Toolbar";
import AddStockModal from "./AddStockModal";
import RemoveStockModal from "./RemoveStockModal";
import Filters from "./Filters";
import CollectionsModal from "./CollectionsModal";
import DistributionTemplatesModal from "./DistributionTemplatesModal";
import { BulkImportModal } from "./BulkImportModal";

const Catalogue = () => {
  const api = useApi();
  const { articleType } = useParams();

  const [transactionsQuery, setTransactionsQuery] = useState<{
    ownerIDs?: number[];
    releaseYear?: number;
    groupID?: number;
    articleName?: string;
    ownership?: any;
  }>({});

  useEffect(() => {
    setTransactionsQuery({});

    if (articleType === "track") {
      window.document.title = "Hudební katalog — Accountant";
    } else if (articleType === "merch") {
      window.document.title = "Katalog merche — Accountant";
    } else {
      window.document.title = "Katalog — Accountant";
    }
  }, [articleType]);

  const [articleModalMode, setArticleModalMode] = useState<"insert" | "update">(
    "insert"
  );
  const [articleModalOpen, setArticleModalOpen] = useState(false);
  const [bulkImportModalOpen, setBulkImportModalOpen] = useState(false);

  const [articleToRemoveId, setArticleToRemoveId] = useState<number>();
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [collectionsModalOpen, setCollectionsModalOpen] = useState(false);

  const [distributionFragmentModalMode, setDistributionFragmentModalMode] =
    useState<"track" | "merch">(articleType as "track" | "merch");
  const [distributionFragmentModalOpen, setDistributionFragmentModalOpen] =
    useState(false);

  const [articleToProcessWarehouse, setArticleToProcessWarehouse] =
    useState<ArticleWithAlias>();
  const [addWarehouseModalOpen, setAddWarehouseModalOpen] = useState(false);
  const [removeWarehouseModalOpen, setRemoveWarehouseModalOpen] =
    useState(false);

  const [albumModalOpen, setAlbumModalOpen] = useState(false);

  const openInsertModal = () => {
    setArticleModalMode("insert");
    setArticleModalOpen(true);
  };

  const openUpdateModal = () => {
    setArticleModalMode("update");
    setArticleModalOpen(true);
  };

  const openDistributionFragmentModal = () => {
    setDistributionFragmentModalMode(articleType as "track" | "merch");
    setDistributionFragmentModalOpen(true);
  };

  if (
    articleType === undefined ||
    (articleType !== "track" && articleType !== "merch")
  ) {
    return <Navigate to="/" />;
  }

  const catalogueType =
    articleType === "track" ? ArticleType.Track : ArticleType.Merch;

  const {
    data,
    refetch,
    isLoading: isArticlesLoading,
  } = useQuery(
    ["articles", articleType, transactionsQuery],
    async () =>
      await api.articles.articlesGetAll({
        articleType: catalogueType,
        ...transactionsQuery,
      }),
    {
      select: (x) => x.data,
    }
  );

  const { data: subjects, isLoading: isSubjectsLoading } = useQuery(
    ["subjects"],
    async () => await api.subjects.getSubjects(),
    {
      select: (x) => x.data.subjects,
    }
  );

  if (isArticlesLoading || isSubjectsLoading) {
    <div className="flex justify-center my-12">
      <Loading />
    </div>;
  }

  return (
    <>
      <CollectionsModal
        open={collectionsModalOpen}
        setOpen={setCollectionsModalOpen}
      />

      <DistributionTemplatesModal
        open={distributionFragmentModalOpen}
        setOpen={setDistributionFragmentModalOpen}
        mode={distributionFragmentModalMode}
      />

      <ArticleRemovalModal
        open={removeModalOpen}
        setOpen={setRemoveModalOpen}
        article={articleToRemoveId}
        refetch={refetch}
      />

      <UpsertModal
        mode={articleModalMode}
        catalogueType={catalogueType}
        open={articleModalOpen}
        setOpen={setArticleModalOpen}
        refetch={refetch}
      />

      <BulkImportModal
        open={bulkImportModalOpen}
        onOpen={setBulkImportModalOpen}
        catalogueType={catalogueType}
      />

      <AddAlbumModal open={albumModalOpen} setOpen={setAlbumModalOpen} />

      {articleToProcessWarehouse !== undefined && (
        <>
          <AddStockModal
            article={articleToProcessWarehouse}
            open={addWarehouseModalOpen}
            setOpen={setAddWarehouseModalOpen}
          />

          <RemoveStockModal
            article={articleToProcessWarehouse}
            open={removeWarehouseModalOpen}
            setOpen={setRemoveWarehouseModalOpen}
          />
        </>
      )}

      <Toolbar
        catalogueType={catalogueType}
        openInsertModal={openInsertModal}
        openBulkImportModal={() => setBulkImportModalOpen(true)}
        openAlbumModal={() => setAlbumModalOpen(true)}
        openCollectionsModal={() => setCollectionsModalOpen(true)}
        openDistributionFragmentModal={openDistributionFragmentModal}
      />

      <div className="flex flex-col flex-1 pt-10 pb-16 bg-neutral-100">
        <main className="flex-1">
          <div className="h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
            {catalogueType === ArticleType.Track && (
              <Filters
                setQuery={setTransactionsQuery}
                subjects={subjects}
                groups={data?.groups}
              />
            )}

            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 overflow-visible align-middle min-h-[32rem] sm:px-6 lg:px-8">
                {data?.articles === undefined ? (
                  <div className="flex justify-center my-12">
                    <Loading />
                  </div>
                ) : (
                  <ArticleTable
                    catalogueType={catalogueType}
                    articles={data.articles}
                    setArticleToRemoveId={setArticleToRemoveId}
                    setRemoveModalOpen={setRemoveModalOpen}
                    setArticleToProcessWarehouse={setArticleToProcessWarehouse}
                    setAddWarehouseModalOpen={setAddWarehouseModalOpen}
                    setRemoveWarehouseModalOpen={setRemoveWarehouseModalOpen}
                  />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Catalogue;
