import React, { useEffect, useState } from "react";
import AddDocument from "./AddDocumentModal";
import { ExternalBillingModal } from "../../external-billing/components/ModalMain";
import { useApi } from "../../../App";
import {
  BankTransactionDetails,
  DocumentHeader,
  QueryRefetch,
} from "../../../util/api";
import { GhostAffiliation } from "../../../services/api";
import DocumentRow from "./DocumentRow";
import AffiliationForm from "./AffiliationForm";
import { Location } from "../../../services/api";
import Loading from "../../../components/Loading";

const TransactionDetail = ({
  disabled,
  refetch,
  locations,
  transaction,
  isLastRow,
  close,
  setDocCount,
  approvalMode,
}: {
  disabled: boolean;
  refetch: QueryRefetch<any>;
  locations: Location[];
  transaction: BankTransactionDetails;
  isLastRow: boolean;
  close: VoidFunction;
  setDocCount: React.Dispatch<React.SetStateAction<number>>;
  approvalMode?: boolean;
}) => {
  const api = useApi();

  const [affiliations, setAffiliations] = useState<GhostAffiliation[]>([]);
  const [documents, setDocuments] = useState<DocumentHeader[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [billingModalOpen, setBillingModalOpen] = useState(false);

  useEffect(() => {
    if (!billingModalOpen) {
      refetch();
    }
  }, [billingModalOpen]);

  function fetchAffiliations() {
    async function getAffiliations() {
      const {
        data: { affiliations },
      } = await api.transactions.getAffiliations(transaction.Id);

      setAffiliations(affiliations);
    }

    getAffiliations();
  }

  function fetchDocuments() {
    async function getDocuments() {
      const {
        data: { documents },
      } = await api.documents.getDocuments(transaction.Id);

      setDocuments(documents.map(({ FileLink }) => ({ FileLink })));
    }

    getDocuments();
  }

  function removeDocument(docIndex: number) {
    setDocuments(documents.filter((_, index) => index !== docIndex));
  }

  function addDocument(fileLink: string) {
    setDocuments([...documents, { FileLink: fileLink }]);
  }

  useEffect(() => {
    fetchAffiliations();
    fetchDocuments();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center col-span-6 py-8 bg-neutral-50">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <AddDocument
        documents={documents}
        open={documentModalOpen}
        setOpen={setDocumentModalOpen}
        addDoc={addDocument}
      />
      <ExternalBillingModal
        open={billingModalOpen}
        onOpen={setBillingModalOpen}
        transaction={transaction}
      />

      <AffiliationForm
        disabled={disabled}
        refetch={refetch}
        documents={documents}
        affiliations={affiliations}
        locations={locations}
        transaction={transaction}
        isLastRow={isLastRow}
        close={close}
        setDocCount={setDocCount}
        openBillingModal={() => setBillingModalOpen(true)}
        approvalMode={approvalMode}
      />

      <DocumentRow
        documents={documents}
        remove={removeDocument}
        openDocModal={() => setDocumentModalOpen(true)}
      />
    </>
  );
};

export default TransactionDetail;
