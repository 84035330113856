import React, { useState } from "react";
import Decimal from "decimal.js";
import { useApi } from "../../../App";
import { useQuery } from "react-query";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Loading from "../../../components/Loading";
import {
  classNames,
  formatAmount,
  currencySymbol,
} from "../../../util/general";
import { ExternalBillingTSOA } from "../../../services/api";
import { BillingSourceNames } from "../../../util/enums";
import { externalToTimeframe } from "../../../util/periods";

const BillingTableRow = ({
  billing: { Id, Source, Period, Year },
}: {
  billing: ExternalBillingTSOA;
}) => {
  const api = useApi();
  const [detailShown, setDetailShown] = useState(false);

  const {
    data: billingDetail,
    isLoading,
    refetch,
  } = useQuery(
    ["billingDetail", Id],
    async () => await api.externalBilling.externalBillingGetSingle(Id),
    {
      select: (x) => x.data,
      enabled: false,
    }
  );

  return (
    <>
      <li className="block cursor-pointer hover:bg-neutral-50">
        <div
          className="flex items-center px-4 py-2 sm:px-6"
          onClick={() => {
            if (!detailShown) {
              refetch();
            }
            setDetailShown(!detailShown);
          }}
        >
          <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
            <div className="truncate">
              <div className="flex text-sm">
                <p className="mr-2 font-medium truncate text-neutral-700">
                  {BillingSourceNames[
                    Source as keyof typeof BillingSourceNames
                  ] || "\u00A0"}
                </p>
                <p className="font-normal text-neutral-400">
                  {externalToTimeframe(Period, Year)}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-shrink-0">
            <ChevronDownIcon
              className={classNames(
                detailShown && "rotate-180",
                "w-5 h-5 text-neutral-400"
              )}
              aria-hidden="true"
            />
          </div>
        </div>
      </li>

      {detailShown &&
        (isLoading || !billingDetail ? (
          <div className="flex justify-center w-full py-4">
            <Loading />
          </div>
        ) : (
          billingDetail.details.subjects.map(({ subject, turnover }) => (
            <li className="block">
              <div className="flex items-center px-4 py-2 sm:px-6">
                <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex text-sm">
                      <p className="truncate text-neutral-700">
                        {subject.Name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-sm text-right text-neutral-500">
                  <span className="py-2.5 font-medium text-neutral-900">
                    {formatAmount(new Decimal(turnover).toFixed(2))}
                  </span>{" "}
                  {currencySymbol(billingDetail.details.totalSum.currency)}
                </div>
              </div>
            </li>
          ))
        ))}
    </>
  );
};

export default BillingTableRow;
