import React from "react";
import {
  UseFormRegister,
  FieldValues,
  Controller,
  Control,
} from "react-hook-form";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";

import { classNames } from "../../../util/general";
import { Listbox } from "@headlessui/react";
import { ArticleOwnership } from "../../../services/api";

const TextInput = ({
  valueKey,
  register,
  errors,
}: {
  valueKey: string;
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: any;
  };
}) => {
  return (
    <input
      type="text"
      id={valueKey}
      {...register(valueKey)}
      className={classNames(
        errors[valueKey] === undefined
          ? "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900"
          : "border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500",
        "block w-full py-2 px-3 mt-1 border  shadow-sm focus:outline-none sm:text-sm"
      )}
    />
  );
};

export default function TextInputs({
  catalogueType,
  control,
  register,
  errors,
}: {
  catalogueType: "track" | "merch";
  control: Control<FieldValues, unknown>;
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: any;
  };
}) {
  return (
    <>
      <dl className="flex items-start justify-between w-full">
        <dt className="block py-2 text-sm font-medium text-neutral-700">
          {catalogueType === "track" ? "Hlavní umělci" : "Hlavní kategorie"}
        </dt>
        <dd className="w-64">
          <TextInput valueKey="owner" register={register} errors={errors} />
        </dd>
      </dl>

      <dl className="flex items-start justify-between w-full">
        <dt className="block py-2 text-sm font-medium text-neutral-700">
          {catalogueType === "merch" ? "Název artiklu" : "Název tracku"}
        </dt>
        <dd className="w-64">
          <TextInput valueKey="name" register={register} errors={errors} />
        </dd>
      </dl>

      {catalogueType !== "merch" && (
        <>
          <dl className="flex items-start justify-between w-full">
            <dt className="block py-2 text-sm font-medium text-neutral-700">
              Vlastnictví
            </dt>
            <dd className="w-64">
              <Controller
                control={control}
                defaultValue=""
                name={"ownership"}
                render={({
                  field: { value, onChange },
                }: {
                  field: { value: string; onChange: any };
                }) => (
                  <Listbox value={value} onChange={onChange}>
                    <div className="relative">
                      <Listbox.Button
                        className={classNames(
                          "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900",
                          "relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default  focus:outline-none focus:ring-1 sm:text-sm"
                        )}
                      >
                        <span className="block truncate">
                          {value.toLowerCase() || "\u00A0"}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ChevronUpDownIcon
                            className="w-5 h-5 text-neutral-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-96 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {Object.values(ArticleOwnership).map((option) => (
                          <Listbox.Option
                            key={option}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-sky-500"
                                  : "text-neutral-900",
                                "cursor-default select-none relative py-1.5 pl-3 pr-9 flex"
                              )
                            }
                            value={option}
                          >
                            {({ active }) => (
                              <>
                                <span
                                  className={classNames(
                                    value === option
                                      ? "font-semibold"
                                      : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {option.toLowerCase()}
                                </span>
                                {value === option ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-sky-500",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                )}
              />
            </dd>
          </dl>

          <dl className="flex items-start justify-between w-full">
            <dt className="block py-2 text-sm font-medium text-neutral-700">
              Datum vydání
            </dt>
            <dd className="w-64">
              <Controller
                control={control}
                defaultValue=""
                name={"releaseDate"}
                render={({ field: { value, onChange } }) => (
                  <input
                    onChange={onChange}
                    type="date"
                    className="block w-full px-3 py-2 mt-1 border shadow-sm border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none sm:text-sm"
                  />
                )}
              />
            </dd>
          </dl>
        </>
      )}

      {(catalogueType === "merch"
        ? [{ c: "EAN", n: "European Article Number" }]
        : [
            { c: "EAN", n: "European Article Number" },
            { c: "ISRC", n: "International Standard Recording Code" },
            { c: "GRid", n: "Global Release Identifier" },
          ]
      ).map(({ c, n }, i) => (
        <dl className="flex items-start justify-between w-full" key={i}>
          <dt className="block py-2 text-sm font-medium text-neutral-700">
            <abbr title={n}>{c}</abbr>
          </dt>
          <dd className="w-64">
            <TextInput valueKey={c} register={register} errors={errors} />
          </dd>
        </dl>
      ))}
    </>
  );
}
