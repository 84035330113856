import React, { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RectangleStackIcon } from "@heroicons/react/24/solid";
import {
  ArticlesResponse,
  ArticleType,
  POSCollectionWithGroups,
} from "../../../services/api";
import Loading from "../../../components/Loading";
import { useApi } from "../../../App";
import { useQueries } from "react-query";
import CollectionsModalItem from "./CollectionsModalItem";

export default function CollectionsModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const cancelButtonRef = useRef(null);
  const api = useApi();

  const [collectionsQuery, articlesQuery] = useQueries([
    {
      queryKey: ["collections"],
      queryFn: () => api.collections.getCollections(),
      select: (x: { data: { collections: POSCollectionWithGroups[] } }) =>
        x.data.collections,
    },
    {
      queryKey: ["articles"],
      queryFn: () =>
        api.articles.articlesGetAll({
          articleType: ArticleType.Merch,
          groupBySuffix: true,
        }),
      select: (x: { data: ArticlesResponse }) => x.data,
    },
  ]);

  const isLoading = collectionsQuery.isLoading || articlesQuery.isLoading;

  useEffect(() => {
    if (open) {
      collectionsQuery.refetch();
      articlesQuery.refetch();
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-6 py-8 text-left transition-all transform bg-white shadow-xl overflow-shown sm:my-8 sm:max-w-3xl sm:w-full">
                {isLoading ? (
                  <div className="flex justify-center py-16">
                    <Loading />
                  </div>
                ) : (
                  <div className="sm:flex sm:items-start">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                      <RectangleStackIcon
                        className="w-6 h-6 text-sky-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex-1 mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="flex items-center h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-neutral-900"
                        >
                          Správa prodejních kolekcí
                        </Dialog.Title>
                      </div>
                      <div className="flex flex-col mt-4">
                        <div className="flex flex-col flex-1 space-y-2">
                          {collectionsQuery.data
                            ?.sort((a, b) => a.Id - b.Id)
                            ?.map((collection) => (
                              <CollectionsModalItem
                                key={collection.Id}
                                collection={collection}
                                articleGroups={
                                  articlesQuery.data?.suffixGroups ?? []
                                }
                                refetch={collectionsQuery.refetch}
                              />
                            ))}
                        </div>
                        <div className="flex items-center justify-between mt-12 space-x-2">
                          <input
                            type="text"
                            placeholder="Přidat kolekci..."
                            className="block w-64 px-3 py-2 border shadow-sm border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none sm:text-sm"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                const input = e.target as HTMLInputElement;
                                const newCollectionName = input.value.trim();
                                if (newCollectionName.length > 0) {
                                  api.collections
                                    .createCollection({
                                      collectionName: newCollectionName,
                                    })
                                    .then(() => {
                                      collectionsQuery.refetch();
                                      input.value = "";
                                    })
                                    .catch((error) =>
                                      console.error(
                                        "Failed to create collection:",
                                        error
                                      )
                                    );
                                }
                              }
                            }}
                          />
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:text-sm"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                          >
                            Zavřít
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
