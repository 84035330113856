import React from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../util/general";
import type { GenericObject } from "../../../types/types";

const ListboxItem = (item: GenericObject) => (
  <Listbox.Option
    key={item.Id}
    className={({ active }) =>
      classNames(
        active ? "text-white bg-sky-500" : "text-neutral-900",
        "cursor-default select-none relative py-1.5 pl-3 pr-9"
      )
    }
    value={item}
  >
    {({ selected, active }) => (
      <>
        <span
          className={classNames(
            selected ? "font-semibold" : "font-normal",
            "block truncate"
          )}
        >
          {item.Name || "nezařazené"}
        </span>

        {selected ? (
          <span
            className={classNames(
              active ? "text-white" : "text-sky-500",
              "absolute inset-y-0 right-0 flex items-center pr-4"
            )}
          >
            <CheckIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        ) : null}
      </>
    )}
  </Listbox.Option>
);

export default ListboxItem;
