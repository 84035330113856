import React, { useState } from "react";
import { classNames } from "../../../util/general";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";

export const NumericInput = ({
  valueKey,
  register,
  errors,
}: {
  valueKey: string;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: any;
  };
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <>
      <div
        className={classNames(
          errors[valueKey] === undefined
            ? classNames(
                "border-neutral-300",
                focused && "ring-neutral-900 border-neutral-900 ring-1"
              )
            : classNames(
                "border-red-300 text-red-900",
                focused && "ring-red-500 border-red-500 ring-1"
              ),
          "flex border group shadow-sm overflow-clip px-3"
        )}
      >
        <input
          type="text"
          id={valueKey}
          {...register(valueKey)}
          onBlur={() => setFocused(false)}
          onFocus={(event) => {
            setFocused(true);
            event.target.select();
          }}
          className={classNames(
            "block flex-1 border-0 ring-transparent focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm px-0"
          )}
        />
      </div>
    </>
  );
};
