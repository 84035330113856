import React from "react";
import { ArticleOwnership } from "../../../services/api";

export const StaticInputField = ({
  label,
  sourceKey,
  index,
  transform,
  sourceData,
}: {
  label: string;
  sourceKey: keyof typeof sourceData[0];
  index: number;
  transform?: (value: string) => string;
  sourceData: {
    owner?: string;
    name?: string;
    ownership?: ArticleOwnership;
    releaseDate?: string;
    EAN?: string;
    ISRC?: string;
    GRid?: string;
  }[];
}) => {
  const value = sourceData[index]?.[sourceKey] || "";
  const displayValue = transform ? transform(value) : value;

  return (
    <dl className="flex items-start justify-between w-full">
      <dt className="block py-2 text-sm font-medium text-neutral-700">
        {label}
      </dt>
      <dd className="w-64">
        <input
          type="text"
          id={sourceKey}
          disabled
          value={displayValue}
          className={
            "text-neutral-800 pointer-events-none bg-neutral-100 border-neutral-300 focus:ring-neutral-500 focus:border-neutral-500 cursor-not-allowed block w-full py-2 px-3 mt-1 border shadow-sm focus:outline-none sm:text-sm"
          }
        />
      </dd>
    </dl>
  );
};
