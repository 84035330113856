import React from "react";
import { useApi } from "../../../App";
import { Subject, ReportHead } from "../../../services/api";
import { exactToTimeframe, formatBillingPeriod } from "../../../util/periods";
import {
  DocumentArrowDownIcon,
  EnvelopeIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/solid";
import { formatDate } from "../../../util/general";

function ReportTable({
  reports,
  setSubject,
  openModal,
}: {
  reports: ReportHead[];
  setSubject: React.Dispatch<React.SetStateAction<Subject | null>>;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const api = useApi();

  async function openPdf(id: number) {
    const reportHead = reports.find((r) => r.Id === id);

    if (!reportHead || !reportHead.$subject || !reportHead.$periods) {
      console.error("Invalid id supplied to openPdf");
      return;
    }

    const response = await api.externalBilling.billingReportGetPdfSingle(id);

    if (!response.ok) {
      console.error("Getting PDF blob failed", response);
      return;
    }

    const downloadLink = document.createElement("a");
    const filename = `${reportHead.ModifiedAt.split("T")[0]}_${
      reportHead.$subject.Id
    }_${exactToTimeframe(reportHead.$periods).replace("-", "")}.pdf`;

    downloadLink.href = encodeURI(response.data!);
    downloadLink.download = filename;
    downloadLink.click();
  }

  return (
    <div className="overflow-hidden bg-white shadow ">
      <ul role="list" className="divide-y divide-neutral-200">
        {reports
          .filter(
            (report) => report.$subject !== null && report.$periods !== null
          )
          .map((report) => (
            <li className="block" key={report.Id}>
              <div className="grid w-full grid-cols-12 px-4 py-2 sm:pl-6 sm:pr-4">
                <div className="flex items-center col-span-10">
                  <p className="text-sm truncate">
                    <span className="mr-1 font-medium text-neutral-700">
                      {report.$subject!.Name}
                    </span>
                    <span className="font-medium text-neutral-400">
                      {formatBillingPeriod(report.$periods!)}
                    </span>
                  </p>
                </div>
                <div className="flex items-center justify-end col-span-2 text-sm text-neutral-500">
                  <p>{formatDate(report.CreatedAt)}</p>
                  <button
                    type="button"
                    className="inline-flex items-center p-1 ml-2 bg-transparent border border-transparent rounded-full text-neutral-500 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                    onClick={() => openPdf(report.Id)}
                    title="Stáhnout vyúčtování"
                  >
                    <DocumentArrowDownIcon
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                  </button>
                  {/* @ts-ignore */}
                  {report.$sent ? (
                    <div className="p-1 ml-2" title="Odesláno">
                      <CheckBadgeIcon
                        className="w-5 h-5 text-green-500"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex items-center p-1 ml-2 bg-transparent border border-transparent rounded-full text-neutral-500 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                      onClick={() => {
                        if (report.$subject) {
                          setSubject(report.$subject);
                          openModal(true);
                        }
                      }}
                      title="Odeslat vyúčtování"
                    >
                      <EnvelopeIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  )}
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default ReportTable;
