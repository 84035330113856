import React, { Fragment } from "react";
import {
  UseFormWatch,
  UseFormSetValue,
  UseFormClearErrors,
  FieldValues,
} from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import ListboxItem from "../../accounts/components/ListboxItem";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import type { GenericObject } from "../../../types/types";

import { classNames } from "../../../util/general";

const DropdownSelector = ({
  rowIndex,
  type,
  values,
  watch,
  setValue,
  clearErrors,
  limitHeight,
  errors,
}: {
  rowIndex: number;
  type: "subjectId" | "role" | "fragmentType";
  values: GenericObject[];
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  limitHeight?: boolean;
  errors: {
    [x: string]: any;
  };
}) => {
  const valueKey = `fragments.${rowIndex}.${type}`;

  const currentValue = watch(valueKey);
  const onValueChange = (val: GenericObject) => {
    clearErrors(valueKey);
    setValue(valueKey, type === "subjectId" ? val.Id : val.Name);
  };

  function displayTitle(currentValue: any, values: GenericObject[]) {
    if (type === "subjectId") {
      return values.find((value) => value.Id === currentValue)?.Name;
    } else {
      return values.find((value) => value.Name === currentValue)?.Name;
    }
  }

  return (
    <Listbox value={currentValue} onChange={onValueChange}>
      {({ open }) => (
        <>
          {rowIndex === 0 && (
            <Listbox.Label className="block text-sm font-medium text-neutral-700">
              {type === "subjectId"
                ? "Subjekt"
                : type === "role"
                ? "Role"
                : "Dělení"}
            </Listbox.Label>
          )}
          <div className="relative mt-1">
            <Listbox.Button
              className={classNames(
                errors.fragments?.[rowIndex]?.[type] === undefined
                  ? "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900"
                  : "border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500",
                "relative w-full py-2 pl-3 pr-10 text-left bg-white border  shadow-sm cursor-default focus:outline-none focus:ring-1 sm:text-sm"
              )}
            >
              <span className="block truncate">
                {displayTitle(currentValue, values) || "\u00A0"}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className="w-5 h-5 text-neutral-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  limitHeight && "h-64",
                  "absolute z-10 w-64 py-1 mt-1 overflow-auto text-base bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                )}
              >
                {values.map(ListboxItem)}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default DropdownSelector;
