import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { DocumentHeader } from "../../../util/api";
import { classNames } from "../../../util/general";
import {
  filenameFromLink,
  isValidLink,
  isUniqueDocumentLink,
} from "../../../util/document";

const AddDocument = ({
  documents,
  open,
  setOpen,
  addDoc,
}: {
  documents: DocumentHeader[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addDoc: (link: string) => void;
}) => {
  const [saveAllowed, setSaveAllowed] = useState(false);
  const [submission, setSubmission] = useState("");
  const [docNames, setDocNames] = useState<string[]>([]);

  useEffect(() => {
    setSaveAllowed(submission.length > 0);
  }, [submission]);

  useEffect(() => {
    setDocNames(
      documents.map((document) => filenameFromLink(document.FileLink))
    );
  }, [documents]);

  function save() {
    if (saveAllowed) {
      addDoc(submission);
      setSubmission("");
      setOpen(false);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-700" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full max-w-md px-6 py-12 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl ">
              <Dialog.Title
                as="h3"
                className="mb-4 text-lg font-medium leading-6 text-neutral-900"
              >
                Přidat nový dokument
              </Dialog.Title>
              <div className="relative mt-1 shadow-sm ">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <DocumentIcon
                    className="w-5 h-5 text-neutral-300"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="document"
                  id="document"
                  onChange={(e) => setSubmission(e.target.value)}
                  className="block w-full py-2 pl-10 mt-1 border shadow-sm  placeholder:text-neutral-300 focus:outline-none sm:text-sm border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900"
                  placeholder="https://www.dropbox.com/"
                />
              </div>
              <div className="flex justify-end mt-5">
                <button
                  type="button"
                  disabled={!saveAllowed}
                  className={classNames(
                    saveAllowed
                      ? "cursor-pointer opacity-100"
                      : "cursor-not-allowed opacity-50",
                    "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent  shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  )}
                  onClick={save}
                >
                  Uložit
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddDocument;
