import React, { Fragment } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../../App";
import ListboxItem from "./ListboxItem";
import { AccountInfo, ProjectInfo } from "../../../services/api";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ArchiveBoxIcon } from "@heroicons/react/24/solid";
import { Listbox, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../../util/general";
import ListboxLocation from "./ListboxLocation";

const AccountHeader = ({
  account,
  currentSubject,
  currentProject,
  showArchived,
  shownTab,
}: {
  account: AccountInfo;
  project: ProjectInfo;
  currentSubject: number;
  currentProject?: ProjectInfo;
  showArchived: boolean;
  shownTab: number;
}) => {
  const api = useApi();
  const navigate = useNavigate();

  const { data: subjects, isLoading: isLoadingSubjects } = useQuery(
    ["subjects"],
    async () => await api.subjects.getSubjects(),
    {
      select: (x) => x.data.subjects,
    }
  );
  const { data: projects, isLoading: isLoadingProjects } = useQuery(
    ["projects", currentSubject],
    async () => await api.projects.getAccountProjects(currentSubject),
    {
      select: (x) => x.data.projects,
    }
  );

  if (isLoadingSubjects || isLoadingProjects) {
    return <></>;
  }

  return (
    <div className="flex justify-between py-2 mb-6 items-top">
      <div className="flex items-center space-x-3">
        <div className="relative inline-flex space-x-2">
          <Listbox
            value={account}
            onChange={(accountInfo: AccountInfo) => {
              navigate(`/account/${accountInfo.Id}/${0}?tab=0`);
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Button className="relative flex items-end space-x-2 text-neutral-900 hover:text-neutral-600">
                  <span className="text-3xl font-bold">{account.Name}</span>
                  <ChevronDownIcon className="w-8 h-8" aria-hidden="true" />
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 py-1 mt-12 overflow-auto text-base bg-white shadow-lg w-72 max-h-96 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {subjects!.map(ListboxItem)}
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </Listbox>

          {account.Projects.length > 1 && shownTab === 1 && (
            <Listbox
              value={currentProject}
              onChange={(projectInfo: ProjectInfo) => {
                navigate(`/account/${currentSubject}/${projectInfo.Id}?tab=1`);
              }}
            >
              {({ open }) => (
                <>
                  <Listbox.Button
                    className={classNames(
                      currentProject?.IsArchived
                        ? "text-neutral-500"
                        : "text-sky-600",
                      "relative flex items-end space-x-2"
                    )}
                  >
                    <span className="text-3xl font-bold">
                      {currentProject?.Name || "nezařazené"}
                    </span>
                    {currentProject?.IsArchived && (
                      <ArchiveBoxIcon
                        className="flex-shrink-0 w-8 h-8"
                        aria-hidden="true"
                        title="archivovaná složka"
                      />
                    )}
                    <ChevronDownIcon className="w-8 h-8" aria-hidden="true" />
                  </Listbox.Button>

                  {projects !== undefined && projects.length > 1 && (
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute right-0 z-10 py-1 mt-12 overflow-auto text-base bg-white shadow-lg w-72 max-h-96 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {projects
                          .filter((p) => (showArchived ? p : !p.Archived))
                          .map(ListboxLocation)}
                      </Listbox.Options>
                    </Transition>
                  )}
                </>
              )}
            </Listbox>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountHeader;
