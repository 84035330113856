import React, { useEffect, useState } from "react";
import qs from "qs";
import { useApi } from "../../../App";
import { useQuery } from "react-query";
import { monthToTimeframe, timeframeToExact } from "../../../util/periods";

import { classNames } from "../../../util/general";
import ExportNotification from "./ExportNotification";
import OptionDropdown from "../../../components/OptionDropdown";
import { PeriodSelector } from "../../../components/PeriodSelector";
import { ExactPeriod } from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";

const Filters = ({
  setQuery,
}: {
  setQuery: React.Dispatch<
    React.SetStateAction<{
      timeframes: ExactPeriod[];
      filterNote?: string;
      assigned?: boolean;
      documents?: boolean;
    }>
  >;
}) => {
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  const [timeframes, setTimeframes] = useState<string[]>([]);
  const [fulltextSearch, setFulltextSearch] = useState("");
  const [assigned, setAssigned] = useState("—");
  const [documents, setDocuments] = useState("—");

  const { data: textExport, refetch } = useQuery(
    ["textExport", { timeframes }],
    async () =>
      await api.transactions.getTransactionsExport(
        timeframes.flatMap(timeframeToExact)
      ),
    {
      select: (x) => x.data.textExport,
      enabled: false,
    }
  );

  useEffect(() => {
    if (timeframes.length !== 0) {
      refetch();
    }
  }, [timeframes]);

  const downloadFile = (filename: string, text: string) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const doExport = () => {
    if (textExport !== undefined) {
      // navigator.clipboard.writeText(textExport);
      downloadFile("accountant_export.csv", textExport);

      setSuccess(true);

      setTimeout(() => setSuccess(false), 5000);
    }
  };

  function getFilters() {
    const queryObject = {
      timeframes:
        timeframes.length === 0 ? [] : timeframes.flatMap(timeframeToExact),
      filterNote: fulltextSearch.trim() || undefined,
      assigned: assigned === "—" ? undefined : !assigned.startsWith("ne"),
      documents: documents === "—" ? undefined : !documents.startsWith("ne"),
    };

    setQuery(queryObject);
    navigate(`/transactions/1?${qs.stringify(queryObject)}`);
  }

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    setTimeframes([]);
    setFulltextSearch("");
    setAssigned("—");
    setDocuments("—");

    if (query.timeframes !== undefined && Array.isArray(query.timeframes)) {
      setTimeframes(
        (query.timeframes as { month: string; year: string }[]).map(
          monthToTimeframe
        )
      );
    }

    if (
      query.filterNote !== undefined &&
      typeof query.filterNote === "string" &&
      query.filterNote.trim() !== ""
    ) {
      setFulltextSearch(query.filterNote as string);
    }

    if (query.assigned !== undefined && typeof query.assigned === "string") {
      setAssigned(
        query.assigned === "true"
          ? "existuje"
          : query.assigned === "false"
          ? "neexistuje"
          : "—"
      );
    }

    if (query.documents !== undefined && typeof query.documents === "string") {
      setDocuments(
        query.documents === "true"
          ? "přiřazeny"
          : query.assigned === "false"
          ? "nepřiřazeny"
          : "—"
      );
    }

    const queryObject = {
      timeframes:
        query.timeframes !== undefined && Array.isArray(query.timeframes)
          ? (query.timeframes as { month: string; year: string }[])
              .flatMap(monthToTimeframe)
              .flatMap(timeframeToExact)
          : [],
      filterNote:
        query.filterNote !== undefined &&
        typeof query.filterNote === "string" &&
        query.filterNote.trim() !== ""
          ? query.filterNote.trim()
          : undefined,
      assigned:
        query.assigned === "true"
          ? true
          : query.assigned === "false"
          ? false
          : undefined,
      documents:
        query.documents === "true"
          ? true
          : query.documents === "false"
          ? false
          : undefined,
    };

    setQuery(queryObject);
  }, [location.search]);

  return (
    <>
      <ExportNotification show={success} setShow={setSuccess} />

      <div className="grid w-full grid-cols-5 mb-10 gap-x-4">
        <div>
          <PeriodSelector
            label={"Časové období"}
            periods={timeframes}
            setPeriods={setTimeframes}
            limitToOne={false}
          />
        </div>
        <div>
          <label
            htmlFor="fulltextSearch"
            className="block text-sm font-medium text-neutral-700"
          >
            Text
          </label>
          <input
            type="text"
            name="fulltextSearch"
            id="fulltextSearch"
            className="block w-full px-3 py-2 mt-1 border shadow-sm border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900  focus:outline-none sm:text-sm"
            value={fulltextSearch}
            onChange={(e) => setFulltextSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getFilters();
              }
            }}
          />
        </div>
        <div>
          <OptionDropdown
            label={"Zařazení"}
            value={assigned}
            options={["—", "existuje", "neexistuje"]}
            setValue={setAssigned}
            onChangeFn={() => {}}
          />
        </div>
        <div>
          <OptionDropdown
            label={"Dokumenty"}
            value={documents}
            options={["—", "přiřazeny", "nepřiřazeny"]}
            setValue={setDocuments}
            onChangeFn={() => {}}
          />
        </div>
        <div className="flex items-end justify-end space-x-2">
          <button
            onClick={() => getFilters()}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm  bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
          >
            Filtrovat
          </button>

          <button
            className={classNames(
              !textExport && "cursor-not-allowed opacity-50",
              "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent  shadow-sm bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
            )}
            disabled={!textExport}
            onClick={() => doExport()}
          >
            Export
          </button>
        </div>
      </div>
    </>
  );
};

export default Filters;
