import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { SettingsContext, useApi } from "../../../App";

import Loading from "../../../components/Loading";
import AccountHeader from "./Header";
import ProjectSummaryTable from "./ProjectSummaryTable";
import ProjectItemsTable from "./ProjectItemsTable";
import TransferModal from "../../transfers/components/TransferModal";
import ProjectRemoveModal from "./RemoveModal";
import ProjectArchiveModal from "./ArchiveModal";
import WarehouseModal from "../../transactions/components/WarehouseModal";

import Toolbar from "./Toolbar";
import { extractParam, ToolbarLevel } from "../../../util/general";
import ProjectSummaryTableConsolidated from "./ProjectSummaryTableConsolidated";
import AccountStatsBar from "./AccountStatsBar";
import ProjectStatsBar from "./ProjectStatsBar";
import DefaultProjectModal from "../../default-projects/components/DefaultProjectModal";

const Account = () => {
  const api = useApi();
  const navigate = useNavigate();
  const { consolidateCurrencies, yearFilter, showArchivedProjects } =
    useContext(SettingsContext);
  const { rawSubjectID, rawProjectID } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const shownTab = parseInt(searchParams.get("tab") || "0", 10);

  const [transferOpen, setTransferOpen] = useState(false);
  const [defaultProjectModalOpen, setDefaultProjectModalOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [warehouseModalOpen, setWarehouseModalOpen] = useState(false);
  const [projectToRemoveId, setProjectToRemoveId] = useState<number>();

  const [projectItemsFilter, setProjectItemsFilter] = useState<
    "Incomes" | "Expenses" | undefined
  >(undefined);

  const subjectID = extractParam(rawSubjectID, 1);
  const projectID = extractParam(rawProjectID, 0);

  const setShownTab = (tabIndex: number, newProjectNumber?: number) => {
    if (newProjectNumber !== undefined) {
      navigate(`/account/${subjectID}/${newProjectNumber}?tab=1`);
    } else {
      searchParams.set("tab", tabIndex.toString());
      setSearchParams(searchParams);
    }
  };

  const level =
    shownTab === 0
      ? ToolbarLevel.Account
      : projectID === 0
      ? ToolbarLevel.ProjectRestricted
      : ToolbarLevel.Project;

  useEffect(() => {
    window.document.title = "Účet — Accountant";
  }, []);

  const {
    data: account,
    isLoading: isLoadingAccount,
    refetch: refetchAccount,
  } = useQuery(
    ["account", subjectID, yearFilter],
    async () =>
      await api.accounts.getAccount(subjectID, {
        filterYear: yearFilter || undefined,
      }),
    {
      select: (x) => x.data.account,
    }
  );

  useEffect(() => {
    if (account) {
      window.document.title = `Účet ${account.Name} — Accountant`;
    }
  }, [account]);

  const {
    data: project,
    isLoading: isLoadingProject,
    refetch: refetchProject,
  } = useQuery(
    ["project", subjectID, projectID, yearFilter],
    async () => {
      return await api.accounts.getProject(subjectID, projectID, {
        filterYear: yearFilter || undefined,
      });
    },
    {
      select: (x) => x.data.project,
    }
  );

  const { data: locations, isLoading: isLoadingLocations } = useQuery(
    ["locations", subjectID],
    async () => await api.locations.getLocationsBySubject(subjectID),
    {
      select: (x) => x.data.locations,
    }
  );

  if (isLoadingAccount || isLoadingProject || isLoadingLocations) {
    return (
      <div className="flex justify-center py-16">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <DefaultProjectModal
        open={defaultProjectModalOpen}
        setOpen={setDefaultProjectModalOpen}
        locations={locations}
        account={account}
      />

      <TransferModal
        open={transferOpen}
        setOpen={setTransferOpen}
        identifiers={{ subject: subjectID, project: projectID }}
      />

      <ProjectRemoveModal
        open={removeOpen}
        setOpen={setRemoveOpen}
        project={projectToRemoveId}
        setShownTab={setShownTab}
      />

      <ProjectArchiveModal
        open={archiveOpen}
        setOpen={setArchiveOpen}
        project={project}
        setShownTab={setShownTab}
      />

      <Toolbar
        setDefaultProjectModalOpen={setDefaultProjectModalOpen}
        setTransferOpen={setTransferOpen}
        currentSubject={subjectID}
        refetchAccount={refetchAccount}
        setProjectToRemove={setProjectToRemoveId}
        setRemoveOpen={setRemoveOpen}
        setArchiveOpen={setArchiveOpen}
        project={project}
        level={level}
        shownTab={shownTab}
        setShownTab={setShownTab}
      />

      <div className="flex flex-col flex-1 pt-10 pb-16 bg-neutral-100">
        <main className="flex-1">
          <div className="h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 overflow-visible align-middle min-h-[32rem] sm:px-6 lg:px-8">
                <AccountHeader
                  account={account!}
                  project={project!}
                  currentSubject={subjectID}
                  currentProject={project}
                  showArchived={showArchivedProjects}
                  shownTab={shownTab}
                />

                {shownTab === 0 ? (
                  <AccountStatsBar accountStats={account?.Stats} />
                ) : (
                  <ProjectStatsBar
                    projectStats={project?.Stats}
                    projectItemsFilter={projectItemsFilter}
                    setProjectItemsFilter={setProjectItemsFilter}
                  />
                )}

                {shownTab === 0 ? (
                  consolidateCurrencies ? (
                    <ProjectSummaryTableConsolidated
                      summaries={account?.Projects.filter((p) =>
                        showArchivedProjects ? p : !p.Archived
                      )}
                      setShownTab={setShownTab}
                    />
                  ) : (
                    <ProjectSummaryTable
                      summaries={account?.Projects.filter((p) =>
                        showArchivedProjects ? p : !p.Archived
                      )}
                      setShownTab={setShownTab}
                    />
                  )
                ) : (
                  <ProjectItemsTable
                    project={project}
                    refetchAccount={refetchAccount}
                    refetchProject={refetchProject}
                    filter={projectItemsFilter}
                    locations={locations}
                  />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Account;
