import React, { useEffect, useMemo, useState, useRef, forwardRef } from "react";
import { useRowSelect, useTable } from "react-table";
import Loading from "../../../components/Loading";
import {
  AccountInfo,
  AffiliationCZK,
  Location,
  ProjectInfo,
} from "../../../services/api";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import {
  formatAmount,
  formatDate,
  currencySymbol,
} from "../../../util/general";
import { classNames } from "../../../util/general";
import TransactionPopover from "./TransactionPopover";
import { QueryRefetch } from "../../../util/api";
import { FolderIcon } from "@heroicons/react/24/outline";
import MoveAffilsModal from "./MoveAffilsModal";

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  { indeterminate?: boolean; checked?: boolean }
>(({ indeterminate = false, checked, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    if (resolvedRef && "current" in resolvedRef && resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={resolvedRef}
      checked={checked}
      {...rest}
      className="w-4 h-4 border-neutral-300 text-sky-600 focus:ring-sky-600"
    />
  );
});

const ProjectItemsTable = ({
  project,
  refetchAccount,
  refetchProject,
  filter,
  locations,
}: {
  project?: ProjectInfo;
  refetchAccount: QueryRefetch<AccountInfo>;
  refetchProject: QueryRefetch<ProjectInfo>;
  filter?: "Incomes" | "Expenses";
  locations?: Location[];
}) => {
  const [moveAffilsModalOpen, setMoveAffilsModalOpen] = useState(false);
  const [projectItems, setProjectItems] = useState<AffiliationCZK[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<Record<string, boolean>>(
    {}
  );
  const [tableReset, setTableReset] = useState(false);

  if (!project) {
    return (
      <div className="flex justify-center my-12">
        <Loading />
      </div>
    );
  }

  useEffect(() => {
    if (project !== undefined) {
      setProjectItems(project.Items);
    }
  }, [project]);

  const data = useMemo(() => {
    return projectItems.map((item) => ({
      id: { id: item.Id, isVirtual: item.IsVirtual },
      date: item.HappenedAt,
      details: item.Title,
      amount: {
        valueVAT: parseFloat(item.AmountWithVAT),
        valueNoVAT: parseFloat(item.AmountWithoutVAT),
        currency: item.Currency,
        vat: item.VATDeduction,
      },
      info: {
        isVirtual: item.IsVirtual,
        vat: item.VATDeduction,
      },
    }));
  }, [projectItems]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id" as const,
        Cell: ({ value }: { value: { id: number; isVirtual: boolean } }) => (
          <div className="flex items-center justify-between px-3 py-2 text-sm font-medium text-left text-neutral-400 whitespace-nowrap">
            <span>{`#${value.id}`}</span>
            <TransactionPopover
              affiliationId={value.id}
              isVirtual={value.isVirtual}
            />
          </div>
        ),
      },

      {
        Header: "Datum",
        accessor: "date" as const,
        Cell: ({ value }: { value: string }) => (
          <div className="py-2 pl-6 pr-3 text-sm font-medium text-left text-neutral-500 whitespace-nowrap">
            {formatDate(value)}
          </div>
        ),
      },
      {
        Header: "Položka",
        accessor: "details" as const,
        Cell: ({ value }: { value: string }) => (
          <div className="flex items-center px-6 py-2 text-sm text-left text-neutral-500 whitespace-nowrap">
            <span className="font-medium text-neutral-900">{value}</span>
          </div>
        ),
      },
      {
        Header: "Částka",
        accessor: "amount" as const,
        Cell: ({
          value: { valueVAT, valueNoVAT, currency, vat },
        }: {
          value: {
            valueVAT: number;
            valueNoVAT: number;
            currency: string;
            vat: number;
          };
        }) => (
          <div className="inline-flex justify-end w-full py-2 pl-6 pr-3 text-sm font-medium text-neutral-900 whitespace-nowrap">
            <div>
              <span
                className={
                  valueNoVAT < 0
                    ? "text-red-600"
                    : valueNoVAT > 0
                    ? "text-green-700"
                    : "text-neutral-900"
                }
              >
                {vat === 0 ? (
                  `${formatAmount(valueNoVAT)}`
                ) : (
                  <abbr
                    title={`včetně DPH ${formatAmount(
                      valueVAT
                    )} ${currencySymbol(currency)}`}
                  >
                    {formatAmount(
                      parseFloat((valueVAT / (1 + vat / 100)).toFixed(2))
                    )}
                  </abbr>
                )}
              </span>

              <span className="text-neutral-500">
                {currency === "CZK" ? "\u00A0Kč" : "\u00A0€\u00A0\u00A0"}
              </span>
            </div>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "info" as const,
        Cell: ({
          value: { vat, isVirtual },
        }: {
          value: { vat: number; isVirtual: boolean };
        }) => (
          <div className="inline-flex justify-center w-full px-3 py-2 text-sm font-medium text-neutral-900 whitespace-nowrap">
            <div className="flex items-center">
              {isVirtual && (
                <span
                  className={classNames(
                    vat !== 0 && "mr-2",
                    "bg-sky-500 text-white inline-flex items-center px-1.5 py-0.5 mt-0.5 rounded-full text-xs font-medium"
                  )}
                >
                  <ArrowsRightLeftIcon className="w-3 h-3" />
                </span>
              )}
              {vat !== 0 && (
                <span className="bg-neutral-200 text-neutral-600 inline-flex items-center px-2 rounded-full text-[10px] py-0.5 leading-3 font-medium">
                  <span>{vat}%</span>
                </span>
              )}
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      initialState: { selectedRowIds },
      autoResetSelectedRows: false,
      stateReducer: (newState, action) => {
        if (action.type === "toggleRowSelected") {
          const newSelectedRowIds = { ...newState.selectedRowIds };
          setSelectedRowIds(newSelectedRowIds);
        }
        return newState;
      },
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Cell: ({ row }) => (
            <div className="flex items-center justify-center px-5 py-2">
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                checked={selectedRowIds[row.id]}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const resetTable = () => {
    setSelectedRowIds({});
    setTableReset(!tableReset);
  };

  return (
    <>
      <MoveAffilsModal
        open={moveAffilsModalOpen}
        setOpen={setMoveAffilsModalOpen}
        locations={locations}
        currentProject={project}
        selectedRowIds={selectedRowIds}
        resetTable={resetTable}
      />

      <div className="flex flex-col mt-8 space-y-2" key={Number(tableReset)}>
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            {Object.keys(selectedRowIds).length > 0 && (
              <div className="flex justify-between px-5 py-2 bg-white border shadow border-neutral-50">
                <div className="flex items-center space-x-2 text-sm font-medium">
                  <span className=" text-neutral-900">Vybráno</span>
                  <span className=" bg-sky-100 text-sky-700 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs">
                    {Object.keys(selectedRowIds).length}
                  </span>
                </div>

                {locations !== undefined && (
                  <button
                    className="text-sm underline cursor-pointer hover:no-underline text-neutral-900"
                    onClick={() => setMoveAffilsModalOpen(true)}
                  >
                    Přesunout označené
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            {projectItems.length === 0 ? (
              <div className="px-6 py-12 overflow-hidden text-center bg-white shadow rounded-b-lgg">
                <FolderIcon className="w-12 h-12 mx-auto text-neutral-400" />

                <h3 className="mt-2 text-sm font-medium text-neutral-900">
                  Tady nic není.
                </h3>
              </div>
            ) : (
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
                <table
                  className="min-w-full divide-y divide-neutral-300"
                  {...getTableProps()}
                >
                  <thead className="bg-neutral-50">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="cursor-pointer hover:bg-neutral-50"
                      >
                        {headerGroup.headers.map((column, x) =>
                          x === 0 ? (
                            <th scope="col" key={column.id}></th>
                          ) : (
                            <th
                              key={column.id}
                              scope="col"
                              className={classNames(
                                x === 1 ? "px-3" : "px-6",
                                x === 4 ? "text-right" : "text-left",
                                "py-2.5 whitespace-nowrap text-xs tracking-wider uppercase font-semibold text-neutral-500"
                              )}
                            >
                              <span>{column.render("Header")}</span>
                            </th>
                          )
                        )}
                      </tr>
                    ))}
                  </thead>

                  <tbody
                    className="bg-white divide-y divide-neutral-200"
                    {...getTableBodyProps()}
                  >
                    {rows.map((row) => {
                      prepareRow(row);

                      if (
                        filter === "Incomes" &&
                        row.original.amount.valueNoVAT < 0
                      ) {
                        return;
                      }

                      if (
                        filter === "Expenses" &&
                        row.original.amount.valueNoVAT > 0
                      ) {
                        return;
                      }

                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, index) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={classNames(index === 3 && "w-full")}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectItemsTable;
