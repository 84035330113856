import React from "react";
import { classNames } from "../util/general";

const Step = ({
  active,
  complete,
  title,
  last,
}: {
  active: boolean;
  complete: boolean;
  title: string;
  last?: boolean;
}) => {
  return (
    <li
      className={classNames(
        active || complete ? "text-sky-700" : "text-neutral-400",
        "relative md:flex md:flex-1"
      )}
    >
      <span className="flex items-center w-full group">
        <span className="flex items-center px-4 py-3 text-sm font-medium">
          <span
            className={classNames(
              active || complete ? "bg-sky-600" : "bg-neutral-200",
              "flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-full"
            )}
          >
            {complete ? (
              <svg
                className="w-4 h-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg
                className="w-4 h-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </span>
          <div className="contents">
            <span className="ml-4 text-sm font-medium">{title}</span>
          </div>
        </span>
      </span>
      {!last && (
        <div
          className="absolute top-0 right-0 hidden w-5 h-full md:block"
          aria-hidden="true"
        >
          <svg
            className="w-full h-full text-neutral-300"
            viewBox="0 0 22 80"
            fill="none"
            preserveAspectRatio="none"
          >
            <path
              d="M0 -2L20 40L0 82"
              vectorEffect="non-scaling-stroke"
              stroke="currentcolor"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      )}
    </li>
  );
};

const ModalNavigation = ({
  step,
  labels,
}: {
  step: number;
  labels: string[];
}) => {
  return (
    <ol className="border divide-y border-neutral-300 divide-neutral-300 md:flex md:divide-y-0">
      {labels.map((label, index) => (
        <Step
          key={index}
          complete={step > index + 1}
          active={step === index + 1}
          title={label}
          last={index === labels.length - 1}
        />
      ))}
    </ol>
  );
};

export default ModalNavigation;
