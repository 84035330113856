import React, { Fragment, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Dialog, Transition } from "@headlessui/react";
import ModalNavigation from "../../../components/ModalNavigation";
import { classNames } from "../../../util/general";
import { useMutation, useQuery } from "react-query";
import { useApi } from "../../../App";
import {
  ArticleOwnership,
  ArticleType,
  DistributionFragmentType,
  DistributionFragmentTypeTSOA,
  DistributionTemplate,
  DistributionTemplateFragment,
  Subject,
  SubjectWithArtist,
  UniqueNameType,
} from "../../../services/api";
import { notifications } from "@mantine/notifications";
import {
  declineArticles,
  declineInvalidRows,
} from "../../../util/transactions";
import BulkFragmentArray from "./BulkFragmentArray";
import { StaticInputField } from "./StaticInputField";
import Loading from "../../../components/Loading";
import { distributionRoles } from "../../../util/roles";

function getErrorMessage({ status }: { status: number }) {
  switch (status) {
    case 400:
      return "Nahraný soubor hlavičkou neodpovídá aktuálnímu katalogu.";

    case 422:
      return "CSV nemá správný formát (hodnoty v uvozovkách oddělené čárkou).";

    case 500:
      return "Došlo k interní chybě na serveru.";

    case 401:
      return "Přístupový token expiroval. Obnov si stránku.";

    default:
      return "Nastala neznámá chyba.";
  }
}

export function BulkImportModal({
  open,
  onOpen,
  catalogueType,
}: {
  open: boolean;
  onOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogueType: "track" | "merch";
}) {
  const api = useApi();
  const [step, setStep] = useState(1);
  const [articleQueueStep, setArticleQueueStep] = useState(1);

  const [sumError, setSumError] = useState(false);

  const [bulkImportFile, setBulkImportFile] = useState<File | null>(null);
  const uploadPossible = bulkImportFile !== null;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [persistDistribution, setPersistDistribution] = useState(true);

  const [existingArticlesCount, setExistingArticlesCount] = useState(0);
  const [invalidRows, setInvalidRows] = useState<
    { reason: string; row: string }[]
  >([]);

  const [newArticlesSourceData, setNewArticlesSourceData] = useState<
    {
      owner?: string;
      name?: string;
      ownership?: ArticleOwnership;
      releaseDate?: string;
      EAN?: string;
      ISRC?: string;
      GRid?: string;
      UPC?: string;
    }[]
  >([]);

  const [articlesToSubmit, setArticlesToSubmit] = useState<
    {
      owner?: string;
      name?: string;
      ownership?: ArticleOwnership;
      releaseDate?: string;
      EAN?: string;
      ISRC?: string;
      GRid?: string;
      UPC?: string;
      fragments: {
        subjectId: number;
        percentage: number;
        role: string;
        type: DistributionFragmentTypeTSOA;
        uniqName: string;
        uniqNameType: UniqueNameType;
      }[];
    }[]
  >([]);

  const { data: subjects, isLoading: isLoadingSubjects } = useQuery(
    ["subjects"],
    async () => await api.subjects.getSubjects(),
    {
      select: (x) => x.data.subjects,
    }
  );

  const { data: templates, isLoading: isLoadingTemplates } = useQuery(
    ["templates"],
    async () => await api.distributionTemplates.distributionTemplatesGet(),
    {
      select: (x) =>
        x.data.templates.filter((template) => {
          if (catalogueType === "track") {
            return !template.Fragments.some(
              (f) => f.Type === DistributionFragmentType.MERCH
            );
          } else {
            return template.Fragments.some(
              (f) => f.Type === DistributionFragmentType.MERCH
            );
          }
        }),
      enabled: open,
    }
  );

  const cancelImport = () => {
    // Reset all state
    setStep(1);
    setBulkImportFile(null);
    setExistingArticlesCount(0);
    setNewArticlesSourceData([]);
    setArticlesToSubmit([]);
    setInvalidRows([]);

    // Close the modal
    onOpen(false);
  };

  const initialUploadMutation = useMutation({
    mutationFn: async (importFile: File) => {
      return await api.articles.articleCreateBulk(
        {
          type:
            catalogueType === "track" ? ArticleType.Track : ArticleType.Merch,
        },
        { file: importFile }
      );
    },
    onSuccess: ({
      data: {
        articles: { existing: articlesExisting, new: articlesNew },
        invalid: rowsInvalid,
      },
    }) => {
      setExistingArticlesCount(articlesExisting.length);

      setNewArticlesSourceData(
        articlesNew.map((article) => ({
          owner: article.OwnerName,
          name: article.ArticleName,
          ownership: article.Ownership || undefined,
          releaseDate: article.ReleaseDate || undefined,
          EAN: article.ArticleAlias.find(
            (alias) => alias.CodeNameType === "EAN"
          )?.CodeName,
          ISRC: article.ArticleAlias.find(
            (alias) => alias.CodeNameType === "ISRC"
          )?.CodeName,
          GRid: article.ArticleAlias.find(
            (alias) => alias.CodeNameType === "GRid"
          )?.CodeName,
          UPC: article.ArticleAlias.find(
            (alias) => alias.CodeNameType === "UPC"
          )?.CodeName,
        }))
      );
      setInvalidRows(rowsInvalid);

      setStep(2);
    },
  });

  const commitMutation = useMutation({
    mutationFn: async (importFile: File) => {
      const fragmentsToSubmit = articlesToSubmit.flatMap(
        (article) => article.fragments
      );

      return await api.articles.articleCreateBulk(
        {
          type:
            catalogueType === "track" ? ArticleType.Track : ArticleType.Merch,
          commit: true,
        },
        { file: importFile, fragments: JSON.stringify(fragmentsToSubmit) }
      );
    },
    onSuccess: ({ data: { invalid } }) => {
      if (invalid.length > 0) {
        console.error(invalid);

        notifications.show({
          color: "#dc2626",
          title: "Některé artikly nebyly vytvořeny.",
          message: "Více informací v konzoli.",
        });
      } else {
        cancelImport();

        notifications.show({
          color: "#22c55e",
          title: "Povedlo se!",
          message: "Artikly byly vytvořeny.",
        });
      }
    },
    onError: (error) => {
      console.error(error);

      notifications.show({
        color: "#dc2626",
        title: "Chyba při vytváření artiklů.",
        message: "Více informací v konzoli.",
      });
    },
  });

  const validationSchema = yup.object().shape({
    fragments: yup
      .array()
      .of(
        yup.object().shape({
          subjectId: yup.number().required().moreThan(0),
          role: yup.string(),
          percentage: yup
            .string()
            .required()
            .matches(/^[1-9][0-9]?(\.[0-9][1-9]?)?$|^100$/),
        })
      )
      .min(1),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    setValue,
    clearErrors,
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    reset();
  }, [open]);

  const { fields, append, remove } = useFieldArray({
    name: "fragments",
    control,
  });

  async function onSubmit({ fragments }: { [x: string]: any }) {
    console.log(fragments);

    setSumError(false);
    let sumMatches = true;

    if (catalogueType === "merch") {
      const [expenseFragmentSum, incomeFragmentSum] = fragments.reduce(
        (
          [expenseSum, incomeSum]: [number, number],
          {
            fragmentType,
            percentage,
          }: { fragmentType: string; percentage: string }
        ) =>
          fragmentType === "MERCH"
            ? [(expenseSum = expenseSum + parseInt(percentage)), incomeSum]
            : [expenseSum, (incomeSum = incomeSum + parseInt(percentage))],
        [0, 0]
      );

      if (expenseFragmentSum !== 100 || incomeFragmentSum !== 100) {
        sumMatches = false;
      }
    } else {
      const fragmentSum = fragments.reduce(
        (sum: number, { percentage }: { percentage: string }) =>
          (sum = sum + parseInt(percentage)),
        0
      );

      if (fragmentSum !== 100) {
        sumMatches = false;
      }
    }

    if (!sumMatches) {
      setSumError(true);

      return;
    }

    const article = newArticlesSourceData[articleQueueStep - 1];

    setArticlesToSubmit([
      ...articlesToSubmit,
      {
        ...article,
        fragments: fragments.map(
          (f: {
            subjectId: number;
            percentage: string;
            role: string;
            fragmentType: DistributionFragmentTypeTSOA;
          }) => {
            let [code, codeType]: [
              string | undefined,
              UniqueNameType | undefined
            ] = [undefined, undefined];

            if (article.ISRC !== undefined) {
              code = article.ISRC;
              codeType = UniqueNameType.ISRC;
            } else if (article.GRid !== undefined) {
              code = article.GRid;
              codeType = UniqueNameType.GRid;
            } else if (article.UPC !== undefined) {
              code = article.UPC;
              codeType = UniqueNameType.UPC;
            } else if (article.EAN !== undefined) {
              code = article.EAN;
              codeType = UniqueNameType.EAN;
            }

            if (code === undefined || codeType === undefined) {
              throw new Error("No code found");
            }

            return {
              subjectId: f.subjectId,
              percentage: f.percentage,
              role: f.role,
              type: f.fragmentType,
              uniqNameType: codeType,
              uniqName: code,
            };
          }
        ),
      },
    ]);

    if (!persistDistribution) {
      reset({ fragments: [] });
    }

    setArticleQueueStep(articleQueueStep + 1);

    if (articleQueueStep === newArticlesSourceData.length) {
      setStep(4);
    }
  }

  function renderSwitch(
    step: number,
    subjects: SubjectWithArtist[],
    templates: (DistributionTemplate & {
      Fragments: (DistributionTemplateFragment & {
        Subject: Subject | null;
      })[];
    })[]
  ) {
    switch (step) {
      case 1:
        return (
          <form className="flex-1">
            <h3 className="px-4 pb-8 mt-8 text-lg font-medium truncate text-neutral-900">
              Nahrání souboru
            </h3>
            <p className="col-span-3 px-4 text-sm text-neutral-700">
              CSV ve formátu{" "}
              {catalogueType === "track" ? (
                <span className="font-mono">
                  "OWNER","ARTICLE","OWNERSHIP","RELEASE DATE","CODE ISRC","CODE
                  GRID","CODE UPC"
                </span>
              ) : (
                <span className="font-mono">"CATEGORY","ARTICLE","EAN"</span>
              )}
            </p>
            <div className="grid w-full grid-cols-3 px-4 pt-4 pb-16 border-b gap-x-4 border-neutral-200">
              <div className="relative">
                <input
                  className="flex items-center justify-center h-[38px] p-2 text-sm border cursor-pointer text-neutral-900 border-neutral-300 bg-neutral-50 dark:text-neutral-400 focus:outline-none"
                  id="file"
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => {
                    if (
                      e.currentTarget.files !== null &&
                      e.currentTarget.files.length > 0
                    ) {
                      setBulkImportFile(e.currentTarget.files[0]);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex items-center justify-between pt-8">
              <div className="text-sm text-red-600">
                {initialUploadMutation.isError &&
                  getErrorMessage(
                    initialUploadMutation.error as { status: number }
                  )}
              </div>
              <div className="flex gap-x-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={cancelImport}
                >
                  Zrušit
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (!initialUploadMutation.isLoading) {
                      initialUploadMutation.mutate(bulkImportFile!);
                    }
                  }}
                  disabled={!uploadPossible}
                  className={classNames(
                    !uploadPossible
                      ? "cursor-not-allowed bg-neutral-300"
                      : "bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500",
                    "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm"
                  )}
                >
                  {initialUploadMutation.isLoading ? (
                    <svg
                      className={"animate-spin h-5 w-5"}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Nahrát"
                  )}
                </button>
              </div>
            </div>
          </form>
        );

      case 2:
        return (
          <div className="flex flex-col w-full">
            <span className="px-4 pt-12 pb-8 text-sm font-medium text-neutral-700">
              Nahrání bylo úspěšné.{" "}
              {invalidRows.length > 0 && (
                <>
                  <strong>{invalidRows.length}</strong>{" "}
                  {declineInvalidRows(invalidRows.length)}.{" "}
                </>
              )}
              {existingArticlesCount > 0 && (
                <>
                  <strong>{existingArticlesCount}</strong>{" "}
                  {declineArticles(existingArticlesCount)[0]}.{" "}
                </>
              )}
              <strong>{newArticlesSourceData.length}</strong>{" "}
              {declineArticles(newArticlesSourceData.length)[1]} k vytvoření.
            </span>

            {invalidRows.length > 0 && (
              <h3 className="px-4 pt-4 mt-2 mb-8 text-lg font-medium truncate border-t text-neutral-900 border-neutral-200">
                Neplatné řádky
              </h3>
            )}

            {invalidRows.length > 0 && (
              <div className="flex flex-col px-4 space-y-4">
                {invalidRows.map((row) => (
                  <div key={row.row} className="flex flex-col ">
                    <div className="font-mono text-sm">{row.row}</div>
                    <div className="text-sm text-neutral-600">{row.reason}</div>
                  </div>
                ))}
              </div>
            )}

            <div className="flex items-center justify-end pt-8 mt-4 border-t border-neutral-200">
              <div className="flex gap-x-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={cancelImport}
                >
                  Zrušit
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setStep(3);
                  }}
                  disabled={newArticlesSourceData.length === 0}
                  className={classNames(
                    newArticlesSourceData.length === 0
                      ? "cursor-not-allowed bg-neutral-300"
                      : "bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500",
                    "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm"
                  )}
                >
                  Přejít k tvorbě
                </button>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col w-full"
          >
            <div className="w-full">
              <h3 className="px-4 pb-4 mt-8 text-lg font-medium truncate border-b text-neutral-900 border-neutral-200">
                Tvorba artiklu {articleQueueStep} z{" "}
                {newArticlesSourceData.length}
              </h3>
            </div>

            <div className={"flex flex-col space-y-4"}>
              <div className="px-4 py-8 space-y-4 border-b border-neutral-200">
                <StaticInputField
                  sourceKey="owner"
                  sourceData={newArticlesSourceData}
                  label={
                    catalogueType === "track"
                      ? "Hlavní umělci"
                      : "Hlavní kategorie"
                  }
                  index={articleQueueStep - 1}
                />
                <StaticInputField
                  sourceKey="name"
                  sourceData={newArticlesSourceData}
                  label={
                    catalogueType === "merch" ? "Název artiklu" : "Název tracku"
                  }
                  index={articleQueueStep - 1}
                />
                {catalogueType === "track" && (
                  <StaticInputField
                    sourceKey="ownership"
                    sourceData={newArticlesSourceData}
                    label="Vlastnictví"
                    index={articleQueueStep - 1}
                    transform={(value) => value.toLocaleLowerCase()}
                  />
                )}
                {catalogueType === "track" && (
                  <StaticInputField
                    sourceKey="releaseDate"
                    sourceData={newArticlesSourceData}
                    label="Datum vydání"
                    index={articleQueueStep - 1}
                    transform={(value) => {
                      if (!value) return "";

                      const date = new Date(value);
                      return date.toISOString().split("T")[0];
                    }}
                  />
                )}
                <StaticInputField
                  sourceKey="EAN"
                  sourceData={newArticlesSourceData}
                  label="EAN"
                  index={articleQueueStep - 1}
                />
                {catalogueType === "track" && (
                  <StaticInputField
                    sourceKey="ISRC"
                    sourceData={newArticlesSourceData}
                    label="ISRC"
                    index={articleQueueStep - 1}
                  />
                )}
                {catalogueType === "track" && (
                  <StaticInputField
                    sourceKey="GRid"
                    sourceData={newArticlesSourceData}
                    label="GRid"
                    index={articleQueueStep - 1}
                  />
                )}
              </div>

              <div className="flex items-center justify-between px-4 mb-5">
                <h2 className="text-lg font-medium text-neutral-900">
                  Rozdělení{catalogueType === "merch" ? " výnosů a " : " "}
                  nákladů
                </h2>

                <div className="flex items-center">
                  {articleQueueStep < newArticlesSourceData.length && (
                    <React.Fragment>
                      <input
                        type="checkbox"
                        id="persistDistribution"
                        checked={persistDistribution}
                        onChange={(e) =>
                          setPersistDistribution(e.target.checked)
                        }
                        className="w-4 h-4 rounded text-sky-600 border-neutral-300 focus:ring-sky-500"
                      />
                      <label
                        htmlFor="persistDistribution"
                        className="ml-2 text-sm text-neutral-700"
                      >
                        Předvyplnit u dalších artiklů
                      </label>
                    </React.Fragment>
                  )}
                </div>
              </div>

              <BulkFragmentArray
                sumError={sumError}
                subjects={subjects}
                templates={templates}
                roles={distributionRoles}
                register={register}
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
                errors={errors}
                fieldArrayConfig={{ fields, append, remove }}
                includeType={false}
              />
            </div>

            <div className="flex items-center justify-between pt-8 mt-4 border-t border-neutral-200">
              <span className="text-sm font-medium text-neutral-700">
                {articleQueueStep} z {newArticlesSourceData.length}
              </span>
              <button
                type="submit"
                disabled={fields.length === 0}
                className={classNames(
                  fields.length === 0
                    ? "cursor-not-allowed bg-neutral-300"
                    : "bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500",
                  "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm"
                )}
              >
                {articleQueueStep < newArticlesSourceData.length
                  ? "Přejít na další"
                  : "Přejít ke kontrole"}
              </button>
            </div>
          </form>
        );

      case 4:
        return (
          <div className="flex flex-col w-full">
            <div className="w-full">
              <h3 className="px-4 pb-4 mt-8 text-lg font-medium truncate text-neutral-900 ">
                Kontrola před vytvořením artiklů
              </h3>

              {articlesToSubmit.map((article, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 px-4 text-xs border-b border-neutral-200 first-of-type:border-t"
                >
                  <div className="grid content-start grid-cols-2 py-4 pr-4 border-r gap-y-1 border-neutral-100">
                    <div className="grid grid-cols-2 col-span-2 gap-2 font-medium">
                      {catalogueType === "merch" ? (
                        <div className="col-span-2">
                          {article.owner} – {article.name}
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className="truncate">
                            {article.owner} – {article.name}
                          </div>
                          <div className="text-right">
                            {article.ownership?.toLocaleLowerCase()}
                            {" od "}
                            {article.releaseDate
                              ? new Date(article.releaseDate)
                                  .toISOString()
                                  .split("T")[0]
                              : ""}
                          </div>
                        </React.Fragment>
                      )}
                    </div>

                    {article.EAN && (
                      <div className="font-medium">
                        EAN <span className="font-bold">{article.EAN}</span>
                      </div>
                    )}
                    {article.ISRC && (
                      <div className="font-medium">
                        ISRC <span className="font-bold">{article.ISRC}</span>
                      </div>
                    )}
                    {article.GRid && (
                      <div className="font-medium">
                        GRid <span className="font-bold">{article.GRid}</span>
                      </div>
                    )}
                    {article.UPC && (
                      <div className="font-medium">
                        UPC <span className="font-bold">{article.UPC}</span>
                      </div>
                    )}
                  </div>
                  <div className="grid content-start grid-cols-3 py-4 pl-4 gap-y-1">
                    {article.fragments
                      .sort((a, b) => {
                        if (
                          a.type === DistributionFragmentTypeTSOA.MERCH &&
                          b.type !== DistributionFragmentTypeTSOA.MERCH
                        ) {
                          return -1;
                        }
                        if (
                          a.type !== DistributionFragmentTypeTSOA.MERCH &&
                          b.type === DistributionFragmentTypeTSOA.MERCH
                        ) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((f, fragmentIndex) => (
                        <React.Fragment key={fragmentIndex}>
                          <div className="font-bold truncate">
                            {subjects.find((s) => s.Id === f.subjectId)?.Name}
                          </div>
                          <div className="text-right truncate">{f.role}</div>
                          <div className="font-bold text-right truncate">
                            {f.type === DistributionFragmentTypeTSOA.MERCH
                              ? "náklad"
                              : "výnos"}{" "}
                            {f.percentage}
                            {" %"}
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-end pt-8 mt-8 border-t border-neutral-200">
              <button
                type="button"
                onClick={() => {
                  if (!commitMutation.isLoading) {
                    commitMutation.mutate(bulkImportFile!);
                  }
                }}
                className={classNames(
                  "bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500",
                  "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm"
                )}
              >
                {commitMutation.isLoading ? (
                  <svg
                    className={"animate-spin h-5 w-5"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Definitivně potvrdit"
                )}
              </button>
            </div>
          </div>
        );

      default:
        return <span>uhh tohle bys asi nemel videt</span>;
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen px-6 pt-6 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-700" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full max-w-4xl my-12 text-left align-middle transition-all transform bg-white shadow-xl">
              <div className="h-full px-6 my-8">
                <div className="flex flex-col mt-4">
                  {isLoadingSubjects ||
                  isLoadingTemplates ||
                  subjects === undefined ||
                  templates === undefined ? (
                    <div className="flex justify-center py-16">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <ModalNavigation
                        step={step}
                        labels={[
                          "Nahrání souboru",
                          "Info",
                          "Tvorba artiklů",
                          "Kontrola",
                        ]}
                      />
                      {renderSwitch(step, subjects, templates)}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
