import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import {
  ArticleOwnership,
  ArticleType,
  ArticleWithAlias,
  DistributionFragmentTSOA,
  Subject,
  WarehouseTSOA,
} from "../../../services/api";
import { classNames, formatDate } from "../../../util/general";
import {
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  Bars4Icon,
} from "@heroicons/react/24/solid";
import DistributionPopover from "./DistributionPopover";
import WarehousePopover from "./WarehousePopover";

const ArticleTable = ({
  catalogueType,
  articles,
  setArticleToRemoveId,
  setArticleToProcessWarehouse,
  setRemoveModalOpen,
  setAddWarehouseModalOpen,
  setRemoveWarehouseModalOpen,
}: {
  catalogueType: ArticleType;
  articles: ArticleWithAlias[];
  setArticleToRemoveId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setArticleToProcessWarehouse: React.Dispatch<
    React.SetStateAction<ArticleWithAlias | undefined>
  >;
  setRemoveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAddWarehouseModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveWarehouseModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const data = useMemo(() => {
    return articles.map((article) => {
      const {
        Id,
        OwnerName,
        ArticleName,
        Groups,
        DistributionFragments,
        Warehouse,
        ArticleType,
        Ownership,
        ReleaseDate,
        $CanBeDeleted,
      } = article;

      return {
        id: Id,
        owner: OwnerName,
        nameOwnership: { name: ArticleName, ownership: Ownership },
        // groups: { groupMemberships: Groups },
        releaseDate: ReleaseDate,
        actions: {
          warehouse: Warehouse,
          fragments: DistributionFragments,
          articleId: Id,
          type: ArticleType,
          article,
          deletable: $CanBeDeleted,
        },
      };
    });
  }, [articles]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id" as const,
        disableSortBy: true,
        Cell: ({ value }: { value: number }) => (
          <div className="col-span-2 px-4 py-2 text-sm text-left text-neutral-400 whitespace-nowrap border-neutral-200">
            <span>#{value}</span>
          </div>
        ),
      },
      {
        Header:
          catalogueType === ArticleType.Track
            ? "Hlavní umělci"
            : "Hlavní kategorie",
        accessor: "owner" as const,
        sortType: "basic",
        Cell: ({ value }: { value: string }) => (
          <div className="px-4 py-2 text-sm font-medium text-left text-neutral-500 whitespace-nowrap">
            <span>{value}</span>
          </div>
        ),
      },
      {
        Header: "Název",
        accessor: "nameOwnership" as const,
        sortType: "basic",
        Cell: ({
          value: { name, ownership },
        }: {
          value: { name: string; ownership: ArticleOwnership | null };
        }) => (
          <div className="px-4 py-2 text-sm font-medium text-left text-neutral-900 whitespace-nowrap">
            <span>{name}</span>
            {ownership !== null && (
              <span
                className={classNames(
                  ownership === ArticleOwnership.LICENSED
                    ? "bg-neutral-200 text-neutral-600"
                    : "bg-sky-500 text-white",
                  "ml-2 inline-flex items-center px-2 rounded-full text-[10px] py-0.5 leading-3 font-medium cursor-default"
                )}
                title={`${ownership.slice(0, 1).toUpperCase()}${ownership
                  .slice(1)
                  .toLowerCase()}`}
              >
                {ownership.slice(0, 1)}
              </span>
            )}
          </div>
        ),
      },
      {
        Header: "Datum vydání",
        accessor: "releaseDate" as const,
        disableSortBy: true,
        Cell: ({ value }: { value: string | null }) => (
          <div className="px-4 py-2 text-sm font-medium text-left text-neutral-500 whitespace-nowrap">
            <span>{value !== null ? formatDate(value) : ""}</span>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "actions" as const,
        disableSortBy: true,
        Cell: ({
          value,
        }: {
          value: {
            warehouse: WarehouseTSOA[];
            fragments: (DistributionFragmentTSOA & { Subject: Subject })[];
            articleId: number;
            type: ArticleType;
            deletable: boolean;
            article: ArticleWithAlias;
          };
        }) => (
          <div className="relative flex justify-end invisible py-2 pl-3 pr-4 space-x-2 text-sm font-medium text-right whitespace-nowrap sm:pr-6 group-hover:visible">
            <DistributionPopover distribution={value.fragments} />
            {value.type === "merch" && (
              <WarehousePopover warehouse={value.warehouse} />
            )}
            {value.type === "merch" && (
              <button
                onClick={() => {
                  setArticleToProcessWarehouse(value.article);
                  setAddWarehouseModalOpen(true);
                }}
                className="font-medium underline text-sky-600 hover:no-underline focus:outline-none"
              >
                Naskladnit
              </button>
            )}
            {value.type === "merch" && (
              <button
                onClick={() => {
                  setArticleToProcessWarehouse(value.article);
                  setRemoveWarehouseModalOpen(true);
                }}
                className="font-medium underline text-sky-600 hover:no-underline"
              >
                Vyskladnit
              </button>
            )}
            <button
              onClick={() => {
                if (value.deletable) {
                  setArticleToRemoveId(value.articleId);
                  setRemoveModalOpen(true);
                }
              }}
              className={classNames(
                value.deletable
                  ? "hover:no-underline"
                  : "opacity-50 cursor-not-allowed",
                "underline font-medium text-red-600"
              )}
            >
              Odstranit
            </button>
          </div>
        ),
      },
    ],
    [catalogueType]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  if (!rows.length) {
    return (
      <div className="px-6 py-12 overflow-hidden text-center bg-white shadow rounded-b-lgg">
        <Bars4Icon className="w-12 h-12 mx-auto text-neutral-400" />

        <h3 className="mt-2 text-sm font-medium text-neutral-900">
          Bez položek
        </h3>
        <p className="mt-1 text-sm text-neutral-500">
          Pro zvolené filtry nebyly nalezeny žádné položky.
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-shown sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="shadow overflow-y-shown ring-1 ring-black ring-opacity-5 ">
            <table
              className="min-w-full divide-y divide-neutral-300"
              {...getTableProps()}
            >
              <thead className="bg-neutral-50">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, x) => (
                      <th
                        key={column.id}
                        scope="col"
                        className={classNames(
                          x === 3 && "w-full",
                          "px-4 py-1.5 text-xs font-semibold tracking-wider text-left uppercase whitespace-nowrap text-neutral-500"
                        )}
                      >
                        <div
                          className={
                            "justify-end flex-row-reverse flex items-center w-full"
                          }
                        >
                          {x > 0 && x < 3 && (
                            <button
                              type="button"
                              title="Seřadit"
                              onClick={(e) =>
                                (
                                  column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  ) as any
                                ).onClick(e)
                              }
                              className="inline-flex items-center p-1 mx-2 bg-transparent border border-transparent rounded-full text-neutral-500 hover:bg-neutral-200 focus:outline-none"
                            >
                              {column.isSortedDesc ? (
                                <Bars4Icon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              ) : column.isSortedDesc === false ? (
                                <BarsArrowDownIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <BarsArrowUpIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              )}
                            </button>
                          )}
                          <span>{column.render("Header")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white divide-y divide-neutral-200"
                {...getTableBodyProps()}
              >
                {rows.map((row) => {
                  prepareRow(row);

                  return (
                    <tr {...row.getRowProps()} className="group">
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleTable;
