import React, { useEffect } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink, useParams } from "react-router-dom";
import { extractParam } from "../../../util/general";
import MetadataOverview from "./MetadataOverview";

const Artist = () => {
  const { rawArtistID } = useParams();
  const artistID = extractParam(rawArtistID, 0);

  useEffect(() => {
    window.document.title = "Profil umělce — Accountant";
  }, []);

  // useEffect(() => {
  //   window.document.title = `Profil umělce ${artist.Subject.Name} — Accountant`;
  // }, [artist]);

  return (
    <div className="flex flex-col flex-1 pt-10 pb-16 bg-neutral-100">
      <main className="flex-1">
        <div className="h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 overflow-visible align-middle min-h-[32rem] sm:px-6 lg:px-8">
              <div className="flex justify-start py-2 mb-6 items-top">
                <div className="flex space-x-3">
                  <NavLink
                    to={`/account/1/0?tab=0`}
                    className="inline-flex items-center px-2 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
                    title="Zpět na účet"
                  >
                    <ArrowLeftIcon className="w-4 h-4 text-neutral-900" />
                  </NavLink>
                  <span className="text-3xl font-bold text-neutral-900">
                    Calin
                  </span>
                </div>
              </div>

              <MetadataOverview />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Artist;
