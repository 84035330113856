import React, { useState } from "react";
import {
  FieldValues,
  UseFormRegister,
  UseFormWatch,
  UseFormSetValue,
  UseFormClearErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from "react-hook-form";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import {
  DistributionFragmentType,
  DistributionTemplate,
  DistributionTemplateFragment,
  Subject,
} from "../../../services/api";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../util/general";

import DropdownSelector from "../../upsert-articles/components/DropdownSelector";
import PercentageInput from "../../upsert-articles/components/PercentageInput";

const BulkFragmentArray = ({
  fieldArrayConfig,
  sumError,
  subjects,
  templates,
  roles,
  register,
  watch,
  setValue,
  clearErrors,
  errors,
  includeType,
}: {
  fieldArrayConfig: {
    fields: Record<"id", string>[];
    append: UseFieldArrayAppend<FieldValues, any>;
    remove: UseFieldArrayRemove;
  };
  sumError: boolean;
  subjects: Subject[];
  templates: (DistributionTemplate & {
    Fragments: (DistributionTemplateFragment & {
      Subject: Subject | null;
    })[];
  })[];
  roles: {
    Id: number;
    Name: string;
  }[];
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: {
    [x: string]: any;
  };
  includeType: boolean;
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<
    | (DistributionTemplate & {
        Fragments: (DistributionTemplateFragment & {
          Subject: Subject | null;
        })[];
      })
    | null
  >(null);

  const { fields, append, remove } = fieldArrayConfig;

  function addFragment() {
    const fragmentSum = (fields as any).reduce(
      (sum: number, { percentage }: { percentage: string }) =>
        (sum = sum + parseInt(percentage)),
      0
    );

    append({
      subjectId: 0,
      type: "",
      role: "",
      percentage: Math.min((includeType ? 200 : 100) - fragmentSum, 100),
    });
  }

  return (
    <div className="px-4">
      {fields.map((item, i) => (
        <div
          key={item.id}
          className="grid w-full grid-cols-12 col-span-6 py-1 gap-x-4"
        >
          <div
            className={classNames(includeType ? "col-span-3" : "col-span-6")}
          >
            <DropdownSelector
              rowIndex={i}
              type={"subjectId"}
              values={subjects}
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
              limitHeight
            />
          </div>
          {includeType && (
            <div className="col-span-3">
              <DropdownSelector
                rowIndex={i}
                type={"fragmentType"}
                values={[
                  { Id: 1, Name: "Dělení nákladů" },
                  { Id: 2, Name: "Dělení výnosů" },
                ]}
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
                errors={errors}
              />
            </div>
          )}

          <div className="col-span-3">
            <DropdownSelector
              rowIndex={i}
              type={"role"}
              values={roles}
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
            />
          </div>

          <div className="grid grid-cols-3 col-span-3">
            <PercentageInput rowIndex={i} register={register} errors={errors} />

            <div className="flex justify-end">
              <div className="flex flex-col">
                <button
                  type="button"
                  className="inline-flex items-center p-1 mt-auto mb-1 ml-4 bg-transparent border border-transparent rounded-full text-neutral-500 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  onClick={() => remove(i)}
                >
                  <XCircleIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="flex justify-between col-span-8 pt-8 pb-4">
        <div className="flex items-center space-x-4">
          {sumError && (
            <span className="text-sm text-red-600">
              Součet podílů neodpovídá {includeType ? "celkům" : "celku"}.
            </span>
          )}
        </div>

        <div className="flex items-center space-x-4">
          <Listbox
            value={selectedTemplate}
            onChange={(template) => {
              setSelectedTemplate(template);
              if (!template) return;

              // Clear entire form
              remove();

              // Append new fields from the selected template
              template.Fragments.forEach((fragment) => {
                append({
                  subjectId: fragment.SubjectId,
                  fragmentType: fragment.Type,
                  role: fragment.Role,
                  percentage: fragment.Percentage.toString(),
                });
              });

              // Clear any existing errors
              clearErrors();
            }}
          >
            {({ open }) => (
              <div className="relative ml-auto">
                <Listbox.Button className="relative py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default w-96 border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none focus:ring-1 sm:text-sm">
                  <span className="block truncate">Vyplnit z šablony</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon
                      className="w-5 h-5 text-neutral-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white divide-y shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {templates.map((template) => (
                      <Listbox.Option
                        key={template.Id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-sky-500"
                              : "text-neutral-900",
                            "cursor-default select-none relative py-2 px-3"
                          )
                        }
                        value={template}
                      >
                        {() => (
                          <div className="grid grid-cols-3 gap-2 text-xs">
                            {template.Fragments.sort((a, b) => {
                              if (
                                a.Type === DistributionFragmentType.MERCH &&
                                b.Type !== DistributionFragmentType.MERCH
                              ) {
                                return -1;
                              }
                              if (
                                a.Type !== DistributionFragmentType.MERCH &&
                                b.Type === DistributionFragmentType.MERCH
                              ) {
                                return 1;
                              }
                              return 0;
                            }).map((f, index) => (
                              <React.Fragment
                                key={`${template.Id}-${f.Id}-${index}`}
                              >
                                <div className="font-bold truncate">
                                  {f.Subject?.Name}
                                </div>
                                <div className="text-right truncate">
                                  {f.Role}
                                </div>
                                <div className="font-bold text-right truncate">
                                  {f.Type === DistributionFragmentType.MERCH
                                    ? "náklad"
                                    : "výnos"}{" "}
                                  {f.Percentage}
                                  {" %"}
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>

          <span className="text-sm">nebo</span>

          <button
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
            type="button"
            onClick={addFragment}
          >
            Přidat řádek
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkFragmentArray;
