import React, { Fragment } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../../App";
import Loading from "../../../components/Loading";
import { ArticlesResponse } from "../../../services/api";
import { Transition } from "@headlessui/react";
import UpsertForm from "./UpsertForm";
import { QueryRefetch } from "../../../util/api";
import UpsertMerchForm from "./UpsertMerchForm";
import { distributionRoles } from "../../../util/roles";

export default function UpsertModal({
  mode,
  catalogueType,
  open,
  setOpen,
  refetch,
}: {
  mode: "insert" | "update";
  catalogueType: "track" | "merch";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: QueryRefetch<ArticlesResponse>;
}) {
  const api = useApi();

  const { data: subjects, isLoading } = useQuery(
    ["subjects"],
    async () => await api.subjects.getSubjects(),
    {
      select: (x) => x.data.subjects,
    }
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="relative z-10 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative text-left transition-all transform bg-white shadow-xl overflow-shown sm:my-8 sm:max-w-3xl sm:w-full">
                {isLoading || subjects === undefined ? (
                  <div className="flex justify-center w-full h-full py-8">
                    <Loading />
                  </div>
                ) : catalogueType === "track" ? (
                  <UpsertForm
                    subjects={subjects}
                    roles={distributionRoles}
                    mode={mode}
                    catalogueType={catalogueType}
                    setOpen={setOpen}
                    refetch={refetch}
                  />
                ) : (
                  <UpsertMerchForm
                    subjects={subjects}
                    roles={distributionRoles}
                    mode={mode}
                    catalogueType={catalogueType}
                    setOpen={setOpen}
                    refetch={refetch}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
}
