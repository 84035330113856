import { DraggableLocation } from "react-beautiful-dnd";
import { ArticleWithAlias } from "../services/api";

export const reorder = (list: ArticleWithAlias[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const move = (
  source: ArticleWithAlias[],
  destination: ArticleWithAlias[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [x: string]: ArticleWithAlias[] } = {};

  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const getItemStyle = (draggableStyle: any) => ({
  ...draggableStyle,
});
