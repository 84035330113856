import React from "react";
import { Subject } from "../../../services/api";
import { classNames } from "../../../util/general";

export default function SubjectPill({ affiliations }: { affiliations: { Subject: Subject }[] | undefined }) {
  if (!affiliations || affiliations.length === 0) {
    return (
      <span className="bg-neutral-100 text-neutral-800 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
        nezadáno
      </span>
    );
  }

  if (affiliations.length > 1) {
    return (
      <span className="bg-blue-100 text-blue-800 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
        více subjektů
      </span>
    );
  }

  const subjectName = affiliations[0].Subject.Name;

  return (
    <span
      className={classNames(
        subjectName.startsWith("MIKE ROFT") ? "bg-black text-white" : "bg-green-100 text-green-800",
        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
      )}
    >
      {subjectName}
    </span>
  );
}
