import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { endOfDay, formatISO } from "date-fns";
import { useApi } from "../../../App";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "react-query";
import { classNames, displayGroupOwners } from "../../../util/general";
import { Dialog } from "@headlessui/react";
import Loading from "../../../components/Loading";
import TabBar from "./TabBar";
import MetadataForm from "./MetadataForm";
import {
  ArticleWithAlias,
  Subject,
  UniqueNameType,
} from "../../../services/api";
import TracklistPicker from "./TracklistPicker";

const AddAlbumModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const api = useApi();
  const [shownTab, setShownTab] = useState(0);
  const [saving, setSaving] = useState(false);

  const [selectedSubjects, setSelectedSubjects] = useState<Subject[]>([]);
  const [disableTracklist, setDisableTracklist] = useState(true);
  const [tracklist, setTracklist] = useState<ArticleWithAlias[]>([]);

  const ownerName = displayGroupOwners(selectedSubjects);

  useEffect(() => {
    setDisableTracklist(selectedSubjects.length === 0);
  }, [selectedSubjects]);

  const { data: subjects, isLoading } = useQuery(
    ["subjects"],
    async () => await api.subjects.getSubjects(),
    {
      select: (x) => x.data.subjects,
    }
  );

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    groupType: yup.string().required(),
    codeName: yup.string().required(),
    coverLink: yup.string().required(),
    releaseDate: yup.date().required(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: { name: "", codeName: "", coverLink: "" },
    resetOptions: {
      keepDirtyValues: true,
    },
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  async function onSubmit(data: { [x: string]: any }) {
    if (!selectedSubjects.length || !tracklist.length) {
      return;
    }

    setSaving(true);

    const groupCreation = {
      name: data.name,
      ownerName,
      type: data.groupType,
      releaseDate: formatISO(endOfDay(data.releaseDate)),
      codeName: data.codeName,
      codeNameType: "EAN" as UniqueNameType,
      coverLink: data.coverLink,
      articles: tracklist.map(({ Id }, $Order) => ({ Id, $Order })),
    };

    const response = await api.articles.groupCreateSingle(groupCreation);

    if (response.ok) {
      console.log(response);
      setSaving(false);
      setOpen(false);
    } else {
      console.error(response);
    }
  }

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      open={open}
      onClose={() => {}}
    >
      <div className="flex items-end justify-center min-h-screen px-6 pt-6 pb-20 text-center sm:block sm:p-0">
        <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-700" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <>
          <div className="inline-block w-full max-w-6xl my-12 text-left align-middle transition-all transform bg-white shadow-xl ">
            <div className="flex flex-col">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-6 my-8">
                  <Dialog.Title
                    as="h3"
                    className="mb-8 text-lg font-medium leading-6 text-neutral-900"
                  >
                    Přidat album nebo EP
                  </Dialog.Title>

                  {isLoading || saving || subjects === undefined ? (
                    <div className="flex justify-center w-full h-full py-8">
                      <Loading />
                    </div>
                  ) : (
                    <div className="mt-2">
                      <TabBar
                        shownTab={shownTab}
                        setShownTab={setShownTab}
                        disableTracklist={disableTracklist}
                      />

                      {shownTab === 0 ? (
                        <MetadataForm
                          subjects={subjects}
                          selectedSubjects={selectedSubjects}
                          setSelectedSubjects={setSelectedSubjects}
                          errors={errors}
                          control={control}
                          register={register}
                        />
                      ) : (
                        <TracklistPicker
                          owners={selectedSubjects}
                          tracklist={tracklist}
                          setTracklist={setTracklist}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="py-3 w-full bg-neutral-50 px-6 h-[62px] flex flex-row-reverse">
                  <button
                    type="submit"
                    disabled={saving}
                    className={classNames(
                      saving && "invisible",
                      "inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent  shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                    )}
                  >
                    Uložit
                  </button>
                  <button
                    type="button"
                    disabled={saving}
                    className={classNames(
                      saving && "invisible",
                      "inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-neutral-700 bg-white border border-neutral-300  shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    )}
                    onClick={() => setOpen(false)}
                  >
                    Zrušit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      </div>
    </Dialog>
  );
};

export default AddAlbumModal;
