import React from "react";
import {
  FolderPlusIcon,
  DocumentPlusIcon,
  RectangleStackIcon,
  AdjustmentsVerticalIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/solid";
import { ArticleType } from "../../../services/api";

const Toolbar = ({
  catalogueType,
  openInsertModal,
  openBulkImportModal,
  openAlbumModal,
  openCollectionsModal,
  openDistributionFragmentModal,
}: {
  catalogueType: ArticleType;
  openInsertModal: VoidFunction;
  openBulkImportModal: VoidFunction;
  openAlbumModal: VoidFunction;
  openCollectionsModal: VoidFunction;
  openDistributionFragmentModal: VoidFunction;
}) => {
  return (
    <div className="w-full bg-white border-b border-sky-700 min-h-[64px]">
      <div className="flex min-h-[64px] justify-between h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="flex items-center space-x-2">
          {catalogueType === ArticleType.Merch && (
            <>
              <button
                onClick={openInsertModal}
                type="button"
                className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
              >
                <DocumentPlusIcon className="w-4 h-4 mr-2 text-neutral-700" />

                <span>Přidat merch</span>
              </button>

              <button
                onClick={openBulkImportModal}
                type="button"
                className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
              >
                <DocumentDuplicateIcon className="w-4 h-4 mr-2 text-neutral-700" />

                <span>Importovat ze souboru</span>
              </button>

              <button
                onClick={openCollectionsModal}
                type="button"
                className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
              >
                <RectangleStackIcon className="w-4 h-4 mr-2 text-neutral-700" />

                <span>Spravovat prodejní kolekce</span>
              </button>
            </>
          )}

          {catalogueType === ArticleType.Track && (
            <>
              <button
                onClick={openInsertModal}
                type="button"
                className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
              >
                <DocumentPlusIcon className="w-4 h-4 mr-2 text-neutral-700" />

                <span>Přidat track</span>
              </button>

              <button
                onClick={openBulkImportModal}
                type="button"
                className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
              >
                <DocumentDuplicateIcon className="w-4 h-4 mr-2 text-neutral-700" />

                <span>Importovat ze souboru</span>
              </button>

              <button
                onClick={openAlbumModal}
                type="button"
                className="relative inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
              >
                <FolderPlusIcon className="w-4 h-4 mr-2 text-neutral-700" />

                <span>Přidat album nebo EP</span>
              </button>
            </>
          )}
        </div>

        <div className="flex items-center space-x-2">
          <button
            onClick={openDistributionFragmentModal}
            type="button"
            className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
          >
            <AdjustmentsVerticalIcon className="w-4 h-4 mr-2 text-neutral-700" />

            <span>Upravit šablony rozdělení</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
