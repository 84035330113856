import React, { Fragment, useState } from "react";
import Loading from "../../../components/Loading";
import * as yup from "yup";
import { ArticleWithAlias } from "../../../services/api";
import { Transition } from "@headlessui/react";
import { useApi } from "../../../App";
import { classNames } from "../../../util/general";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PlusIcon } from "@heroicons/react/24/solid";
import { NumericInput } from "./NumericInput";

export default function AddStockModal({
  article,
  open,
  setOpen,
}: {
  article: ArticleWithAlias;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const api = useApi();

  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);

  const validationSchema = yup.object().shape({
    price: yup.number().required().min(0),
    amount: yup.number().required().moreThan(0),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: { price: 0, amount: 0 },
  };

  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(formOptions);

  async function onSubmit(data: { [x: string]: any }) {
    setSaving(true);

    const { price, amount } = data;

    const submitResult = await api.warehouse.warehouseCreateSingle({
      articleId: article.Id,
      stock: amount,
      buyingPrice: price.toString(),
    });

    if (submitResult.ok) {
      setTimeout(() => setSuccess(true), 1500);
      setTimeout(() => {
        setOpen(false);
        setSaving(false);
        setSuccess(false);
      }, 4000);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="relative z-10 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative overflow-hidden text-left transition-all transform bg-white shadow-xl sm:my-8 sm:max-w-3xl sm:w-full">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                    <div className="relative sm:flex sm:items-start">
                      <div
                        className={classNames(
                          saving ? "visible" : "invisible",
                          "absolute w-full h-full flex justify-center items-center"
                        )}
                      >
                        {success ? (
                          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-auto bg-green-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <CheckCircleIcon
                              className="w-5 h-5 text-green-600"
                              aria-hidden="true"
                            />
                          </div>
                        ) : (
                          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <Loading />
                          </div>
                        )}
                      </div>
                      <div
                        className={classNames(
                          saving && "invisible",
                          "flex items-center justify-center flex-shrink-0 w-10 h-10 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10"
                        )}
                      >
                        <PlusIcon
                          className="w-5 h-5 text-sky-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div
                        className={classNames(
                          saving && "invisible",
                          "flex flex-col flex-1 ml-4 space-y-4"
                        )}
                      >
                        <h3 className="mb-2 text-lg font-medium truncate text-neutral-900">
                          Naskladnit {article.ArticleName}
                        </h3>

                        <dl className="flex items-start justify-between w-full pt-4">
                          <dt className="block py-2 text-sm font-medium text-neutral-700">
                            Kusová nákupní cena bez DPH [Kč]
                          </dt>
                          <dd className="w-64">
                            <NumericInput
                              valueKey="price"
                              watch={watch}
                              register={register}
                              errors={errors}
                            />
                          </dd>
                        </dl>

                        <dl className="flex items-start justify-between w-full pt-4">
                          <dt className="block py-2 text-sm font-medium text-neutral-700">
                            Množství
                          </dt>
                          <dd className="w-64">
                            <NumericInput
                              valueKey="amount"
                              watch={watch}
                              register={register}
                              errors={errors}
                            />
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="py-3 bg-neutral-50 px-6 h-[62px] flex flex-row-reverse">
                    <button
                      type="submit"
                      disabled={saving}
                      className={classNames(
                        saving && "invisible",
                        "inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent  shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                      )}
                    >
                      Potvrdit
                    </button>
                    <button
                      type="button"
                      disabled={saving}
                      className={classNames(
                        saving && "invisible",
                        "inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-neutral-700 bg-white border border-neutral-300  shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      )}
                      onClick={() => setOpen(false)}
                    >
                      Zrušit
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
}
