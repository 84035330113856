import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../../App";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { ArticleType, ArticleWithAlias, Subject } from "../../../services/api";
import Loading from "../../../components/Loading";
import { reorder, move, getItemStyle } from "../../../util/dnd";

const TracklistPicker = ({
  owners,
  tracklist,
  setTracklist,
}: {
  owners: Subject[];
  tracklist: ArticleWithAlias[];
  setTracklist: React.Dispatch<React.SetStateAction<ArticleWithAlias[]>>;
}) => {
  const api = useApi();

  const [catalogueSelection, setCatalogueSelection] = useState<
    ArticleWithAlias[]
  >([]);

  const { data: articles, isLoading } = useQuery(
    ["articles", owners],
    async () =>
      await api.articles.articlesGetAll({
        articleType: ArticleType.Track,
        ownerIDs: owners.map((x) => x.Id),
      }),
    {
      select: (x) => x.data.articles,
      enabled: owners.length > 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (articles !== undefined && catalogueSelection.length === 0) {
      setCatalogueSelection(
        articles.filter((a) => !tracklist.some((t) => t.Id === a.Id))
      );
    }
  }, [articles]);

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        source.droppableId === "catalogueSelection"
          ? catalogueSelection
          : tracklist,
        source.index,
        destination.index
      );
      if (source.droppableId === "catalogueSelection") {
        setCatalogueSelection(items as ArticleWithAlias[]);
      } else {
        setTracklist(items as ArticleWithAlias[]);
      }
    } else {
      const result =
        source.droppableId === "catalogueSelection"
          ? move(catalogueSelection, tracklist, source, destination)
          : move(tracklist, catalogueSelection, source, destination);

      setCatalogueSelection(result.catalogueSelection);
      setTracklist(result.tracklist);
    }
  }

  if (isLoading && articles === undefined) {
    return (
      <div className="flex justify-center w-full h-full py-8">
        <Loading />
      </div>
    );
  }

  if (!isLoading && catalogueSelection.length === 0 && tracklist.length === 0) {
    return (
      <div className="flex justify-center w-full h-full py-8">
        <div className="text-sm font-medium text-neutral-500">
          V katalogu nebyla nalezena žádná hudba pro dané autory.
        </div>
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="grid grid-cols-2">
        <Droppable droppableId="catalogueSelection">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="w-full mr-4"
            >
              <h3 className="mb-8 text-lg font-medium leading-6 text-sky-600">
                Návrhy z katalogu
              </h3>
              {catalogueSelection.map((item, index) => (
                <Draggable
                  key={item.Id}
                  draggableId={item.Id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(provided.draggableProps.style)}
                      className="flex select-none"
                    >
                      <div className="text-sm font-medium text-neutral-500">
                        {item.OwnerName}
                        {" – "}
                      </div>
                      <div className="ml-1 text-sm font-medium text-left text-neutral-900 whitespace-nowrap">
                        {item.ArticleName}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable droppableId="tracklist">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="w-full mr-4"
            >
              <h3 className="mb-8 text-lg font-medium leading-6 text-sky-600">
                Tracklist
              </h3>
              {tracklist.map((item, index) => (
                <Draggable
                  key={item.Id}
                  draggableId={item.Id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      style={getItemStyle(provided.draggableProps.style)}
                      className="flex select-none"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="w-6 mr-1.5 font-mono text-sm text-neutral-500">
                        {index + 1}
                      </div>
                      <div className="flex">
                        <div className="text-sm font-medium text-neutral-500">
                          {item.OwnerName}
                          {" – "}
                        </div>
                        <div className="ml-1 text-sm font-medium text-left text-neutral-900 whitespace-nowrap">
                          {item.ArticleName}
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default TracklistPicker;
