import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import Loading from "../../../components/Loading";
import { ProjectSummary } from "../../../services/api";
import { classNames, formatAmount } from "../../../util/general";
import {
  ArchiveBoxIcon,
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  Bars4Icon,
} from "@heroicons/react/24/solid";

const ProjectSummaryTable = ({
  summaries,
  setShownTab,
}: {
  summaries?: ProjectSummary[];
  setShownTab: (tabIndex: number, newProjectNumber?: number) => void;
}) => {
  if (!summaries) {
    return (
      <div className="flex justify-center my-12">
        <Loading />
      </div>
    );
  }

  const data = useMemo(() => {
    return summaries.map((summary) => ({
      id: summary.Id,
      info: { name: summary.Name, archived: summary.Archived },
      balanceCZK: summary.Balance.CZK,
      balanceEUR: summary.Balance.EUR,
    }));
  }, [summaries]);

  const columns = useMemo(
    () => [
      {
        Header: "Název složky",
        accessor: "info" as const,
        Cell: ({
          value: { name, archived },
        }: {
          value: { name: string; archived: boolean };
        }) => (
          <div
            className={classNames(
              archived ? "text-neutral-400" : "text-neutral-900",
              "py-2 pl-4 pr-3 text-sm font-medium whitespace-nowrap sm:pl-6 flex"
            )}
          >
            <span className={name ? "" : "italic"}>{name || "nezařazené"}</span>
            {archived && (
              <ArchiveBoxIcon
                className="flex-shrink-0 w-5 h-5 ml-1.5 text-neutral-400"
                aria-hidden="true"
                title="archivovaná složka"
              />
            )}
          </div>
        ),
      },
      {
        Header: "Stav v CZK",
        accessor: "balanceCZK" as const,
        sortType: "basic",
        Cell: ({ value }: { value: number }) => (
          <div className="px-2 py-2 text-sm text-right text-neutral-500 whitespace-nowrap">
            <span className="font-medium text-neutral-900">
              {formatAmount(value)}
            </span>
            {" Kč"}
          </div>
        ),
      },
      {
        Header: "Stav v EUR",
        accessor: "balanceEUR" as const,
        sortType: "basic",
        Cell: ({ value }: { value: number }) => (
          <div className="py-2 pl-2 pr-4 text-sm text-right text-neutral-500 whitespace-nowrap">
            <span className="font-medium text-neutral-900">
              {formatAmount(value)}
            </span>
            {" €"}
          </div>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div className="flex flex-col mt-8">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
            <table
              className="min-w-full divide-y divide-neutral-300"
              {...getTableProps()}
            >
              <thead className="bg-neutral-50">
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="cursor-pointer hover:bg-neutral-50"
                  >
                    {headerGroup.headers.map((column, x) => (
                      <th
                        scope="col"
                        key={column.id}
                        className={classNames(
                          x === 0
                            ? "pl-4 pr-3 sm:pl-6"
                            : x === 1
                            ? "px-2 text-right"
                            : "pl-2 pr-4 text-right",
                          "py-1.5 whitespace-nowrap text-xs tracking-wider uppercase font-semibold text-neutral-500"
                        )}
                      >
                        <div
                          className={classNames(
                            x === 0
                              ? "justify-end flex-row-reverse"
                              : "justify-end",
                            "flex items-center w-full"
                          )}
                        >
                          {x < 3 && (
                            <button
                              type="button"
                              title="Seřadit"
                              onClick={(e) =>
                                (
                                  column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  ) as any
                                ).onClick(e)
                              }
                              className="inline-flex items-center p-1 mx-2 bg-transparent border border-transparent rounded-full text-neutral-500 hover:bg-neutral-200 focus:outline-none"
                            >
                              {column.isSortedDesc ? (
                                <Bars4Icon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              ) : column.isSortedDesc === false ? (
                                <BarsArrowDownIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <BarsArrowUpIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              )}
                            </button>
                          )}
                          <span>{column.render("Header")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white divide-y divide-neutral-200"
                {...getTableBodyProps()}
              >
                {rows.map((row) => {
                  prepareRow(row);

                  return (
                    <tr
                      className="cursor-pointer hover:bg-neutral-50"
                      {...row.getRowProps()}
                      onClick={() => {
                        setShownTab(1, row.original.id);
                      }}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={classNames(index === 0 && "w-full")}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSummaryTable;
