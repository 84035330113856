import React from "react";
import { NativeSelect } from "@mantine/core";
import { Article } from "../../../services/api";

export default function MappingSelector({
  catalogue,
  alias,
  setAlias,
  setEditDisabled,
}: {
  catalogue: Article[];
  alias: Article | undefined;
  setAlias: React.Dispatch<React.SetStateAction<Article | undefined>>;
  setEditDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="flex flex-1">
      <NativeSelect
        value={alias ? alias.Id.toString() : ""}
        onChange={(event) => {
          const selectedAlias = catalogue.find(
            (a) => a.Id === parseInt(event.currentTarget.value)
          );

          if (selectedAlias) {
            setAlias(selectedAlias);
            setEditDisabled(true);
          }
        }}
        data={[
          { value: "", label: "" },
          ...catalogue.map((a) => ({
            value: a.Id.toString(),
            label: `${a.ArticleName} [${a.OwnerName}]`,
          })),
        ]}
        rightSection={<></>}
        classNames={{
          input: "leading-none h-[2.625rem]",
          root: "w-full",
          wrapper: "w-full",
        }}
      />
    </div>
  );
}
