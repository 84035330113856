import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import type {
  UseFormSetValue,
  UseFormRegister,
  UseFormWatch,
  UseFormClearErrors,
  FieldValues,
} from "react-hook-form";
import { Location } from "../../../services/api";
import { classNames, currencySymbol } from "../../../util/general";
import {
  ChevronDownIcon,
  CheckIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/solid";

export const LocationSelector = ({
  locations,
  rowIndex,
  watch,
  setValue,
  clearErrors,
  errors,
  disabled,
}: {
  locations: Location[];
  rowIndex: number;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: {
    [x: string]: any;
  };
  disabled: boolean;
}) => {
  const locationId = watch(`affiliations.${rowIndex}.location`);
  const onLocationChange = (val: Location) => {
    clearErrors(`affiliations.${rowIndex}.location`);
    setValue(`affiliations.${rowIndex}.location`, val.Id);
  };

  const displayTitle = (id: number) => {
    const location = locations[id];
    const { Title } = location;

    if (location.Parent !== undefined) {
      return `${
        locations.find((parent) => location.Parent === parent.SubjectId)!.Title
      }: ${Title}`;
    }

    return Title;
  };

  return (
    <Listbox value={locationId} onChange={onLocationChange} disabled={disabled}>
      {({ open }) => (
        <>
          {rowIndex === 0 && (
            <Listbox.Label className="block text-sm font-medium text-neutral-700">
              Přiřazení
            </Listbox.Label>
          )}
          <div className="relative mt-1">
            <Listbox.Button
              className={classNames(
                errors.affiliations?.[rowIndex]?.location === undefined
                  ? "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900"
                  : "border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500",

                "relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-mdd shadow-sm cursor-default focus:outline-none focus:ring-1 sm:text-sm"
              )}
            >
              <span className="block truncate">{displayTitle(locationId)}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className="w-5 h-5 text-neutral-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg rounded-mdd max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {locations.map(
                  (location) =>
                    location.Id !== 0 && (
                      <Listbox.Option
                        disabled={location.Archived}
                        key={location.Id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-sky-500"
                              : "text-neutral-900",
                            location.Archived
                              ? "cursor-not-allowed"
                              : "cursor-default",
                            "select-none relative py-1.5 pl-3 pr-9 flex"
                          )
                        }
                        value={location}
                      >
                        {({ active, selected }) => (
                          <div
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "truncate flex space-x-1.5 items-center justify-start"
                            )}
                          >
                            {location.Parent !== undefined && "└\u00A0\u00A0"}

                            <span
                              className={classNames(
                                location.Id === locationId
                                  ? "font-semibold"
                                  : "font-normal",
                                "block truncate"
                              )}
                            >
                              {location.Title}
                            </span>

                            {location.Archived && (
                              <ArchiveBoxIcon
                                className="flex-shrink-0 w-4 h-4"
                                aria-hidden="true"
                              />
                            )}

                            {location.Id === locationId && (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-sky-500",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    )
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export const AmountInput = ({
  rowIndex,
  currency,
  amount,
  register,
  setValue,
  errors,
  disabled,
}: {
  rowIndex: number;
  currency: string;
  amount: string;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: {
    [x: string]: any;
  };
  disabled: boolean;
}) => {
  return (
    <>
      {rowIndex === 0 && (
        <label
          htmlFor="amount"
          className="flex justify-between text-sm font-medium text-neutral-700"
        >
          <div>Částka</div>
          <button
            onClick={() =>
              setValue(
                `affiliations.${rowIndex}.amount`,
                amount.replace(".", ",")
              )
            }
            disabled={disabled}
            type="button"
            className={classNames(
              disabled
                ? "cursor-not-allowed"
                : "cursor-pointer hover:no-underline",
              "font-medium underline "
            )}
          >
            Vyplnit
          </button>
        </label>
      )}
      <div className="relative">
        <input
          type="text"
          id="amount"
          {...register(`affiliations.${rowIndex}.amount`)}
          disabled={disabled}
          className={classNames(
            errors.affiliations?.[rowIndex]?.amount === undefined
              ? "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900"
              : "border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500",

            disabled ? "cursor-not-allowed" : "cursor-default",

            "block w-full py-2 pl-3 pr-12 mt-1 border  shadow-sm focus:outline-none sm:text-sm"
          )}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <span className="text-neutral-400 sm:text-sm" id="price-currency">
            {currencySymbol(currency)}
          </span>
        </div>
      </div>
    </>
  );
};

export const TitleInput = ({
  rowIndex,
  register,
  errors,
  disabled,
}: {
  rowIndex: number;
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: any;
  };
  disabled: boolean;
}) => {
  return (
    <>
      {rowIndex === 0 && (
        <label
          htmlFor="title"
          className="block text-sm font-medium text-neutral-700"
        >
          Název položky
        </label>
      )}
      <input
        type="text"
        id="title"
        {...register(`affiliations.${rowIndex}.title`)}
        disabled={disabled}
        className={classNames(
          errors.affiliations?.[rowIndex]?.title === undefined
            ? "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900"
            : "border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500",

          disabled ? "cursor-not-allowed" : "cursor-default",

          "block w-full py-2 px-3 mt-1 border  shadow-sm focus:outline-none sm:text-sm"
        )}
      />
    </>
  );
};

export const VATSelector = ({
  rowIndex,
  setValue,
  watch,
  disabled,
}: {
  rowIndex: number;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  disabled: boolean;
}) => {
  const rates = [0, 10, 12, 15, 20, 21];
  const selectedRate = watch(`affiliations.${rowIndex}.vatrate`);
  const onRateChange = (val: number) => {
    setValue(`affiliations.${rowIndex}.vatrate`, val);
  };

  return (
    <div className="col-span-2">
      <Listbox value={selectedRate} onChange={onRateChange} disabled={disabled}>
        {({ open }) => (
          <>
            {rowIndex === 0 && (
              <Listbox.Label className="block text-sm font-medium text-neutral-700">
                Odečet DPH
              </Listbox.Label>
            )}
            <div className="relative mt-1">
              <Listbox.Button
                className={classNames(
                  disabled ? "cursor-not-allowed" : "cursor-pointer",

                  "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 relative w-full py-2 pl-3 pr-10 text-left bg-white border  shadow-sm focus:outline-none focus:ring-1 sm:text-sm"
                )}
              >
                <span className="block truncate">{selectedRate} %</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon
                    className="w-5 h-5 text-neutral-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {rates.map((rate, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-sky-500" : "text-neutral-900",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={rate}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {rate} %
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-sky-500",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};
