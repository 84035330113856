import React, { Fragment, useState } from "react";
import { useApi } from "../../../App";
import { AccountInfo, ProjectInfo } from "../../../services/api";
import { QueryRefetch } from "../../../util/api";
import {
  ArchiveBoxIcon,
  XCircleIcon,
  FolderPlusIcon,
  PlusCircleIcon,
  BanknotesIcon,
  FolderIcon,
} from "@heroicons/react/24/solid";
import { Popover, Transition } from "@headlessui/react";
import { ToolbarLevel } from "../../../util/general";
import { useNavigate } from "react-router-dom";
import TabBar from "./TabBar";

const Toolbar = ({
  setDefaultProjectModalOpen,
  setTransferOpen,
  currentSubject,
  refetchAccount,
  setProjectToRemove,
  setRemoveOpen,
  setArchiveOpen,
  project,
  level,
  shownTab,
  setShownTab,
}: {
  setDefaultProjectModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTransferOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentSubject: number;
  refetchAccount: QueryRefetch<AccountInfo>;
  setProjectToRemove: React.Dispatch<React.SetStateAction<number | undefined>>;
  setRemoveOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setArchiveOpen: React.Dispatch<React.SetStateAction<boolean>>;
  project: ProjectInfo | undefined;
  level: ToolbarLevel;
  shownTab: number;
  setShownTab: (id: number) => void;
}) => {
  const api = useApi();
  const navigate = useNavigate();
  const [newSubject, setNewSubject] = useState("");
  const [newProject, setNewProject] = useState("");

  async function addSubject() {
    const result = await api.subjects.subjectCreateSingle({ name: newSubject });

    if (result.ok) {
      setNewSubject("");
      navigate(`/account/${result.data.Id}/0?tab=0`);
    }
  }

  async function addProject() {
    const result = await api.projects.createProject(currentSubject, {
      projectName: newProject,
    });

    if (result.ok) {
      setNewProject("");
      refetchAccount();
    }
  }

  return (
    <div className="w-full bg-white border-b border-sky-700 min-h-[64px]">
      <div className="flex min-h-[64px] justify-between h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <TabBar shownTab={shownTab} setShownTab={setShownTab} />

        <div className="flex items-center space-x-2">
          {level === ToolbarLevel.Account && (
            <>
              <Popover className="relative">
                {({ open, close }) => (
                  <>
                    <Popover.Button className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600">
                      <PlusCircleIcon className="w-5 h-5 mr-2 text-neutral-700" />

                      <span>Nový účet</span>
                    </Popover.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Popover.Panel className="absolute z-10 px-4 mt-3 transform translate-y-0 border opacity-100 border-neutral-200 sm:px-0">
                        <div className="relative p-2 bg-white ">
                          <input
                            type="text"
                            placeholder="Název nového účtu"
                            value={newSubject}
                            onChange={(e) => setNewSubject(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && newSubject.length) {
                                close();
                                addSubject();
                              }
                            }}
                            className="block px-3 py-2 border shadow-sm border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none sm:text-sm"
                          />
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Popover className="relative">
                {({ open, close }) => (
                  <>
                    <Popover.Button className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600">
                      <FolderPlusIcon className="w-5 h-5 mr-2 text-neutral-700" />

                      <span>Nová složka</span>
                    </Popover.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Popover.Panel className="absolute z-10 px-4 mt-3 transform translate-y-0 border opacity-100 border-neutral-200 sm:px-0">
                        <div className="relative p-2 bg-white ">
                          <input
                            type="text"
                            placeholder="Název nové složky"
                            value={newProject}
                            onChange={(e) => setNewProject(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && newProject.length) {
                                close();
                                addProject();
                              }
                            }}
                            className="block px-3 py-2 border shadow-sm border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none sm:text-sm"
                          />
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </>
          )}

          {level === ToolbarLevel.Project && !project?.IsArchived && (
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
              onClick={() => setArchiveOpen(true)}
            >
              <ArchiveBoxIcon className="w-5 h-5 mr-2 text-neutral-700" />
              Archivovat
            </button>
          )}

          {level === ToolbarLevel.Project &&
            project?.Id !== 0 &&
            !project?.IsArchived && (
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
                onClick={() => {
                  setProjectToRemove(project?.Id);
                  setRemoveOpen(true);
                }}
              >
                <XCircleIcon className="w-5 h-5 mr-2 text-neutral-700" />
                Odstranit
              </button>
            )}

          {(level === ToolbarLevel.Account ||
            (level === ToolbarLevel.Project && !project?.IsArchived)) && (
            <button
              onClick={() => setTransferOpen(true)}
              type="button"
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
            >
              <BanknotesIcon className="w-5 h-5 mr-2 text-neutral-700" />

              <span>Převést prostředky</span>
            </button>
          )}

          <button
            className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
            type="button"
            onClick={() => setDefaultProjectModalOpen(true)}
          >
            <FolderIcon className="w-5 h-5 text-neutral-700" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
