import React from "react";
import { ExternalBillingRow, ArticleType } from "../../../services/api";
import { AliasToCreate } from "../../../types/types";
import { MissingArticleRow } from "./MissingArticleRow";
import { useApi } from "../../../App";
import { useQuery } from "react-query";
import Loading from "../../../components/Loading";

export function MissingArticlesTable({
  source,
  missingRows,
  aliases,
  setAliases,
}: {
  source: string;
  missingRows: ExternalBillingRow[];
  aliases: AliasToCreate[];
  setAliases: (aliases: AliasToCreate[]) => void;
}) {
  const api = useApi();

  const {
    data: articles,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ["articles"],
    async () =>
      await api.articles.articlesGetAll({
        articleType: (source.startsWith("shop_")
          ? "merch"
          : "track") as ArticleType,
      }),
    {
      select: (x) => x.data.articles,
    }
  );

  if (isLoading) {
    return (
      <div className="flex justify-center my-12">
        <Loading />
      </div>
    );
  }

  if (!articles) {
    return (
      <div className="flex justify-center my-12">
        <div className="text-sm text-center text-red-600">
          Nepodařilo se načíst katalog artiklů (chyba:{" "}
          {(error as Error)?.message})
        </div>

        <div className="flex justify-center mt-4">
          <button
            type="button"
            className="inline-flex items-center justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => refetch()}
          >
            Zkusit znovu
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-8 pb-16 mt-8">
      <div className="flex flex-col">
        <div className="inline-block min-w-full py-2 align-middle px-0.5">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
            <div className="min-w-full divide-y divide-neutral-300">
              <div className="grid grid-cols-12 bg-neutral-50">
                <div className="col-span-6 py-3 pl-4 pr-3 text-xs font-medium tracking-wide text-left uppercase text-neutral-500 sm:pl-6">
                  Název artiklu
                </div>
                <div className="col-span-6 py-3 pr-3 text-xs font-medium tracking-wide text-left uppercase pl-14 text-neutral-500">
                  Mapování
                </div>
              </div>
              <div className="bg-white divide-y divide-neutral-200">
                {missingRows.map((row, index) => (
                  <MissingArticleRow
                    key={row.articleUniqName}
                    source={source}
                    rowToMatch={row}
                    index={index}
                    catalogue={articles}
                    aliasesToCreate={aliases}
                    setAliasesToCreate={setAliases}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
