import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../../App";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loading from "../../../components/Loading";
import { Currency, Location } from "../../../services/api";
import { Transition } from "@headlessui/react";
import {
  ArrowsRightLeftIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { LocationSelector, AmountInput, NoteInput } from "./Inputs";

import type { Identifiers, TransferData } from "../../../types/types";
import { classNames } from "../../../util/general";

function findLocation(
  { subject, project }: { subject: number; project: number },
  locations?: Location[]
) {
  if (locations === undefined || subject === 0) {
    return 0;
  }

  if (project === 0) {
    return locations.findIndex((location) => location.SubjectId === subject);
  }

  return locations.findIndex(
    (location) => location.Parent === subject && location.ProjectId === project
  );
}

export default function TransferModal({
  open,
  setOpen,
  identifiers,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  identifiers: Identifiers;
}) {
  const api = useApi();
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);

  const { data: locations, isLoading } = useQuery(
    ["locations"],
    async () => await api.locations.getLocations(),
    {
      select: (x) => x.data.locations,
    }
  );

  const validationSchema = yup.object().shape({
    source: yup.number().required().moreThan(0),
    target: yup.number().required().moreThan(0),
    sourceNote: yup.string().required(),
    targetNote: yup.string().required(),
    amount: yup
      .string()
      .required()
      .matches(/^([0-9] ?)(\d ?)*(,\d+)?$/),
    currency: yup.string().required(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      source: 0,
      target: 0,
      sourceNote: "",
      targetNote: "",
      amount: "0",
      currency: "CZK",
    },
  };

  const {
    setValue,
    clearErrors,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  async function onSubmit(data: { [x: string]: any }) {
    setSaving(true);

    const { source, target, amount, currency, sourceNote, targetNote } =
      data as TransferData;

    const submitResult = await api.transactions.addTransfer({
      Source: source,
      Target: target,
      Amount: parseFloat(amount.replace(",", ".")),
      Currency: currency as Currency,
      SourceNote: sourceNote,
      TargetNote: targetNote,
    });

    if (submitResult.ok) {
      setTimeout(() => setSuccess(true), 1500);
      setTimeout(() => {
        reset();
        setOpen(false);
        setSaving(false);
        setSuccess(false);
      }, 4000);
    }
  }

  useEffect(() => {
    setValue("source", findLocation(identifiers, locations));
  }, [locations, identifiers]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="relative z-10 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative overflow-hidden text-left transition-all transform bg-white shadow-xl sm:my-8 sm:max-w-xl sm:w-full">
                {isLoading || locations === undefined ? (
                  <div className="flex justify-center w-full h-full py-8">
                    <Loading />
                  </div>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                      <div className="relative sm:flex sm:items-start">
                        <div
                          className={classNames(
                            saving ? "visible" : "invisible",
                            "absolute w-full h-full flex justify-center items-center"
                          )}
                        >
                          {success ? (
                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-auto bg-green-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                              <CheckCircleIcon
                                className="w-5 h-5 text-green-600"
                                aria-hidden="true"
                              />
                            </div>
                          ) : (
                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10">
                              <Loading />
                            </div>
                          )}
                        </div>
                        <div
                          className={classNames(
                            saving && "invisible",
                            "flex items-center justify-center flex-shrink-0 w-10 h-10 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10"
                          )}
                        >
                          <ArrowsRightLeftIcon
                            className="w-5 h-5 text-sky-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div
                          className={classNames(
                            saving && "invisible",
                            "flex flex-col flex-1 ml-4 space-y-4"
                          )}
                        >
                          <dl className="flex items-start justify-between w-full">
                            <dt className="block py-2 text-sm font-medium text-neutral-700">
                              Zdroj
                            </dt>
                            <dd className="w-64">
                              <LocationSelector
                                valueKey="source"
                                locations={locations}
                                watch={watch}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                errors={errors}
                              />
                            </dd>
                          </dl>

                          <dl className="flex items-start justify-between w-full">
                            <dt className="block py-2 text-sm font-medium text-neutral-700">
                              Příjemce
                            </dt>
                            <dd className="w-64">
                              <LocationSelector
                                valueKey="target"
                                locations={locations}
                                watch={watch}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                errors={errors}
                              />
                            </dd>
                          </dl>

                          <div className="pt-4 border-b border-neutral-200"></div>

                          <dl className="flex items-start justify-between w-full pt-4">
                            <dt className="block py-2 text-sm font-medium text-neutral-700">
                              Částka
                            </dt>
                            <dd className="w-64">
                              <AmountInput
                                valueKey="amount"
                                watch={watch}
                                register={register}
                                setValue={setValue}
                                errors={errors}
                              />
                            </dd>
                          </dl>

                          <dl className="flex items-start justify-between w-full">
                            <dt className="block py-2 text-sm font-medium text-neutral-700">
                              Poznámka u zdroje
                            </dt>
                            <dd className="w-64">
                              <NoteInput
                                valueKey="sourceNote"
                                register={register}
                                errors={errors}
                              />
                            </dd>
                          </dl>

                          <dl className="flex items-start justify-between w-full">
                            <dt className="block py-2 text-sm font-medium text-neutral-700">
                              Poznámka pro příjemce
                            </dt>
                            <dd className="w-64">
                              <NoteInput
                                valueKey="targetNote"
                                register={register}
                                errors={errors}
                              />
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className="py-3 bg-neutral-50 px-6 h-[62px] flex flex-row-reverse">
                      <button
                        type="submit"
                        disabled={saving}
                        className={classNames(
                          saving && "invisible",
                          "inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent  shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                        )}
                      >
                        Převést
                      </button>
                      <button
                        type="button"
                        disabled={saving}
                        className={classNames(
                          saving && "invisible",
                          "inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-neutral-700 bg-white border border-neutral-300  shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        )}
                        onClick={() => setOpen(false)}
                      >
                        Zrušit
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
}
