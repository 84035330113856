import React, { useState, useEffect, Fragment } from "react";
import { POSCollectionWithGroups, SuffixGroup } from "../../../services/api";
import {
  PencilSquareIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "../../../util/general";
import { useApi } from "../../../App";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { FixedSizeList as List } from "react-window";

const CollectionsModalItem = ({
  collection,
  articleGroups,
  refetch,
}: {
  collection: POSCollectionWithGroups;
  articleGroups: SuffixGroup[];
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<POSCollectionWithGroups[], unknown>>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentGroups, setCurrentGroups] = useState(collection.Groups);
  const [selectedSuffixGroup, setSelectedSuffixGroup] =
    useState<SuffixGroup | null>(null);
  const api = useApi();

  useEffect(() => {
    setCurrentGroups(collection.Groups);
  }, [collection]);

  async function deleteCollection(collectionId: number) {
    await api.collections.deleteCollection(collectionId, { force: true });
    refetch();
  }

  async function confirmContents() {
    await api.collections.setCollectionArticles(
      collection.Id,
      currentGroups.flatMap((group) =>
        group.Articles.map((article) => article.Id)
      )
    );
    refetch();

    setIsOpen(!isOpen);
  }

  function handleRemoveFromCollection(groupIndex: number) {
    const updatedGroups = currentGroups.filter(
      (_, index) => index !== groupIndex
    );
    setCurrentGroups(updatedGroups);
  }

  function handleAddToCollection(suffixGroup: SuffixGroup) {
    const isUnique = !currentGroups.some((group) =>
      group.Articles.some((article) =>
        suffixGroup.Articles.some((newArticle) => newArticle.Id === article.Id)
      )
    );

    if (isUnique) {
      setCurrentGroups([...currentGroups, suffixGroup]);
    }
    setSelectedSuffixGroup(null);
  }

  const Row = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    const group = articleGroups[index];
    return (
      <Listbox.Option
        key={index}
        className={({ active }) =>
          classNames(
            active ? "text-white bg-sky-600" : "text-neutral-900",
            "cursor-default select-none relative py-2 pl-3 pr-9"
          )
        }
        value={group}
        style={style}
      >
        {({ active }) => (
          <span className={classNames("block truncate")}>{group.Name}</span>
        )}
      </Listbox.Option>
    );
  };

  return (
    <div className="bg-neutral-50">
      <div
        key={collection.Id}
        className="flex items-center justify-between px-4 py-2"
      >
        <div className="flex text-sm text-neutral-500">
          <div className="font-medium text-neutral-900">{collection.Name}</div>
          <div className="ml-1">
            ({currentGroups.length}
            {" položek"})
          </div>
        </div>

        <div className="flex ml-auto space-x-4">
          {!isOpen ? (
            <>
              <button
                type="button"
                title="Upravit"
                className="inline-flex items-center p-1 mt-auto ml-4 bg-transparent border border-transparent rounded-full cursor-pointer hover:bg-neutral-200 text-neutral-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                onClick={() => setIsOpen(!isOpen)}
              >
                <PencilSquareIcon className="w-5 h-5" aria-hidden="true" />
              </button>

              <button
                disabled={currentGroups.length > 0}
                type="button"
                title={
                  currentGroups.length > 0
                    ? "Nelze odstranit, obsahuje položky"
                    : "Odstranit"
                }
                className={classNames(
                  currentGroups.length > 0
                    ? "cursor-not-allowed"
                    : "cursor-pointer hover:bg-neutral-200",
                  "inline-flex items-center p-1 mt-auto ml-4 bg-transparent border border-transparent rounded-full text-neutral-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                )}
                onClick={() => deleteCollection(collection.Id)}
              >
                <XCircleIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </>
          ) : (
            <button
              title="Potvrdit"
              type="button"
              className="inline-flex items-center p-1 mt-auto ml-4 bg-transparent border border-transparent rounded-full cursor-pointer hover:bg-neutral-200 text-neutral-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
              onClick={confirmContents}
            >
              <CheckCircleIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="px-4 pt-2 pb-4 text-sm text-neutral-700">
          <ul className="space-y-2 list-disc list-inside">
            {currentGroups.map((group, index) => (
              <li key={index} className="flex items-center justify-between">
                <span>
                  {group.Name}
                  {group.Articles.length > 1 && (
                    <span className="opacity-50">
                      {` (${group.Articles.map((article) =>
                        article.ArticleName.split(" ").pop()
                      ).join(", ")})`}
                    </span>
                  )}
                </span>
                <button
                  className="text-black underline hover:no-underline focus:outline-none"
                  onClick={() => handleRemoveFromCollection(index)}
                >
                  Odstranit
                </button>
              </li>
            ))}
          </ul>
          <div className="mt-4">
            <Listbox
              value={selectedSuffixGroup}
              onChange={handleAddToCollection}
            >
              {({ open }) => (
                <>
                  <div className="relative mt-1">
                    <Listbox.Button className="relative py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default w-96 border-neutral-300 focus:outline-none focus:ring-1 focus:ring-neutral-900 focus:border-neutral-900 sm:text-sm">
                      <span className="block truncate">
                        {selectedSuffixGroup
                          ? selectedSuffixGroup.Name
                          : "Přidat..."}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronDownIcon
                          className="w-5 h-5 text-neutral-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 py-1 mt-1 overflow-auto text-base bg-white shadow-lg w-96 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <List
                          height={256}
                          itemCount={articleGroups.length}
                          itemSize={35}
                          width={384}
                        >
                          {Row}
                        </List>
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>
      )}
    </div>
  );
};
export default CollectionsModalItem;
