import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/solid";
import * as yup from "yup";
import {
  DistributionFragmentType,
  DistributionFragmentTypeTSOA,
} from "../../../services/api";
import Loading from "../../../components/Loading";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useApi } from "../../../App";
import { useQuery } from "react-query";
import FragmentArray from "./DistributionTemplateFragmentArray";
import { distributionRoles } from "../../../util/roles";

export default function DistributionTemplatesModal({
  open,
  setOpen,
  mode,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mode: "track" | "merch";
}) {
  const cancelButtonRef = useRef(null);

  const api = useApi();
  const [saving, setSaving] = useState(false);
  const [sumError, setSumError] = useState(false);

  const { data: subjects, isLoading: isLoadingSubjects } = useQuery(
    ["subjects"],
    async () => await api.subjects.getSubjects(),
    {
      select: (x) => x.data.subjects,
    }
  );

  const {
    data: templates,
    isLoading: isLoadingTemplates,
    refetch,
  } = useQuery(
    ["templates"],
    async () => await api.distributionTemplates.distributionTemplatesGet(),
    {
      select: (x) =>
        x.data.templates.filter((template) => {
          if (mode === "track") {
            return !template.Fragments.some(
              (f) => f.Type === DistributionFragmentType.MERCH
            );
          } else {
            return template.Fragments.some(
              (f) => f.Type === DistributionFragmentType.MERCH
            );
          }
        }),
      enabled: open,
    }
  );

  const validationSchema = yup.object().shape({
    fragments: yup
      .array()
      .of(
        yup.object().shape({
          subjectId: yup.number(),
          fragmentType:
            mode === "merch" ? yup.string().required() : yup.string(),
          role: yup.string(),
          percentage: yup
            .string()
            .required()
            .matches(/^[1-9][0-9]?(\.[0-9][1-9]?)?$|^100$/),
        })
      )
      .min(1),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    setValue,
    clearErrors,
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    reset();
  }, [open]);

  const { fields, append, remove } = useFieldArray({
    name: "fragments",
    control,
  });

  async function onSubmit({ fragments }: { [x: string]: any }) {
    setSaving(true);
    setSumError(false);

    let sumMatches = true;

    if (mode === "merch") {
      const [expenseFragmentSum, incomeFragmentSum] = fragments.reduce(
        (
          [expenseSum, incomeSum]: [number, number],
          {
            fragmentType,
            percentage,
          }: { fragmentType: string; percentage: string }
        ) =>
          fragmentType === "Dělení nákladů"
            ? [(expenseSum = expenseSum + parseInt(percentage)), incomeSum]
            : [expenseSum, (incomeSum = incomeSum + parseInt(percentage))],
        [0, 0]
      );

      if (expenseFragmentSum !== 100 || incomeFragmentSum !== 100) {
        sumMatches = false;
      }
    } else {
      const fragmentSum = fragments.reduce(
        (sum: number, { percentage }: { percentage: string }) =>
          (sum = sum + parseInt(percentage)),
        0
      );

      if (fragmentSum !== 100) {
        sumMatches = false;
      }
    }

    if (!sumMatches) {
      setSaving(false);
      setSumError(true);

      return;
    }

    const fragmentCreationData = fragments.map(
      ({
        fragmentType,
        percentage,
        role,
        subjectId,
      }: {
        fragmentType: string;
        percentage: string;
        role: string;
        subjectId: number;
      }) => ({
        type:
          mode === "track"
            ? DistributionFragmentTypeTSOA.INCOME
            : fragmentType === "Dělení nákladů"
            ? DistributionFragmentTypeTSOA.MERCH
            : DistributionFragmentTypeTSOA.INCOME,
        percentage,
        role,
        subjectId,
      })
    );

    const templateResponse =
      await api.distributionTemplates.distributionTemplateCreate({
        fragments: fragmentCreationData,
        templateName: "",
      });

    if (templateResponse.ok) {
      refetch();
      reset();
    } else {
      console.error(templateResponse);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 text-left transition-all transform bg-white shadow-xl overflow-shown sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                    <AdjustmentsVerticalIcon
                      className="w-6 h-6 text-sky-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex-1 mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div className="flex items-center h-10">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-neutral-900"
                      >
                        Správa šablon rozdělení
                      </Dialog.Title>
                    </div>
                    <div className="flex flex-col mt-4">
                      {isLoadingSubjects ||
                      isLoadingTemplates ||
                      subjects === undefined ? (
                        <div className="flex justify-center py-16">
                          <Loading />
                        </div>
                      ) : (
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="flex flex-col flex-1 space-y-2"
                        >
                          <div className="flex flex-col text-sm">
                            {templates?.map((template) => (
                              <div
                                key={template.Id}
                                className="relative pt-2 pb-2 mt-2 border-b first:border-t border-neutral-200"
                              >
                                <div
                                  className="absolute z-10 flex items-start justify-center w-full h-full transition-all ease-in-out bg-transparent cursor-pointer group hover:backdrop-blur-sm"
                                  onClick={async () => {
                                    await api.distributionTemplates.templateDeleteSingle(
                                      template.Id
                                    );

                                    refetch();
                                  }}
                                >
                                  <span className="hidden text-sm font-medium group-hover:block text-neutral-900">
                                    Smazat tuto šablonu?
                                  </span>
                                </div>
                                {/* <div className="absolute "> */}
                                {template.Fragments.map(
                                  ({ Id, Percentage, Subject, Role, Type }) => (
                                    <div
                                      key={Id}
                                      className="flex justify-between px-1 py-0.5 focus:outline-none"
                                    >
                                      <div className="flex items-center space-x-1">
                                        <span className="font-medium">
                                          {Subject?.Name || "–"}
                                        </span>
                                        <span className="font-normal text-neutral-400">
                                          {Role}
                                        </span>
                                      </div>
                                      <span className="font-medium">
                                        {Type === DistributionFragmentType.MERCH
                                          ? "náklad "
                                          : "příjem "}
                                        {Percentage}
                                        {" %"}
                                      </span>
                                    </div>
                                  )
                                )}
                                {/* </div> */}
                              </div>
                            ))}
                          </div>

                          <div className="py-4"></div>

                          <h2 className="mt-8 text-lg font-medium text-neutral-900">
                            Přidat novou šablonu
                          </h2>

                          <FragmentArray
                            sumError={sumError}
                            subjects={subjects}
                            roles={distributionRoles}
                            control={control}
                            register={register}
                            watch={watch}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            errors={errors}
                            fieldArrayConfig={{ fields, append, remove }}
                            includeType={mode === "merch"}
                          />
                        </form>
                      )}

                      <div className="mt-4 space-x-2 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          Zavřít
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
