import React, { Fragment, useState } from "react";
import { Popover, Transition, Listbox } from "@headlessui/react";
import {
  ChevronDownIcon,
  XCircleIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "../util/general";
import getYear from "date-fns/getYear";

export function PeriodSelector({
  label,
  periods,
  setPeriods,
  limitToOne,
}: {
  label: string;
  periods: string[];
  setPeriods: (periods: string[]) => void;
  limitToOne: boolean;
}) {
  const currentYear = getYear(new Date());

  const [activeMonths, setActiveMonths] = useState<number[]>([]);
  const [year, setYear] = useState<number>(currentYear);
  const previousYears: number[] = [];

  for (let i = currentYear; i >= 2021; i--) previousYears.push(i);

  function addPeriod() {
    if (limitToOne && periods.length === 1) {
      return;
    }

    if (limitToOne && periods.length === 3) {
      return;
    }

    const periodsCopy = periods;

    if (activeMonths.length > 0) {
      periodsCopy.push(
        ...activeMonths.map((m) => `${m.toString().padStart(2, "0")}/${year}`)
      );
    }

    setActiveMonths([]);
    setPeriods([
      ...new Set(
        periodsCopy.sort(function (a, b) {
          const [aMonth, aYear] = a.split("/").map(parseInt);
          const [bMonth, bYear] = b.split("/").map(parseInt);

          if (aMonth !== bMonth) {
            return aMonth - bMonth;
          } else {
            return aYear - bYear;
          }
        })
      ),
    ]);
  }

  function removePeriod(period: string) {
    setPeriods(periods.filter((p) => p !== period));
  }

  function selectMonth(month: number) {
    if (activeMonths.includes(month)) {
      setActiveMonths(activeMonths.filter((m) => m !== month));
    } else {
      setActiveMonths([...activeMonths, month]);
    }
  }

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <div className="block text-sm font-medium text-neutral-700">
            {label}
          </div>

          <div className="mt-1">
            <Popover.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none focus:ring-1 sm:text-sm">
              {`${periods.length} měsíc${
                periods.length === 1
                  ? ""
                  : periods.length > 1 && periods.length < 5
                  ? "e"
                  : "ů"
              }`}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className="w-5 h-5 text-neutral-400"
                  aria-hidden="true"
                />
              </span>
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel className="absolute z-10 flex flex-col w-[356px] p-4 mt-2 bg-white border opacity-100 border-neutral-200 sm:px-0">
                {({ close: periodSelectorClose }) => (
                  <div className="flex flex-col w-full p-4 pt-2">
                    <div className="flex flex-wrap gap-x-2">
                      {periods.map((period) => (
                        <div
                          key={period}
                          className="inline-flex items-center mt-2 pr-2 py-0.5 rounded-full text-xs font-medium bg-sky-100 text-sky-700 hover:bg-sky-200"
                        >
                          <span className="pl-3 mr-1">{period}</span>
                          <button
                            className="hover:text-sky-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                            title="Odstranit dokument"
                            onClick={() => removePeriod(period)}
                          >
                            <XCircleIcon className="w-4 h-4" />
                          </button>
                        </div>
                      ))}
                    </div>

                    <hr className="mt-3 mb-6 border border-neutral-200" />

                    <div className="grid grid-cols-2 space-x-4">
                      <div>
                        <div className="block mb-2 text-sm font-medium text-neutral-700">
                          Měsíce
                        </div>

                        <div className="grid grid-cols-3 gap-1 isolate">
                          {[...Array(12).keys()].map((i) => (
                            <button
                              key={i}
                              type="button"
                              className={classNames(
                                activeMonths.includes(i + 1) &&
                                  "border-sky-600 ring-sky-600 ring-1 outline-none z-10",
                                "flex justify-center py-2 text-sm font-medium bg-white border border-neutral-300 text-neutral-700 hover:bg-neutral-50"
                              )}
                              onClick={() => selectMonth(i + 1)}
                            >
                              <span>{(i + 1).toString().padStart(2, "0")}</span>
                            </button>
                          ))}
                        </div>
                      </div>

                      <div className="flex flex-col justify-between">
                        <div>
                          <Listbox value={year} onChange={setYear}>
                            {({ open }) => (
                              <>
                                {label !== undefined ? (
                                  <Listbox.Label className="block text-sm font-medium text-neutral-700">
                                    Rok
                                  </Listbox.Label>
                                ) : undefined}
                                <div className="relative mt-2">
                                  <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none focus:ring-1 sm:text-sm">
                                    <span className="block truncate">
                                      {year}
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <ChevronDownIcon
                                        className="w-5 h-5 text-neutral-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {previousYears.map((option, index) => (
                                        <Listbox.Option
                                          key={index}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-sky-500"
                                                : "text-neutral-900",
                                              "cursor-default select-none relative py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={option}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {option}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-sky-500",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="w-5 h-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-center text-white border border-transparent shadow-sm bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                          onClick={() => {
                            addPeriod();
                            periodSelectorClose();
                          }}
                        >
                          Přidat období
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
}
