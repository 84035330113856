import React from "react";
import { InsufficientStocksRow } from "./InsufficientStocksRow";
import { ArticleInsufficientWarehouseWithStatus } from "../../../util/general";

export function InsufficientStocksTable({
  insufficientStocks,
  setInsufficientStocks,
}: {
  insufficientStocks: ArticleInsufficientWarehouseWithStatus[];
  setInsufficientStocks: React.Dispatch<
    React.SetStateAction<ArticleInsufficientWarehouseWithStatus[]>
  >;
}) {
  function updateRowState(id: number) {
    const newInsufficientStocks = insufficientStocks.map((x) => {
      if (x.Id === id) {
        return {
          ...x,
          status: "resolved" as "resolved",
        };
      }
      return x;
    });

    setInsufficientStocks(newInsufficientStocks);
  }

  return (
    <div className="pt-8 pb-16 mt-8">
      <div className="flex flex-col">
        <div className="inline-block min-w-full py-2 align-middle px-0.5">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
            <div className="min-w-full divide-y divide-neutral-300">
              <div className="grid grid-cols-12 bg-neutral-50">
                <div className="col-span-5 px-4 py-3 text-xs font-medium tracking-wide text-left uppercase text-neutral-500 sm:pl-6">
                  Název artiklu
                </div>
                <div className="col-span-1 px-4 py-3 text-xs font-medium tracking-wide text-right uppercase border-r border-neutral-300 text-neutral-500 ">
                  Chybí
                </div>
                <div className="flex col-span-6 px-4 py-3 text-xs font-medium tracking-wide text-left uppercase text-neutral-500">
                  <div className="flex-1">
                    <span>Naskladnit kusů</span>
                  </div>
                  <div className="flex-1">
                    <span>Cena za kus bez DPH</span>
                  </div>
                  <div className="flex-1 max-w-[132px]"></div>
                </div>
              </div>
              <div className="bg-white divide-y divide-neutral-200">
                {insufficientStocks.map((row) => (
                  <InsufficientStocksRow
                    key={row.Id}
                    details={row}
                    updateWarehouseRowState={updateRowState}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
