import React, { useEffect, useState } from "react";
import {
  ExternalBillingRow,
  Article,
  UniqueNameType,
} from "../../../services/api";
import { AliasToCreate } from "../../../types/types";
import {
  ArrowRightIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import MappingSelector from "./MappingSelector";
import { MissingArticleRowUpsertForm } from "./MissingArticleRowUpsertForm";

export function MissingArticleRow({
  source,
  rowToMatch,
  index,
  catalogue,
  aliasesToCreate,
  setAliasesToCreate,
}: {
  source: string;
  rowToMatch: ExternalBillingRow;
  index: number;
  catalogue: Article[];
  aliasesToCreate: AliasToCreate[];
  setAliasesToCreate: (aliases: AliasToCreate[]) => void;
}) {
  const [alias, setAlias] = useState<Article | undefined>();
  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);

  const catalogueType = source.startsWith("shop_") ? "merch" : "track";
  const filterCatalogue = false;

  useEffect(() => {
    setAliasesToCreate([
      ...aliasesToCreate.filter((alias) => alias.Index != index),
      ...(alias === undefined
        ? []
        : [
            {
              Index: index,
              ArticleType: alias.ArticleType,
              ArticleId: alias.Id,
              OwnerName: alias.OwnerName,
              ArticleName: alias.ArticleName,
              CodeName: rowToMatch.articleUniqName,
              CodeNameType: source.startsWith("shop_")
                ? UniqueNameType.EAN
                : source.startsWith("awal_")
                ? UniqueNameType.ISRC
                : UniqueNameType.GRid,
            },
          ]),
    ]);
  }, [alias]);

  return (
    <div className="grid grid-cols-12">
      <div className="flex justify-between col-span-6 py-3 pl-4 pr-3 text-sm font-medium sm:pl-6">
        <div className="pr-3">
          <div className="text-neutral-900">{rowToMatch.articleName}</div>
          <div className="text-neutral-500">{rowToMatch.articleOwner}</div>
        </div>
      </div>
      {editDisabled && alias !== undefined ? (
        <div className="flex items-center justify-end col-span-6 px-3 py-3 text-sm text-neutral-500 whitespace-nowrap">
          <ArrowRightIcon className="w-5 h-5 mr-6 text-neutral-400" />

          <div className="flex flex-col flex-1 text-sm font-medium flex-0">
            <div className="text-neutral-900">{alias.ArticleName}</div>
            <div className="text-neutral-500">{alias.OwnerName}</div>
          </div>

          <button
            type="button"
            className="inline-flex items-center p-1 ml-3 bg-transparent border border-transparent rounded-full text-neutral-500 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            onClick={() => {
              setEditDisabled(false);
              setAlias(undefined);
            }}
          >
            <XCircleIcon className="w-5 h-5" aria-hidden="true" />
          </button>
        </div>
      ) : (
        <div className="flex flex-col col-span-6 px-3 py-3">
          {!createFormOpen ? (
            <div className="flex items-center justify-end col-span-6 text-sm text-neutral-500 whitespace-nowrap">
              <ArrowRightIcon className="w-5 h-5 mr-6 text-neutral-400" />
              <MappingSelector
                catalogue={
                  filterCatalogue
                    ? catalogue.filter(
                        (a) => a.OwnerName === rowToMatch.articleOwner
                      )
                    : catalogue
                }
                alias={alias}
                setAlias={setAlias}
                setEditDisabled={setEditDisabled}
              />
              <button
                type="button"
                className={
                  "inline-flex items-center p-1 ml-3 bg-transparent border border-transparent rounded-full text-neutral-500 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                }
                onClick={() => setCreateFormOpen(true)}
              >
                <PlusCircleIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>
          ) : (
            <div className="pt-1">
              <MissingArticleRowUpsertForm
                catalogueType={catalogueType}
                setOpen={setCreateFormOpen}
                setAlias={setAlias}
                setEditDisabled={setEditDisabled}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
