import React, { useContext } from "react";
import { SettingsContext } from "../../../App";
import { AccountStats } from "../../../services/api";
import { formatAmount } from "../../../util/general";

const statBoxesAccount = [
  { label: "Zůstatek na účtu", source: "Balance" },
  { label: "Suma příjmů", source: "Incomes" },
  { label: "Suma výdajů", source: "Expenses" },
];

const AccountStatsBar = ({ accountStats }: { accountStats?: AccountStats }) => {
  const { consolidateCurrencies, yearFilter } = useContext(SettingsContext);

  if (!accountStats) {
    return <></>;
  }

  return (
    <div className="w-full overflow-hidden bg-white shadow ">
      <div className="grid w-full grid-cols-3">
        {statBoxesAccount.map((box, index) => (
          <div
            className="p-6 text-right border-r border-neutral-200 last:border-0"
            key={index}
          >
            <dt className="mb-2 font-medium truncate text-neutral-900">
              {box.label} {yearFilter ? <>({yearFilter})</> : ""}
            </dt>

            {consolidateCurrencies ? (
              <dd className="text-xl text-neutral-500">
                {"cca "}
                <span className="font-medium text-sky-600">
                  {formatAmount(
                    parseFloat(
                      accountStats[box.source as keyof AccountStats].merged
                        .value || "0"
                    )
                  )}
                </span>
                {" Kč"}
              </dd>
            ) : (
              <>
                <dd className="text-xl text-neutral-500">
                  <span className="font-medium text-sky-600">
                    {formatAmount(
                      parseFloat(
                        accountStats[
                          box.source as keyof AccountStats
                        ].CZK.toFixed(2) || "0"
                      )
                    )}
                  </span>
                  {" Kč"}
                </dd>
                <dd className="text-xl text-neutral-500">
                  <span className="font-medium text-sky-600">
                    {formatAmount(
                      parseFloat(
                        accountStats[
                          box.source as keyof AccountStats
                        ].EUR.toFixed(2) || "0"
                      )
                    )}
                  </span>
                  {" €\u00A0\u00A0"}
                </dd>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountStatsBar;
