import React, { useContext } from "react";
import { SettingsContext } from "../../../App";
import { ProjectStats } from "../../../services/api";
import { classNames, formatAmount } from "../../../util/general";

const statBoxesProject = [
  { label: "Stav složky", source: "Balance" },
  { label: "Suma příjmů", source: "Incomes" },
  { label: "Suma výdajů", source: "Expenses" },
];

const ProjectStatsBar = ({
  projectStats,
  projectItemsFilter,
  setProjectItemsFilter,
}: {
  projectStats?: ProjectStats;
  projectItemsFilter: "Incomes" | "Expenses" | undefined;
  setProjectItemsFilter: React.Dispatch<
    React.SetStateAction<"Incomes" | "Expenses" | undefined>
  >;
}) => {
  const { consolidateCurrencies, yearFilter } = useContext(SettingsContext);

  const toggleFilter = (key: "Balance" | "Incomes" | "Expenses") => {
    if (projectItemsFilter === key || key === "Balance") {
      setProjectItemsFilter(undefined);
    } else {
      setProjectItemsFilter(key);
    }
  };

  if (!projectStats) {
    return <></>;
  }

  return (
    <div className="w-full overflow-hidden bg-white shadow ">
      <div className="grid w-full grid-cols-3">
        {statBoxesProject.map((box, index) => (
          <div
            className="p-6 text-right transition-colors border-r cursor-pointer border-neutral-200 last:border-0 group hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
            key={index}
            onClick={() =>
              toggleFilter(box.source as "Balance" | "Incomes" | "Expenses")
            }
          >
            <dt
              className={classNames(
                box.source !== projectItemsFilter &&
                  projectItemsFilter !== undefined &&
                  "opacity-20 group-hover:opacity-90",
                "mb-2 font-medium truncate text-neutral-900 transition-opacity"
              )}
            >
              {box.label} {yearFilter ? <>({yearFilter})</> : ""}
            </dt>

            {consolidateCurrencies ? (
              <dd
                className={classNames(
                  box.source !== projectItemsFilter &&
                    projectItemsFilter !== undefined &&
                    "opacity-20 group-hover:opacity-90",
                  "text-xl text-neutral-500 transition-opacity"
                )}
              >
                {"cca "}
                <span className="font-medium text-sky-600">
                  {formatAmount(
                    parseFloat(
                      projectStats[box.source as keyof ProjectStats]?.merged
                        .value || "0"
                    ),
                    true
                  )}
                </span>
                {" Kč"}
              </dd>
            ) : (
              <>
                <dd
                  className={classNames(
                    box.source !== projectItemsFilter &&
                      projectItemsFilter !== undefined &&
                      "opacity-20 group-hover:opacity-90",
                    "text-xl text-neutral-500 transition-opacity"
                  )}
                >
                  <span className="font-medium text-sky-600">
                    {formatAmount(
                      parseFloat(
                        projectStats[
                          box.source as keyof ProjectStats
                        ]?.CZK.toFixed(2) || "0"
                      ),
                      true
                    )}
                  </span>
                  {" Kč"}
                </dd>
                <dd
                  className={classNames(
                    box.source !== projectItemsFilter &&
                      projectItemsFilter !== undefined &&
                      "opacity-20 group-hover:opacity-90",
                    "text-xl text-neutral-500 transition-opacity"
                  )}
                >
                  <span className="font-medium text-sky-600">
                    {formatAmount(
                      parseFloat(
                        projectStats[
                          box.source as keyof ProjectStats
                        ]?.EUR.toFixed(2) || "0"
                      ),
                      true
                    )}
                  </span>
                  {" €\u00A0\u00A0"}
                </dd>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectStatsBar;
