/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum Currency {
  EUR = "EUR",
  CZK = "CZK",
}

export type Decimal = string;

export interface CurrencyRevenue {
  currency: Currency;
  value: Decimal;
}

export interface CurrencyPair {
  /** @format double */
  CZK: number;
  /** @format double */
  EUR: number;
  merged: CurrencyRevenue;
}

export interface AccountStats {
  Balance: CurrencyPair;
  Incomes: CurrencyPair;
  Expenses: CurrencyPair;
}

export interface ProjectSummary {
  /** @format double */
  Id: number;
  Name: string;
  Archived: boolean;
  Balance: CurrencyPair;
}

export interface AccountInfo {
  /** @format double */
  Id: number;
  Name: string;
  /** @format double */
  IncomesProjectId: number | null;
  /** @format double */
  ExpensesProjectId: number | null;
  Stats: AccountStats;
  Projects: ProjectSummary[];
}

/** Model Subject */
export interface Subject {
  /** @format double */
  ExpensesProjectId: number | null;
  /** @format double */
  IncomesProjectId: number | null;
  SlackId: string | null;
  Email: string | null;
  Name: string;
  /** @format double */
  Id: number;
}

/** Model Artist */
export interface Artist {
  /** @format double */
  SubjectId: number;
  DistroDigi: boolean;
  DistroPhys: boolean;
  MerchRights: boolean;
  RepreAd: boolean;
  RepreBooking: boolean;
  Name: string;
  /** @format double */
  Id: number;
}

export type SubjectWithArtist = Subject & {
  Artist: Artist | null;
};

export interface ProjectStats {
  Balance: CurrencyPair;
  Incomes?: CurrencyPair;
  Expenses?: CurrencyPair;
}

export enum AffiliationType {
  CLOSING_PROJECT = "CLOSING_PROJECT",
  INVERSE_TO_PAIR = "INVERSE_TO_PAIR",
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickAffiliationWithVATExcludeKeys {
  Amount: null;
  /** @format double */
  Id: number;
  /** @format double */
  TransactionId: number | null;
  /** @format double */
  SubjectId: number;
  /** @format double */
  ProjectId: number | null;
  Title: string;
  /** @format double */
  VATDeduction: number;
  IsVirtual: boolean;
  Currency: string;
  /** @format date-time */
  HappenedAt: string;
  Type: AffiliationType | null;
  AmountWithVAT: Decimal;
  AmountWithoutVAT: Decimal;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitAffiliationWithVAT = PickAffiliationWithVATExcludeKeys;

export type AffiliationCZK = OmitAffiliationWithVAT & {
  $Merged: {
    Currency: Currency;
    AmountWithoutVAT: Decimal;
    AmountWithVAT: Decimal;
  };
};

export interface ProjectInfo {
  /** @format double */
  Id: number;
  Name: string;
  Owner: SubjectWithArtist;
  Stats: ProjectStats;
  Items: AffiliationCZK[];
  IsArchived: boolean;
}

export type PrismaDecimal = string;

/** Model Affiliation */
export interface Affiliation {
  Type: AffiliationType | null;
  /** @format date-time */
  HappenedAt: string;
  Currency: string;
  IsVirtual: boolean;
  /** @format double */
  VATDeduction: number;
  Title: string;
  Amount: PrismaDecimal;
  /** @format double */
  ProjectId: number | null;
  /** @format double */
  SubjectId: number;
  /** @format double */
  TransactionId: number | null;
  /** @format double */
  Id: number;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickArticleAliasExcludeKeysOrCodeNameType {
  /** @format double */
  Id: number;
  CodeName: string;
  OwnerName: string;
  ArticleName: string;
  ArticleType: string | null;
  /** @format double */
  ArticleId: number;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitArticleAliasOrCodeNameType = PickArticleAliasExcludeKeysOrCodeNameType;

export enum UniqueNameType {
  EAN = "EAN",
  UPC = "UPC",
  GRid = "GRid",
  ISRC = "ISRC",
}

export type ArticleAliasTSOA = OmitArticleAliasOrCodeNameType & {
  CodeNameType: UniqueNameType;
};

export interface ArticleAliasesResponse {
  alias: ArticleAliasTSOA;
}

export enum ArticleOwnership {
  OWNED = "OWNED",
  LICENSED = "LICENSED",
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickArticleExcludeKeysIdOrArticleType {
  Currency: string | null;
  OwnerName: string;
  ArticleName: string;
  ImageUrl: string | null;
  Ownership: ArticleOwnership | null;
  /** @format date-time */
  ReleaseDate: string | null;
  Price: PrismaDecimal | null;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitArticleIdOrArticleType = PickArticleExcludeKeysIdOrArticleType;

export enum ArticleType {
  Track = "track",
  Merch = "merch",
}

export type ArticleUpdate = OmitArticleIdOrArticleType & {
  ArticleType: ArticleType;
};

export interface ArticleAliasesBulkResponse {
  aliases: ArticleAliasTSOA[];
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickArticleAliasTSOAArticleNameOrOwnerNameOrArticleIdOrCodeNameOrCodeNameTypeOrArticleType {
  CodeNameType: UniqueNameType;
  CodeName: string;
  OwnerName: string;
  ArticleName: string;
  ArticleType: string | null;
  /** @format double */
  ArticleId: number;
}

export type ArticleAliasCreation =
  PickArticleAliasTSOAArticleNameOrOwnerNameOrArticleIdOrCodeNameOrCodeNameTypeOrArticleType;

/** Model ArticleGroup */
export interface ArticleGroup {
  /** @format date-time */
  ReleaseDate: string;
  CoverLink: string | null;
  GroupOwner: string;
  GroupName: string;
  Type: string;
  CodeNameType: UniqueNameType;
  CodeName: string;
  /** @format double */
  Id: number;
}

/** Model ArticleGroupMembership */
export interface ArticleGroupMembership {
  /** @format double */
  ArticleOrder: number;
  /** @format double */
  GroupId: number;
  /** @format double */
  ArticleId: number;
}

/** Model Article */
export interface Article {
  Currency: string | null;
  Price: PrismaDecimal | null;
  /** @format date-time */
  ReleaseDate: string | null;
  Ownership: ArticleOwnership | null;
  ImageUrl: string | null;
  ArticleType: ArticleType;
  ArticleName: string;
  OwnerName: string;
  /** @format double */
  Id: number;
}

export type GroupResponse = ArticleGroup & {
  Articles: (ArticleGroupMembership & {
    /** Model Article */
    Article: Article;
  })[];
};

/** From T, pick a set of properties whose keys are in the union K */
export interface PickArticleId {
  /** @format double */
  Id: number;
}

export type ArticleAssign = PickArticleId & {
  /** @format double */
  $Order?: number;
};

export interface GroupCreation {
  name: string;
  ownerName: string;
  type: string;
  /** @format date-time */
  releaseDate: string;
  codeName: string;
  codeNameType: UniqueNameType;
  coverLink?: string;
  articles?: ArticleAssign[];
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickWarehouseExcludeKeysDirection {
  /** @format double */
  Id: number;
  Currency: string;
  /** @format double */
  ArticleId: number;
  /** @format double */
  Stock: number;
  BuyingPrice: PrismaDecimal;
  /** @format date-time */
  BuyingDate: string;
  /** @format double */
  ParentId: number | null;
  /** @format double */
  AffiliationId: number | null;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitWarehouseDirection = PickWarehouseExcludeKeysDirection;

export type WarehouseTSOA = OmitWarehouseDirection & {
  Direction: "IN" | "OUT";
};

export enum DistributionFragmentTypeTSOA {
  MERCH = "MERCH",
  INCOME = "INCOME",
}

export interface DistributionFragmentTSOA {
  /** @format double */
  Id: number;
  /** @format double */
  SubjectId: number;
  /** @format double */
  ArticleId: number;
  Role: string;
  Percentage: PrismaDecimal;
  Type: DistributionFragmentTypeTSOA;
}

export type ArticleWithAlias = Article & {
  $CanBeDeleted: boolean;
} & {
  Groups: {
    /** Model ArticleGroup */
    Group: ArticleGroup;
    /** @format double */
    ArticleOrder: number;
  }[];
} & {
  Warehouse: WarehouseTSOA[];
  ArticleAlias: ArticleAliasTSOA[];
} & {
  DistributionFragments: (DistributionFragmentTSOA & {
    Subject: SubjectWithArtist;
  })[];
};

export interface SuffixGroup {
  Name: string;
  Articles: Article[];
}

export interface ArticlesResponse {
  articles: ArticleWithAlias[];
  groups: ArticleGroup[];
  suffixGroups: SuffixGroup[];
}

export interface ArticleResponse {
  article: ArticleWithAlias | null;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickArticleArticleNameOrOwnerNameOrOwnershipOrReleaseDate {
  OwnerName: string;
  ArticleName: string;
  Ownership: ArticleOwnership | null;
  /** @format date-time */
  ReleaseDate: string | null;
}

export type ArticleCreation = PickArticleArticleNameOrOwnerNameOrOwnershipOrReleaseDate & {
  ArticleType: ArticleType;
};

export interface ArticlesBulkResponse {
  articles: {
    existing: ArticleWithAlias[];
    new: ArticleWithAlias[];
  };
  invalid: {
    reason: string;
    row: string;
  }[];
}

/** Model POSCollection */
export interface POSCollection {
  Name: string;
  /** @format double */
  Id: number;
}

export interface POSGroup {
  Name: string;
  Articles: Article[];
}

export type POSCollectionWithGroups = POSCollection & {
  Groups: POSGroup[];
};

export interface DistributionFragmentResponse {
  fragments: DistributionFragmentTSOA[];
  complete: boolean;
}

/** Model DistributionTemplate */
export interface DistributionTemplate {
  Name: string;
  /** @format double */
  Id: number;
}

export enum DistributionFragmentType {
  MERCH = "MERCH",
  INCOME = "INCOME",
}

/** Model DistributionTemplateFragment */
export interface DistributionTemplateFragment {
  Type: DistributionFragmentType;
  Percentage: PrismaDecimal;
  Role: string;
  /** @format double */
  SubjectId: number | null;
  /** @format double */
  DistributionId: number;
  /** @format double */
  Id: number;
}

export interface DistributionTemplatesResponse {
  templates: (DistributionTemplate & {
    Fragments: (DistributionTemplateFragment & {
      Subject: Subject | null;
    })[];
  })[];
}

export interface DistributionTemplateResponse {
  template: DistributionTemplate & {
    Fragments: DistributionTemplateFragment[];
  };
}

/** Model Document */
export interface Document {
  FileLink: string;
  /** @format double */
  TransactionId: number | null;
  /** @format double */
  Id: number;
}

export enum ExternalBillingPeriod {
  JAN = "JAN",
  FEB = "FEB",
  MAR = "MAR",
  APR = "APR",
  MAY = "MAY",
  JUN = "JUN",
  JUL = "JUL",
  AUG = "AUG",
  SEP = "SEP",
  OCT = "OCT",
  NOV = "NOV",
  DEC = "DEC",
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickBillingExcludeKeysSourceOrPeriod {
  /** @format double */
  Id: number;
  /** @format double */
  Year: number;
  Turnover: PrismaDecimal | null;
  /** @format double */
  BankTransactionId: number;
  /** @format date-time */
  CreatedAt: string | null;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitBillingSourceOrPeriod = PickBillingExcludeKeysSourceOrPeriod;

export enum ExternalBillingSource {
  WmczDistribution = "wmcz_distribution",
  AwalMain = "awal_main",
  AwalSecond = "awal_second",
  ShopMikeRoft = "shop_mike_roft",
  ShopViktorSheen = "shop_viktor_sheen",
  ShopWmcz = "shop_wmcz",
  ShopPopstar = "shop_popstar",
  PosMikeRoft = "pos_mike_roft",
}

export type ExternalBillingTSOA = OmitBillingSourceOrPeriod & {
  Period: ExternalBillingPeriod;
  Source: ExternalBillingSource;
};

export enum BillingSource {
  WmczDistribution = "wmcz_distribution",
  AwalMain = "awal_main",
  AwalSecond = "awal_second",
  ShopMikeRoft = "shop_mike_roft",
  ShopViktorSheen = "shop_viktor_sheen",
  ShopWmcz = "shop_wmcz",
  ShopPopstar = "shop_popstar",
  PosMikeRoft = "pos_mike_roft",
}

/** Model ArticleAlias */
export interface ArticleAlias {
  /** @format double */
  ArticleId: number;
  ArticleType: string | null;
  ArticleName: string;
  OwnerName: string;
  CodeNameType: UniqueNameType;
  CodeName: string;
  /** @format double */
  Id: number;
}

export interface ExternalBillingRowMatched {
  articleName: string;
  articleOwner: string;
  articleUniqName: string;
  articleUniqNameType: UniqueNameType;
  /** @format double */
  count: number;
  revenue: Decimal;
  groupNameType?: UniqueNameType;
  /** Model Article */
  article: Article;
  aliases: {
    revenue: Decimal;
    /** Model ArticleAlias */
    alias: ArticleAlias;
  }[];
}

export interface ExternalBillingRow {
  articleName: string;
  articleOwner: string;
  articleUniqName: string;
  articleUniqNameType: UniqueNameType;
  /** @format double */
  count: number;
  revenue: Decimal;
  groupNameType?: UniqueNameType;
}

export type WarehousePartial = WarehouseTSOA & {
  $totalPrice: Decimal;
  /** @format double */
  $used: number;
};

export interface WarehouseStocks {
  /** Model Article */
  article: Article;
  stocks: WarehousePartial[];
}

export interface ArticleInsufficientWarehouse {
  Currency: string | null;
  Price: PrismaDecimal | null;
  /** @format date-time */
  ReleaseDate: string | null;
  Ownership: ArticleOwnership | null;
  ImageUrl: string | null;
  ArticleType: ArticleType;
  ArticleName: string;
  OwnerName: string;
  /** @format double */
  Id: number;
  /** @format double */
  $Missing: number;
}

export type WarehousePartialFragment = WarehousePartial & {
  /** @format double */
  $subjectId: number;
  $fragmentPrice: Decimal;
  $fragmentPercentage: Decimal;
};

export interface ExternalBillingSubjectRevenue {
  subject: SubjectWithArtist;
  revenue: Decimal;
  turnover: Decimal;
  articles: {
    turnover: Decimal;
    revenue: Decimal;
    aliases: {
      revenue: Decimal;
      /** Model ArticleAlias */
      alias: ArticleAlias;
    }[];
    /** Model Article */
    article: Article;
  }[];
  warehouse: WarehousePartialFragment[];
}

export interface ExternalBillingParseResult {
  source?: BillingSource;
  totalSum: {
    expected: {
      currency: string;
      turnover: Decimal;
    };
    vat: Decimal;
    currency: Currency;
    matches: boolean;
    revenue: Decimal;
    turnover: Decimal;
  };
  transaction: {
    currency: Currency;
    value: Decimal;
  };
  articles: {
    incomplete?: Article[];
    missing?: ExternalBillingRow[];
    matched: ExternalBillingRowMatched[];
  };
  warehouse: {
    unknownBuyer?: WarehousePartial[];
    insufficient?: ArticleInsufficientWarehouse[];
    located: WarehouseStocks[];
  };
  subjects: ExternalBillingSubjectRevenue[];
}

export interface BillingDetail {
  billing: ExternalBillingTSOA;
  details: ExternalBillingParseResult;
}

export interface ExternalBillingParseResultResponse {
  result: ExternalBillingParseResult;
  error?: string;
}

export interface ExactPeriod {
  /** @format double */
  year: number;
  /** @format double */
  month: number;
}

export interface ArticleWithTurnover {
  Currency: string | null;
  Price: PrismaDecimal | null;
  /** @format date-time */
  ReleaseDate: string | null;
  Ownership: ArticleOwnership | null;
  ImageUrl: string | null;
  ArticleType: ArticleType;
  ArticleName: string;
  OwnerName: string;
  /** @format double */
  Id: number;
  $Turnover: CurrencyRevenue[];
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickAffiliationExcludeKeysAmount {
  /** @format double */
  Id: number;
  /** @format double */
  TransactionId: number | null;
  /** @format double */
  SubjectId: number;
  /** @format double */
  ProjectId: number | null;
  Title: string;
  /** @format double */
  VATDeduction: number;
  IsVirtual: boolean;
  Currency: string;
  /** @format date-time */
  HappenedAt: string;
  Type: AffiliationType | null;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitAffiliationAmount = PickAffiliationExcludeKeysAmount;

export type AffiliationWithVAT = OmitAffiliationAmount & {
  Amount: null;
  AmountWithoutVAT: Decimal;
  AmountWithVAT: Decimal;
};

export interface WarehouseArticleStock {
  /** @format double */
  articleId: number;
  /** @format double */
  stocks: number;
  costs: CurrencyRevenue[];
  buyingPercentage?: Decimal;
}

export interface ReportBillingStock {
  /** @format double */
  billing: number;
  articles: WarehouseArticleStock[];
  warehouse: WarehouseTSOA[];
}

export interface ExternalBillingReportDeductions {
  sum: CurrencyRevenue[];
  affiliations: AffiliationWithVAT[];
}

export interface ExternalBillingReport {
  periods: ExactPeriod[];
  billings: (ExternalBillingTSOA & {
    $revenue: Decimal;
    $notified: boolean | null;
  })[];
  articles: ArticleWithTurnover[];
  total: {
    turnover: CurrencyRevenue[];
    revenue: CurrencyRevenue[];
  };
  affiliations: {
    articles: {
      /** @format double */
      stocks?: number;
      article: ArticleWithTurnover;
    }[];
    /** @format double */
    billing: number;
    affiliation: AffiliationWithVAT;
  }[];
  warehouseAll: ReportBillingStock[];
  deductions?: ExternalBillingReportDeductions;
  otherIncomes?: ExternalBillingReportDeductions;
}

export interface ReportInput {
  periods: ExactPeriod[];
  deductionAffiliationIds?: number[];
}

export interface ReportHead {
  /** @format double */
  Id: number;
  /** @format date-time */
  CreatedAt: string;
  /** @format date-time */
  ModifiedAt: string;
  $subject?: SubjectWithArtist | null;
  $periods?: ExactPeriod[];
  $currencies?: Currency[];
}

export interface InternalReportInput {
  periods: ExactPeriod[];
  articleType?: ArticleType;
  scope: "ARTICLE" | "ALIAS";
}

export interface Location {
  /** @format double */
  Id: number;
  Title: string;
  Archived?: boolean;
  /** @format double */
  Parent?: number;
  /** @format double */
  SubjectId?: number;
  /** @format double */
  ProjectId?: number;
}

export enum OrderPaymentMethod {
  CASH = "CASH",
  CARD = "CARD",
}

/** Model POSOrder */
export interface POSOrder {
  /** @format double */
  ReplacedById: number | null;
  Cancelled: boolean;
  /** @format date-time */
  UpdatedAt: string;
  /** @format date-time */
  CreatedAt: string;
  CreditNoteNumber: string | null;
  InvoiceNumber: string;
  Payment: OrderPaymentMethod;
  POS: string;
  /** @format double */
  Id: number;
}

export type PosOrderHeader = POSOrder & {
  Currency: string;
  TotalPrice: Decimal;
};

export interface PosOrderStats {
  Currency?: Currency;
  /** @format double */
  Count: number;
  TotalPrice: Decimal;
}

export interface PosOrderArticleStats {
  Currency?: Currency;
  TotalPrice: Decimal;
  /** @format double */
  Count: number;
  /** Model Article */
  Article: Article;
}

/** Model POSOrderItem */
export interface POSOrderItem {
  Currency: string;
  Price: PrismaDecimal;
  /** @format double */
  Count: number;
  /** @format double */
  ArticleId: number;
  /** @format double */
  POSOrderId: number;
  /** @format double */
  Id: number;
}

export type PosOrderItemWithName = POSOrderItem & {
  /** Model Article */
  Article: Article;
};

export type PosOrderWithItems = {
  $TotalPrice: Decimal;
  Items: PosOrderItemWithName[];
} & POSOrder;

export interface PosOrderItemCount {
  /** @format double */
  Count: number;
  /** @format double */
  ArticleId: number;
}

export interface PosOrderCreateRequest {
  /** @format double */
  replacesId?: number;
  method: OrderPaymentMethod;
  pos: string;
  articles: PosOrderItemCount[];
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickPosOrderCreateRequestArticles {
  articles: PosOrderItemCount[];
}

/** Model Project */
export interface Project {
  Archived: boolean;
  /** @format double */
  SubjectId: number;
  Name: string;
  /** @format double */
  Id: number;
}

/** Enums */
export enum TransactionState {
  DEFAULT = "DEFAULT",
  PENDING = "PENDING",
  LOCKED = "LOCKED",
}

/** Model BankTransaction */
export interface BankTransaction {
  /** Enums */
  State: TransactionState;
  Variable: string | null;
  Note: string | null;
  Currency: string;
  Amount: PrismaDecimal;
  /** @format date-time */
  HappenedAt: string;
  TransactionId: string;
  /** @format double */
  Id: number;
}

export type TransactionSearch = BankTransaction & {
  $IsBillingSource: boolean;
  Affiliations: {
    Subject: SubjectWithArtist;
  }[];
  Documents: Document[];
};

export interface TransactionSearchPerPeriod {
  transactions: TransactionSearch[];
  period: ExactPeriod;
}

export interface PageInfo {
  /** @format double */
  page: number;
  /** @format double */
  totalPages: number;
  recordsShown: {
    /** @format double */
    stop: number;
    /** @format double */
    start: number;
  };
  /** @format double */
  totalRecords: number;
}

export interface TransactionResponse {
  transactions: TransactionSearchPerPeriod[];
  page: PageInfo;
}

export interface GhostAffiliation {
  Amount: PrismaDecimal;
  Title: string;
  /** @format double */
  VATDeduction: number;
  /** @format double */
  LocationId: number;
}

export type TransactionToApprove = BankTransaction & {
  Documents: Document[];
  Affiliations: GhostAffiliation[];
};

export interface TransactionToApproveWrapper {
  transaction: TransactionToApprove | null;
  /** @format double */
  totalCount: number;
}

export interface AccountTransfer {
  /** @format double */
  Source: number;
  /** @format double */
  Target: number;
  /** @format double */
  Amount: number;
  Currency: Currency;
  SourceNote: string;
  TargetNote: string;
}

export interface ArticleWarehouse {
  /** Model Article */
  article: Article;
  warehouse: WarehouseTSOA[];
  /** @format double */
  stockTotal: number;
}

export interface WarehouseCreationData {
  /** @format double */
  articleId: number;
  /** @format double */
  stock: number;
  buyingPrice: Decimal;
  /** @format double */
  affiliationId?: number;
  /** @format date-time */
  date?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title mrft-accountant-backend
 * @version 1.0.0
 * @license ISC
 * @baseUrl /
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  accounts = {
    /**
     * No description
     *
     * @name GetAccount
     * @request GET:/accounts/{subjectId}
     */
    getAccount: (
      subjectId: number,
      query?: {
        /** @format double */
        filterYear?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          account: AccountInfo;
        },
        {
          reason: string;
        }
      >({
        path: `/accounts/${subjectId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetProject
     * @request GET:/accounts/{subjectId}/project/{projectId}
     */
    getProject: (
      subjectId: number,
      projectId: number,
      query?: {
        /** @format double */
        filterYear?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          project: ProjectInfo;
        },
        {
          reason: string;
        }
      >({
        path: `/accounts/${subjectId}/project/${projectId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAccountAffiliations
     * @request GET:/accounts/{subjectId}/items
     */
    getAccountAffiliations: (subjectId: number, params: RequestParams = {}) =>
      this.request<
        {
          affiliations: Affiliation[];
        },
        any
      >({
        path: `/accounts/${subjectId}/items`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  articleAliases = {
    /**
     * No description
     *
     * @name ArticleAliasUpdateSingle
     * @request PUT:/articleAliases/{articleAliasId}
     */
    articleAliasUpdateSingle: (articleAliasId: number, data: ArticleUpdate, params: RequestParams = {}) =>
      this.request<ArticleAliasesResponse, any>({
        path: `/articleAliases/${articleAliasId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArticleAliasDeleteSingle
     * @request DELETE:/articleAliases/{articleAliasId}
     */
    articleAliasDeleteSingle: (articleAliasId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/articleAliases/${articleAliasId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArticleAliasCreateMultiple
     * @request POST:/articleAliases
     */
    articleAliasCreateMultiple: (data: ArticleAliasCreation[], params: RequestParams = {}) =>
      this.request<ArticleAliasesBulkResponse, any>({
        path: `/articleAliases`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  articles = {
    /**
     * No description
     *
     * @name GroupGetAll
     * @request GET:/articles/groups
     */
    groupGetAll: (
      query?: {
        ownerIDs?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupResponse[], any>({
        path: `/articles/groups`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupCreateSingle
     * @request POST:/articles/groups
     */
    groupCreateSingle: (data: GroupCreation, params: RequestParams = {}) =>
      this.request<GroupResponse, any>({
        path: `/articles/groups`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupGetSingle
     * @request GET:/articles/groups/{groupId}
     */
    groupGetSingle: (groupId: number, params: RequestParams = {}) =>
      this.request<GroupResponse, any>({
        path: `/articles/groups/${groupId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupUpdateSingle
     * @request PUT:/articles/groups/{groupId}
     */
    groupUpdateSingle: (groupId: number, data: GroupCreation, params: RequestParams = {}) =>
      this.request<GroupResponse, any>({
        path: `/articles/groups/${groupId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupDeleteSingle
     * @request DELETE:/articles/groups/{groupId}
     */
    groupDeleteSingle: (groupId: number, params: RequestParams = {}) =>
      this.request<null, any>({
        path: `/articles/groups/${groupId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupAddArticle
     * @request PUT:/articles/groups/{groupId}/article
     */
    groupAddArticle: (groupId: number, data: ArticleAssign, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/articles/groups/${groupId}/article`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupRemoveArticle
     * @request DELETE:/articles/groups/{groupId}/article
     */
    groupRemoveArticle: (groupId: number, data: ArticleAssign, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/articles/groups/${groupId}/article`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupAddArticles
     * @request PUT:/articles/groups/{groupId}/article/bulk
     */
    groupAddArticles: (groupId: number, data: ArticleAssign[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/articles/groups/${groupId}/article/bulk`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupRemoveArticles
     * @request DELETE:/articles/groups/{groupId}/article/bulk
     */
    groupRemoveArticles: (groupId: number, data: ArticleAssign[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/articles/groups/${groupId}/article/bulk`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ArticlesGetAll
     * @request GET:/articles
     */
    articlesGetAll: (
      query?: {
        /** @default false */
        groupBySuffix?: boolean;
        articleType?: ArticleType;
        ownerIDs?: number[];
        /** @format double */
        releaseYear?: number;
        /** @format double */
        groupID?: number;
        articleName?: string;
        /** @format double */
        page?: number;
        ownership?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<ArticlesResponse, any>({
        path: `/articles`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArticleCreateSingle
     * @request POST:/articles
     */
    articleCreateSingle: (data: ArticleCreation, params: RequestParams = {}) =>
      this.request<ArticleResponse, any>({
        path: `/articles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArticleGetSingle
     * @request GET:/articles/{articleId}
     */
    articleGetSingle: (articleId: number, params: RequestParams = {}) =>
      this.request<ArticleResponse, any>({
        path: `/articles/${articleId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArticleUpdateSingle
     * @request PUT:/articles/{articleId}
     */
    articleUpdateSingle: (articleId: number, data: ArticleUpdate, params: RequestParams = {}) =>
      this.request<ArticleResponse, any>({
        path: `/articles/${articleId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArticleDeleteSingle
     * @request DELETE:/articles/{articleId}
     */
    articleDeleteSingle: (articleId: number, params: RequestParams = {}) =>
      this.request<null, any>({
        path: `/articles/${articleId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArticleCreateBulk
     * @request POST:/articles/bulk
     */
    articleCreateBulk: (
      query: {
        type: ArticleType;
        /** @default false */
        commit?: boolean;
      },
      data: {
        /** @format binary */
        file: File;
        fragments?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ArticlesBulkResponse, any>({
        path: `/articles/bulk`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  collections = {
    /**
     * No description
     *
     * @name GetCollections
     * @request GET:/collections
     */
    getCollections: (params: RequestParams = {}) =>
      this.request<
        {
          collections: POSCollectionWithGroups[];
        },
        any
      >({
        path: `/collections`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateCollection
     * @request POST:/collections
     */
    createCollection: (
      query: {
        collectionName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Model POSCollection */
          collection: POSCollection;
        },
        any
      >({
        path: `/collections`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCollection
     * @request GET:/collections/{collectionId}
     */
    getCollection: (collectionId: number, params: RequestParams = {}) =>
      this.request<
        {
          collection: POSCollectionWithGroups;
        },
        any
      >({
        path: `/collections/${collectionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SetCollectionArticles
     * @request PUT:/collections/{collectionId}
     */
    setCollectionArticles: (collectionId: number, data: number[], params: RequestParams = {}) =>
      this.request<
        {
          /** Model POSCollection */
          collection: POSCollection;
        },
        any
      >({
        path: `/collections/${collectionId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteCollection
     * @request DELETE:/collections/{collectionId}
     */
    deleteCollection: (
      collectionId: number,
      query?: {
        /** @default false */
        force?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/collections/${collectionId}`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name RenameCollection
     * @request POST:/collections/{collectionId}/rename
     */
    renameCollection: (
      collectionId: number,
      query: {
        collectionName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Model POSCollection */
          collection: POSCollection;
        },
        any
      >({
        path: `/collections/${collectionId}/rename`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  distributionFragments = {
    /**
     * No description
     *
     * @name DistributionFragmentsGetForArticle
     * @request GET:/distributionFragments/{articleId}
     */
    distributionFragmentsGetForArticle: (
      articleId: number,
      query: {
        type: DistributionFragmentTypeTSOA;
      },
      params: RequestParams = {},
    ) =>
      this.request<DistributionFragmentResponse, any>({
        path: `/distributionFragments/${articleId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FragmentUpdateSingle
     * @request PUT:/distributionFragments/{fragmentId}
     */
    fragmentUpdateSingle: (fragmentId: number, data: Decimal, params: RequestParams = {}) =>
      this.request<
        {
          fragment: DistributionFragmentTSOA;
        },
        any
      >({
        path: `/distributionFragments/${fragmentId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FragmentDeleteSingle
     * @request DELETE:/distributionFragments/{fragmentId}
     */
    fragmentDeleteSingle: (fragmentId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/distributionFragments/${fragmentId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name FragmentCreateSingle
     * @request POST:/distributionFragments
     */
    fragmentCreateSingle: (
      data: {
        type: DistributionFragmentTypeTSOA;
        role: string;
        percentage: string;
        /** @format double */
        subjectId: number;
        /** @format double */
        articleId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          fragment: DistributionFragmentTSOA;
        },
        any
      >({
        path: `/distributionFragments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FragmentCreateMultiple
     * @request POST:/distributionFragments/bulk
     */
    fragmentCreateMultiple: (
      data: {
        type: DistributionFragmentTypeTSOA;
        role: string;
        percentage: string;
        /** @format double */
        subjectId: number;
        /** @format double */
        articleId: number;
      }[],
      params: RequestParams = {},
    ) =>
      this.request<
        {
          fragments: DistributionFragmentTSOA[];
        },
        any
      >({
        path: `/distributionFragments/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  distributionTemplates = {
    /**
     * No description
     *
     * @name DistributionTemplatesGet
     * @request GET:/distributionTemplates
     */
    distributionTemplatesGet: (params: RequestParams = {}) =>
      this.request<DistributionTemplatesResponse, any>({
        path: `/distributionTemplates`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DistributionTemplateCreate
     * @request POST:/distributionTemplates
     */
    distributionTemplateCreate: (
      data: {
        fragments: {
          type: DistributionFragmentTypeTSOA;
          role: string;
          percentage: string;
          /** @format double */
          subjectId?: number;
        }[];
        templateName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DistributionTemplateResponse, any>({
        path: `/distributionTemplates`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TemplateDeleteSingle
     * @request DELETE:/distributionTemplates/{templateId}
     */
    templateDeleteSingle: (templateId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/distributionTemplates/${templateId}`,
        method: "DELETE",
        ...params,
      }),
  };
  documents = {
    /**
     * No description
     *
     * @name GetDocuments
     * @request GET:/documents/{id}
     */
    getDocuments: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          documents: Document[];
        },
        any
      >({
        path: `/documents/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateTransactionDocuments
     * @request PUT:/documents/{id}
     */
    updateTransactionDocuments: (id: number, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/documents/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  export = {
    /**
     * No description
     *
     * @name GetTable
     * @request GET:/export
     */
    getTable: (
      query: {
        period: ExternalBillingPeriod;
        /** @format double */
        year: number;
        articleIds: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<Decimal, any>({
        path: `/export`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  externalBilling = {
    /**
     * No description
     *
     * @name ExternalBillingGetHeadAll
     * @request GET:/externalBilling
     */
    externalBillingGetHeadAll: (params: RequestParams = {}) =>
      this.request<
        {
          billings: ExternalBillingTSOA[];
        },
        any
      >({
        path: `/externalBilling`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ExternalBillingCreateSingle
     * @request POST:/externalBilling
     */
    externalBillingCreateSingle: (
      query: {
        source: ExternalBillingSource;
        period: ExternalBillingPeriod;
        commit: boolean;
        /** @format double */
        transactionId: number;
        /** @format double */
        year: number;
        /** @default false */
        overrideIncorrectSum?: boolean;
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ExternalBillingParseResultResponse, any>({
        path: `/externalBilling`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ExternalBillingGetSingle
     * @request GET:/externalBilling/{billingId}
     */
    externalBillingGetSingle: (billingId: number, params: RequestParams = {}) =>
      this.request<BillingDetail | null, any>({
        path: `/externalBilling/${billingId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingDeleteSingle
     * @request DELETE:/externalBilling/{billingId}
     */
    billingDeleteSingle: (billingId: number, params: RequestParams = {}) =>
      this.request<boolean | null, any>({
        path: `/externalBilling/${billingId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingReportGetSingle
     * @request POST:/externalBilling/report/{subjectId}
     */
    billingReportGetSingle: (
      subjectId: number,
      data: ReportInput,
      query?: {
        /** @default false */
        includeSent?: boolean;
        filterCurrency?: Currency;
      },
      params: RequestParams = {},
    ) =>
      this.request<ExternalBillingReport, any>({
        path: `/externalBilling/report/${subjectId}`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingReportGetPdfRecordsAll
     * @request GET:/externalBilling/report/pdf/all
     */
    billingReportGetPdfRecordsAll: (
      query?: {
        /** @format double */
        filterYear?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReportHead[], any>({
        path: `/externalBilling/report/pdf/all`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingReportGetPdfSingle
     * @request GET:/externalBilling/report/pdf/{reportId}/content
     */
    billingReportGetPdfSingle: (reportId: number, params: RequestParams = {}) =>
      this.request<string | null, any>({
        path: `/externalBilling/report/pdf/${reportId}/content`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingReportCreatePdfSingle
     * @request POST:/externalBilling/report/{subjectId}/pdf
     */
    billingReportCreatePdfSingle: (
      subjectId: number,
      data: ReportInput,
      query?: {
        /** @default false */
        includeSent?: boolean;
        filterCurrency?: Currency;
      },
      params: RequestParams = {},
    ) =>
      this.request<Decimal, any>({
        path: `/externalBilling/report/${subjectId}/pdf`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingMailSendReport
     * @request POST:/externalBilling/mail/{reportId}
     */
    billingMailSendReport: (
      reportId: number,
      data: {
        content: string;
        subject: string;
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<true, any>({
        path: `/externalBilling/mail/${reportId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name BillingReportInternalCsvSingle
     * @request POST:/externalBilling/report/internal/csv
     */
    billingReportInternalCsvSingle: (data: InternalReportInput, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/externalBilling/report/internal/csv`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  locations = {
    /**
     * No description
     *
     * @name GetLocations
     * @request GET:/locations
     */
    getLocations: (params: RequestParams = {}) =>
      this.request<
        {
          locations: Location[];
        },
        any
      >({
        path: `/locations`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetLocationsBySubject
     * @request GET:/locations/{subjectId}
     */
    getLocationsBySubject: (subjectId: number, params: RequestParams = {}) =>
      this.request<
        {
          locations: Location[];
        },
        any
      >({
        path: `/locations/${subjectId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  posorders = {
    /**
     * No description
     *
     * @name GetOrderHeaders
     * @request GET:/posorders
     */
    getOrderHeaders: (
      query?: {
        /** @format double */
        page?: number;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
        paymentMethod?: OrderPaymentMethod;
      },
      params: RequestParams = {},
    ) =>
      this.request<PosOrderHeader[], any>({
        path: `/posorders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOrder
     * @request POST:/posorders
     */
    createOrder: (data: PosOrderCreateRequest, params: RequestParams = {}) =>
      this.request<PosOrderWithItems, any>({
        path: `/posorders`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOrderStats
     * @request GET:/posorders/stats
     */
    getOrderStats: (
      query?: {
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
        paymentMethod?: OrderPaymentMethod;
      },
      params: RequestParams = {},
    ) =>
      this.request<PosOrderStats, any>({
        path: `/posorders/stats`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetArticleStats
     * @request GET:/posorders/articles
     */
    getArticleStats: (
      query?: {
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
        paymentMethod?: OrderPaymentMethod;
      },
      params: RequestParams = {},
    ) =>
      this.request<PosOrderArticleStats[], any>({
        path: `/posorders/articles`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateItems
     * @request PUT:/posorders/{orderId}/items
     */
    updateItems: (orderId: number, data: PickPosOrderCreateRequestArticles, params: RequestParams = {}) =>
      this.request<PosOrderWithItems, any>({
        path: `/posorders/${orderId}/items`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CancelOrder
     * @request PUT:/posorders/{orderId}/cancel
     */
    cancelOrder: (orderId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/posorders/${orderId}/cancel`,
        method: "PUT",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOrder
     * @request GET:/posorders/{orderId}
     */
    getOrder: (orderId: number, params: RequestParams = {}) =>
      this.request<PosOrderWithItems, any>({
        path: `/posorders/${orderId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  projects = {
    /**
     * No description
     *
     * @name GetProjects
     * @request GET:/projects
     */
    getProjects: (params: RequestParams = {}) =>
      this.request<
        {
          projects: Project[];
        },
        any
      >({
        path: `/projects`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetProject
     * @request GET:/projects/project/{projectId}
     */
    getProject: (projectId: number, params: RequestParams = {}) =>
      this.request<
        {
          project: Project | null;
        },
        any
      >({
        path: `/projects/project/${projectId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveProject
     * @request DELETE:/projects/project/{projectId}
     */
    removeProject: (projectId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/projects/project/${projectId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArchiveProject
     * @request PUT:/projects/project/{projectId}
     */
    archiveProject: (projectId: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Model Project */
          project: Project;
        } | null,
        any
      >({
        path: `/projects/project/${projectId}`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAccountProjects
     * @request GET:/projects/{subjectId}
     */
    getAccountProjects: (subjectId: number, params: RequestParams = {}) =>
      this.request<
        {
          projects: Project[];
        },
        any
      >({
        path: `/projects/${subjectId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateProject
     * @request POST:/projects/{subjectId}
     */
    createProject: (
      subjectId: number,
      query: {
        projectName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Model Project */
          project: Project;
        },
        any
      >({
        path: `/projects/${subjectId}`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CategorizeAffiliation
     * @request PUT:/projects/{projectId}/affiliation/bulk
     */
    categorizeAffiliation: (projectId: number, data: number[], params: RequestParams = {}) =>
      this.request<
        {
          /** @format double */
          affectedCount: number;
        } | null,
        any
      >({
        path: `/projects/${projectId}/affiliation/bulk`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ProjectUpdateSingle
     * @request PUT:/projects/project/{projectId}/rename
     */
    projectUpdateSingle: (
      projectId: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Project, any>({
        path: `/projects/project/${projectId}/rename`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  subjects = {
    /**
     * No description
     *
     * @name GetSubjects
     * @request GET:/subjects
     */
    getSubjects: (params: RequestParams = {}) =>
      this.request<
        {
          subjects: SubjectWithArtist[];
        },
        any
      >({
        path: `/subjects`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SubjectCreateSingle
     * @request POST:/subjects
     */
    subjectCreateSingle: (
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SubjectWithArtist, any>({
        path: `/subjects`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SubjectSetDefaultProjects
     * @request PUT:/subjects/{subjectId}/default
     */
    subjectSetDefaultProjects: (
      subjectId: number,
      data: {
        /** @format double */
        expensesProjectId?: number;
        /** @format double */
        incomesProjectId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<SubjectWithArtist, any>({
        path: `/subjects/${subjectId}/default`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  transactions = {
    /**
     * No description
     *
     * @name GetTransactions
     * @request POST:/transactions
     */
    getTransactions: (
      data: ExactPeriod[],
      query?: {
        assigned?: boolean;
        documents?: boolean;
        filterNote?: string;
        state?: string;
        /** @format double */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<TransactionResponse, any>({
        path: `/transactions`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetTransactionToApprove
     * @request GET:/transactions/approve
     */
    getTransactionToApprove: (params: RequestParams = {}) =>
      this.request<TransactionToApproveWrapper, any>({
        path: `/transactions/approve`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetTransactionsExport
     * @request POST:/transactions/export
     */
    getTransactionsExport: (data: ExactPeriod[], params: RequestParams = {}) =>
      this.request<
        {
          textExport: string;
        },
        any
      >({
        path: `/transactions/export`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CategorizeAffiliations
     * @request POST:/transactions/categorize
     */
    categorizeAffiliations: (
      data: {
        affiliations: number[];
        /** @format double */
        projectId: number;
        /** @format double */
        subjectId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/transactions/categorize`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddTransfer
     * @request POST:/transactions/transfer
     */
    addTransfer: (data: AccountTransfer, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/transactions/transfer`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAffiliations
     * @request GET:/transactions/transaction/{transactionId}
     */
    getAffiliations: (transactionId: number, params: RequestParams = {}) =>
      this.request<
        {
          affiliations: GhostAffiliation[];
        },
        any
      >({
        path: `/transactions/transaction/${transactionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PutAffiliations
     * @request PUT:/transactions/transaction/{transactionId}
     */
    putAffiliations: (
      transactionId: number,
      data: {
        lockTransaction: boolean;
        affiliations: GhostAffiliation[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          affiliations: {
            Subject: SubjectWithArtist;
          }[];
        },
        any
      >({
        path: `/transactions/transaction/${transactionId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetTransactionByAffiliation
     * @request GET:/transactions/affiliation/{affiliationId}
     */
    getTransactionByAffiliation: (affiliationId: number, params: RequestParams = {}) =>
      this.request<BankTransaction | null, any>({
        path: `/transactions/affiliation/${affiliationId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  warehouse = {
    /**
     * No description
     *
     * @name WarehouseGetAll
     * @request GET:/warehouse
     */
    warehouseGetAll: (
      query?: {
        /** @format double */
        articleId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ArticleWarehouse[], any>({
        path: `/warehouse`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name WarehouseCreateSingle
     * @request POST:/warehouse
     */
    warehouseCreateSingle: (data: WarehouseCreationData, params: RequestParams = {}) =>
      this.request<WarehouseTSOA, any>({
        path: `/warehouse`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name WarehouseUpdateSingle
     * @request PUT:/warehouse/{warehouseId}
     */
    warehouseUpdateSingle: (
      warehouseId: number,
      query: {
        /** @format double */
        stockLeft: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<WarehouseTSOA, any>({
        path: `/warehouse/${warehouseId}`,
        method: "PUT",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name WarehouseDeleteSingle
     * @request DELETE:/warehouse/{warehouseId}
     */
    warehouseDeleteSingle: (warehouseId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/warehouse/${warehouseId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name WarehouseCreateMultiple
     * @request POST:/warehouse/bulk
     */
    warehouseCreateMultiple: (data: WarehouseCreationData[], params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/warehouse/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name WarehouseDeduct
     * @request POST:/warehouse/deduct/{articleId}
     */
    warehouseDeduct: (
      articleId: number,
      query: {
        /** @format double */
        stocks: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/warehouse/deduct/${articleId}`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
