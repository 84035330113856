import React from "react";
import BillingTableRow from "./BillingTableRow";
import { ExternalBillingTSOA } from "../../../services/api";

function BillingTable({ billings }: { billings: ExternalBillingTSOA[] }) {
  return (
    <div className="overflow-hidden bg-white shadow ">
      <ul role="list" className="divide-y divide-neutral-200">
        {billings.map((billing) => (
          <BillingTableRow billing={billing} />
        ))}
      </ul>
    </div>
  );
}

export default BillingTable;
