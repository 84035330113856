import React from "react";
import Loading from "../../../components/Loading";
import { distributionRoles } from "../../../util/roles";
import { useApi } from "../../../App";
import { useQuery } from "react-query";
import UpsertMerchForm from "../../upsert-articles/components/UpsertMerchForm";
import UpsertForm from "../../upsert-articles/components/UpsertForm";
import { Article } from "../../../services/api";

export function MissingArticleRowUpsertForm({
  catalogueType,
  setOpen,
  setAlias,
  setEditDisabled,
}: {
  catalogueType: "track" | "merch";
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlias: React.Dispatch<React.SetStateAction<Article | undefined>>;
  setEditDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const api = useApi();

  const { data: subjects, isLoading } = useQuery(
    ["subjects"],
    async () => await api.subjects.getSubjects(),
    {
      select: (x) => x.data.subjects,
    }
  );

  function setAliasWrapper(alias: Article) {
    setAlias(alias);
    setEditDisabled(true);
  }

  if (isLoading || subjects === undefined) {
    return (
      <div className="flex justify-center w-full h-16 py-8">
        <Loading />
      </div>
    );
  }

  return catalogueType === "merch" ? (
    <UpsertMerchForm
      subjects={subjects}
      roles={distributionRoles}
      mode={"insert"}
      catalogueType={catalogueType}
      setOpen={setOpen}
      inRow
      setAlias={setAliasWrapper}
    />
  ) : (
    <UpsertForm
      subjects={subjects}
      roles={distributionRoles}
      mode={"insert"}
      catalogueType={catalogueType}
      setOpen={setOpen}
      inRow
      setAlias={setAliasWrapper}
    />
  );
}
