import React, { useState } from "react";
import { useApi } from "../../../App";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loading from "../../../components/Loading";
import {
  PlusIcon,
  PencilSquareIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

import FragmentArray from "./FragmentArray";
import TextInputs from "./TextInputs";

import { classNames } from "../../../util/general";
import {
  ArticleType,
  Subject,
  UniqueNameType,
  DistributionFragmentTypeTSOA,
  ArticlesResponse,
  Article,
} from "../../../services/api";
import { QueryRefetch } from "../../../util/api";

export default function UpsertMerchForm({
  subjects,
  roles,
  mode,
  catalogueType,
  setOpen,
  refetch,
  inRow,
  setAlias,
}: {
  subjects: Subject[];
  roles: {
    Id: number;
    Name: string;
  }[];
  mode: "insert" | "update";
  catalogueType: "track" | "merch";
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch?: QueryRefetch<ArticlesResponse>;
  inRow?: boolean;
  setAlias?: (alias: Article) => void;
}) {
  const api = useApi();
  const [sumError, setSumError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);

  const validationSchema = yup.object().shape({
    owner: yup.string().required(),
    name: yup.string().required(),
    EAN: yup.string().required(),
    fragments: yup
      .array()
      .of(
        yup.object().shape({
          subjectId: yup.number().required().moreThan(0),
          fragmentType: yup.string().required(),
          role: yup.string(),
          percentage: yup
            .string()
            .required()
            .matches(/^[1-9][0-9]?(\.[0-9][1-9]?)?$|^100$/),
        })
      )
      .min(1),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    setValue,
    clearErrors,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm(formOptions);

  const { fields, append, remove } = useFieldArray({
    name: "fragments",
    control,
  });

  async function onSubmit({
    owner,
    name,
    EAN,
    fragments,
  }: {
    [x: string]: any;
  }) {
    setSaving(true);
    setSumError(false);

    const [expenseFragmentSum, incomeFragmentSum] = fragments.reduce(
      (
        [expenseSum, incomeSum]: [number, number],
        {
          fragmentType,
          percentage,
        }: { fragmentType: string; percentage: string }
      ) =>
        fragmentType === "Dělení nákladů"
          ? [(expenseSum = expenseSum + parseInt(percentage)), incomeSum]
          : [expenseSum, (incomeSum = incomeSum + parseInt(percentage))],
      [0, 0]
    );

    if (expenseFragmentSum !== 100 || incomeFragmentSum !== 100) {
      setSaving(false);
      setSumError(true);

      return;
    }

    const articleResponse = await api.articles.articleCreateSingle({
      OwnerName: owner.trim(),
      ArticleName: name.trim(),
      Ownership: null,
      ArticleType:
        catalogueType === "track" ? ArticleType.Track : ArticleType.Merch,
      ReleaseDate: null,
    });

    if (articleResponse.data.article === null) {
      console.error("Created article is null");
      return;
    }

    const articleId = articleResponse.data.article.Id;

    const fragmentCreationData = fragments.map(
      ({
        fragmentType,
        role,
        percentage,
        subjectId,
      }: {
        fragmentType: string;
        role: string;
        percentage: string;
        subjectId: number;
      }) => ({
        type:
          fragmentType === "Dělení nákladů"
            ? DistributionFragmentTypeTSOA.MERCH
            : DistributionFragmentTypeTSOA.INCOME,
        articleId,
        role,
        percentage,
        subjectId,
      })
    );

    const aliasesResponse = await api.articleAliases.articleAliasCreateMultiple(
      [
        {
          CodeName: EAN.trim(),
          CodeNameType: UniqueNameType.EAN,
          ArticleId: articleId,
          OwnerName: owner.trim(),
          ArticleName: name.trim(),
          ArticleType:
            catalogueType === "track" ? ArticleType.Track : ArticleType.Merch,
        },
      ]
    );

    const fragmentsResponse =
      await api.distributionFragments.fragmentCreateMultiple(
        fragmentCreationData
      );

    if (articleResponse.ok && aliasesResponse.ok && fragmentsResponse.ok) {
      setOpen(false);

      if (refetch) {
        refetch();
      }

      if (setAlias) {
        setAlias(articleResponse.data.article);
      }
    } else {
      console.error(articleResponse, aliasesResponse, fragmentsResponse);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={classNames(
          inRow ? "pr-3 pl-7 py-1" : "px-4 pt-5 pb-4 sm:p-6 sm:pb-4",
          "bg-white"
        )}
      >
        <div className="relative sm:flex sm:items-start">
          <div
            className={classNames(
              saving ? "visible" : "invisible",
              "absolute w-full h-full flex justify-center items-center"
            )}
          >
            {success ? (
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-auto bg-green-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                <CheckCircleIcon
                  className="w-5 h-5 text-green-600"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10">
                <Loading />
              </div>
            )}
          </div>
          {!inRow && (
            <div
              className={classNames(
                saving && "invisible",
                "flex items-center justify-center flex-shrink-0 w-10 h-10 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10"
              )}
            >
              {mode === "insert" ? (
                <PlusIcon className="w-5 h-5 text-sky-600" aria-hidden="true" />
              ) : (
                <PencilSquareIcon
                  className="w-5 h-5 text-sky-600"
                  aria-hidden="true"
                />
              )}
            </div>
          )}
          <div
            className={classNames(
              saving && "invisible",
              "flex flex-col flex-1 ml-4 space-y-4"
            )}
          >
            <TextInputs
              catalogueType={catalogueType}
              control={control}
              register={register}
              errors={errors}
            />

            <div className="py-4 border-b border-neutral-200"></div>
            <h2 className="mb-5 text-lg font-medium text-neutral-900">
              Rozdělení nákladů a výnosů
            </h2>
            <FragmentArray
              sumError={sumError}
              subjects={subjects}
              roles={roles}
              register={register}
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
              fieldArrayConfig={{ fields, append, remove }}
              includeType={true}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          inRow ? "py-4 pr-3" : "py-3 bg-neutral-50 px-6",
          "flex flex-row-reverse"
        )}
      >
        <button
          type="submit"
          disabled={saving}
          className={classNames(
            saving && "invisible",
            "inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent  shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
          )}
        >
          {mode === "insert" ? "Uložit" : "Přidat"}
        </button>
        <button
          type="button"
          disabled={saving}
          className={classNames(
            saving && "invisible",
            "inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-neutral-700 bg-white border border-neutral-300  shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          )}
          onClick={() => setOpen(false)}
        >
          Zrušit
        </button>
      </div>
    </form>
  );
}
