import React, { useEffect, useState } from "react";
import { useApi } from "../../../App";
import { useQuery } from "react-query";
import {
  Currency,
  CurrencyRevenue,
  ExternalBillingTSOA,
  Subject,
} from "../../../services/api";
import BillingTable from "./BillingTable";
import OptionDropdown from "./OptionDropdown";
import { DocumentPlusIcon, DocumentIcon } from "@heroicons/react/24/solid";
import InternalBillingModal from "./InternalBillingModal";
import { classNames } from "../../../util/general";
import { timeframeToExact } from "../../../util/periods";
import ReportTable from "./ReportTable";
import EmailModal from "./EmailModal";
import { PeriodSelector } from "../../../components/PeriodSelector";
import Loading from "../../../components/Loading";

const Billing = () => {
  const api = useApi();
  const [billingsType, setBillingsType] = useState("pro klienty");
  const [billingModalOpen, setBillingModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailSubject, setEmailSubject] = useState<Subject | null>(null);

  const [subject, setSubject] = useState<Subject | undefined>(undefined);
  const [timeframes, setTimeframes] = useState<string[]>([]);
  const [currency, setCurrency] = useState("všechny");

  const [billingDetails, setBillingDetails] = useState<
    (ExternalBillingTSOA & { $revenue: string })[]
  >([]);
  const [totalRevenue, setTotalRevenue] = useState<CurrencyRevenue[]>([]);

  useEffect(() => {
    window.document.title = "Vyúčtování — Accountant";
  }, []);

  const { data: subjects, isLoading: isLoadingSubjects } = useQuery(
    ["subjects"],
    api.subjects.getSubjects,
    {
      select: (x) => x.data.subjects,
    }
  );

  const { data: billings, isLoading: isLoadingBillings } = useQuery(
    ["billings"],
    async () => await api.externalBilling.externalBillingGetHeadAll(),
    { select: (x) => x.data.billings }
  );

  const { data: reports, isLoading: isLoadingReports } = useQuery(
    ["reports"],
    async () => await api.externalBilling.billingReportGetPdfRecordsAll(),
    { select: (x) => x.data }
  );

  async function openModal() {
    const periods = timeframes.flatMap(timeframeToExact);

    const { data } = await api.externalBilling.billingReportGetSingle(
      subject!.Id,
      { periods },
      {
        filterCurrency:
          currency === "všechny" ? undefined : (currency as Currency),
      }
    );

    console.log(data.total);

    if (data.total.revenue.length !== 0) {
      setTotalRevenue(data.total.revenue);
    }

    setBillingDetails(data.billings);
    setBillingModalOpen(true);
  }

  if (isLoadingSubjects || isLoadingBillings || isLoadingReports) {
    return (
      <div className="flex justify-center py-16">
        <Loading />
      </div>
    );
  }

  if (
    subjects === undefined ||
    billings === undefined ||
    reports === undefined
  ) {
    return <>something wrong</>;
  }

  return (
    <>
      <EmailModal
        open={emailModalOpen}
        setOpen={setEmailModalOpen}
        subject={emailSubject}
      />

      <InternalBillingModal
        open={billingModalOpen}
        setOpen={setBillingModalOpen}
        subject={subject}
        timeframes={timeframes}
        currency={currency}
        billingDetails={billingDetails}
        totalRevenue={totalRevenue}
      />

      <div className="flex flex-col flex-1 pt-10 pb-16 bg-neutral-100">
        <main className="flex-1">
          <div className="h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 overflow-visible align-middle min-h-[32rem] sm:px-6 lg:px-8">
                <div className="flex items-center space-x-2 text-lg font-medium text-neutral-900">
                  <DocumentPlusIcon className="w-5 h-5" />
                  <span>Tvorba interního vyúčtování</span>
                </div>
                <div className="grid w-full grid-cols-4 my-5 gap-x-4">
                  <div>
                    <OptionDropdown
                      label={"Subjekt"}
                      value={subject?.Name || "—"}
                      options={["—", ...subjects.map((s) => s.Name)]}
                      setValue={(v) =>
                        setSubject(subjects.find((s) => s.Name === v))
                      }
                      onChangeFn={() => {}}
                    />
                  </div>
                  <div>
                    <PeriodSelector
                      label={"Časové období"}
                      periods={timeframes}
                      setPeriods={setTimeframes}
                      limitToOne={false}
                    />
                  </div>
                  <div>
                    <OptionDropdown
                      label={"Měna"}
                      value={currency}
                      options={["—", "CZK", "EUR"]}
                      setValue={setCurrency}
                      onChangeFn={() => {}}
                    />
                  </div>

                  <div className="flex items-end justify-end space-x-4">
                    <button
                      disabled={
                        subject === undefined ||
                        timeframes.length === 0 ||
                        currency === "—"
                      }
                      onClick={openModal}
                      className={classNames(
                        (subject === undefined ||
                          timeframes.length === 0 ||
                          currency === "—") &&
                          "cursor-not-allowed opacity-50",
                        "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent  shadow-sm bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                      )}
                    >
                      Další krok
                    </button>
                  </div>
                </div>
                <hr className="mb-5 border-b border-neutral-200" />
                <div className="grid grid-cols-6 mb-5 text-lg font-medium text-neutral-900">
                  <div className="flex items-center col-span-5 space-x-2">
                    <DocumentIcon className="w-5 h-5" />
                    <span>Přehled vyúčtování</span>
                  </div>

                  <div>
                    <OptionDropdown
                      value={billingsType}
                      options={["pro klienty", "zpracovaná příchozí"]}
                      setValue={setBillingsType}
                      onChangeFn={() => {}}
                    />
                  </div>
                </div>
                {billingsType === "pro klienty" ? (
                  <ReportTable
                    reports={reports}
                    setSubject={setEmailSubject}
                    openModal={setEmailModalOpen}
                  />
                ) : (
                  <BillingTable billings={billings} />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Billing;
