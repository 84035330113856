import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { Subject } from "../../../services/api";
import { GroupTypeSelector, SubjectSelector, TextInput } from "./Inputs";

export default function MetadataForm({
  subjects,
  selectedSubjects,
  setSelectedSubjects,
  control,
  register,
  errors,
}: {
  subjects: Subject[];
  selectedSubjects: Subject[];
  setSelectedSubjects: React.Dispatch<React.SetStateAction<Subject[]>>;
  control: Control<FieldValues, unknown>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
}) {
  return (
    <div className="pt-5 pb-4 bg-white sm:pt-6 sm:pb-4">
      <div className="flex flex-col flex-1 space-y-4">
        <dl className="flex items-start justify-between w-full">
          <dt className="block py-2 text-sm font-medium text-neutral-700">
            <span>Umělci</span>
          </dt>
          <dd className="w-96">
            <SubjectSelector
              subjects={subjects}
              selectedSubjects={selectedSubjects}
              setSelectedSubjects={setSelectedSubjects}
            />
          </dd>
        </dl>
        <dl className="flex items-start justify-between w-full">
          <dt className="block py-2 text-sm font-medium text-neutral-700">
            <span>Název</span>
          </dt>
          <dd className="w-96">
            <TextInput valueKey="name" register={register} errors={errors} />
          </dd>
        </dl>
        <dl className="flex items-start justify-between w-full">
          <dt className="block py-2 text-sm font-medium text-neutral-700">
            <span>Typ</span>
          </dt>
          <dd className="w-96">
            <GroupTypeSelector
              valueKey="groupType"
              control={control}
              errors={errors}
            />
          </dd>
        </dl>
        <dl className="flex items-start justify-between w-full">
          <dt className="block py-2 text-sm font-medium text-neutral-700">
            <abbr title="Universal Product Code">UPC</abbr>
          </dt>
          <dd className="w-96">
            <TextInput
              valueKey="codeName"
              register={register}
              errors={errors}
            />
          </dd>
        </dl>
        <dl className="flex items-start justify-between w-full">
          <dt className="block py-2 text-sm font-medium text-neutral-700">
            <span>Odkaz na cover art</span>
          </dt>
          <dd className="w-96">
            <TextInput
              valueKey="coverLink"
              register={register}
              errors={errors}
            />
          </dd>
        </dl>
        <dl className="flex items-start justify-between w-full">
          <dt className="block py-2 text-sm font-medium text-neutral-700">
            <span>Datum vydání</span>
          </dt>
          <dd className="w-96">
            <Controller
              control={control}
              defaultValue=""
              name="releaseDate"
              render={({ field: { value, onChange } }) => (
                <input
                  onChange={onChange}
                  type="date"
                  className="block w-full px-3 py-2 mt-1 border shadow-sm border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900  focus:outline-none sm:text-sm"
                />
              )}
            />
          </dd>
        </dl>
      </div>
    </div>
  );
}
