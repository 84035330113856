import React, { Fragment, useMemo, useState } from "react";
import Decimal from "decimal.js";
import { useApi } from "../../../App";
import { useQuery } from "react-query";
import { Dialog, Transition } from "@headlessui/react";
import {
  Currency,
  CurrencyRevenue,
  ExternalBillingTSOA,
  Subject,
} from "../../../services/api";
import { Deduction } from "../../../types/types";
import DeductionForm from "./DeductionForm";
import { externalToTimeframe, timeframeToExact } from "../../../util/periods";
import { currencySymbol, formatAmount } from "../../../util/general";
import { BillingSourceNames } from "../../../util/enums";
import Loading from "../../../components/Loading";

const InternalBillingModal = ({
  open,
  setOpen,
  subject,
  timeframes,
  currency,
  billingDetails,
  totalRevenue,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subject?: Subject;
  timeframes: string[];
  currency?: string;
  billingDetails: (ExternalBillingTSOA & { $revenue: string })[];
  totalRevenue: CurrencyRevenue[];
}) => {
  if (subject === undefined || timeframes.length === 0) {
    return <></>;
  }

  const api = useApi();

  const { data: affiliations, isLoading } = useQuery(
    "affiliations",
    async () => await api.accounts.getAccountAffiliations(subject.Id),
    {
      select: (x) => x.data.affiliations,
    }
  );

  const [generating, setGenerating] = useState(false);
  const [deductions, setDeductions] = useState<Deduction[]>([]);

  const finalRevenue = useMemo(() => {
    return totalRevenue.map((r, i) => ({
      id: i,
      value:
        parseFloat(r.value) +
        deductions.reduce(
          (a, b) => a + parseInt(b.Amount) / (1 + b.VATDeduction / 100),
          0
        ),
      currency: r.currency,
    }));
  }, [totalRevenue, deductions]);

  async function submitPdfData() {
    if (generating) {
      return;
    }

    setGenerating(true);

    const periods = timeframes.flatMap(timeframeToExact);
    const response = await api.externalBilling.billingReportCreatePdfSingle(
      subject!.Id,
      {
        periods,
        deductionAffiliationIds: deductions.map((d) => d.Id),
      },
      {
        filterCurrency:
          currency === "všechny" ? undefined : (currency as Currency),
      }
    );

    if (!response.ok) {
      console.error(response.error);
      setGenerating(false);
      return;
    }

    window.open(encodeURI(response.data));

    setGenerating(false);
    setOpen(false);
  }

  if (affiliations === undefined || isLoading) {
    return <></>;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setOpen(false)}
      >
        <div className="flex items-end justify-center min-h-screen px-6 pt-6 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-700" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full max-w-lg my-12 text-left align-middle transition-all transform bg-white shadow-xl ">
              <div className="px-6 pb-12 my-8">
                <Dialog.Title
                  as="h3"
                  className="mb-4 text-xl font-medium leading-6 text-neutral-900"
                >
                  {subject.Name}{" "}
                  <span className="text-neutral-400">
                    {timeframes.join(", ")}
                  </span>
                </Dialog.Title>

                <hr className="border-b border-neutral-200" />

                <div className="pt-6 pb-2 font-medium text-neutral-900">
                  Zahrnuté příjmy
                </div>

                {billingDetails.map(
                  ({ Id, Source, Period, Year, $revenue }) =>
                    $revenue !== "0" && (
                      <div className="flex items-center py-1 text-sm" key={Id}>
                        <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
                          <div className="flex text-sm">
                            <p className="mr-1 font-medium truncate text-neutral-900">
                              {BillingSourceNames[Source]}
                            </p>
                            <p className="font-normal text-neutral-400">
                              {externalToTimeframe(Period, Year)}
                            </p>
                          </div>
                        </div>
                        <div className="flex-shrink-0 text-neutral-500">
                          <span className="font-medium text-neutral-900">
                            {formatAmount(new Decimal($revenue).toFixed(2))}
                          </span>{" "}
                          {currencySymbol(
                            Source.startsWith("shop") ||
                              Source.startsWith("wmcz")
                              ? "CZK"
                              : "EUR"
                          )}
                        </div>
                      </div>
                    )
                )}

                <div className="pt-6 pb-2 font-medium text-neutral-900">
                  Položky k zápočtu
                </div>

                <div className="pb-6">
                  <DeductionForm
                    affiliations={affiliations}
                    deductions={deductions}
                    setDeductions={setDeductions}
                    // currency={currency}
                  />
                </div>

                {finalRevenue.map(({ id, currency, value }) => (
                  <div key={id} className="flex items-center pb-1 text-sm">
                    <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
                      <div className="flex text-sm">
                        <p className="mr-2 font-medium truncate text-neutral-900">
                          Celkem k vyplacení
                        </p>
                      </div>
                    </div>
                    <div className="flex-shrink-0 text-neutral-500">
                      <span className="font-medium text-neutral-900">
                        {formatAmount(value)}
                      </span>{" "}
                      {currencySymbol(currency)}
                    </div>
                  </div>
                ))}
              </div>

              <div className="py-3 bg-neutral-50 px-6 h-[62px] flex flex-row justify-end ">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm  bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
                  onClick={submitPdfData}
                >
                  {generating && <Loading />}
                  Vygenerovat vyúčtování
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InternalBillingModal;
