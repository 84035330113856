import { ExternalBillingSource } from "../services/api";

type EnumMapping<T extends string, U> = {
  [K in T]: U;
};

export const BillingSourceName: EnumMapping<ExternalBillingSource, string> = {
  [ExternalBillingSource.WmczDistribution]: "[WMCZ] Digitální distribuce",
  [ExternalBillingSource.AwalMain]: "[AWAL] Main",
  [ExternalBillingSource.AwalSecond]: "[AWAL] Second",
  [ExternalBillingSource.ShopMikeRoft]: "[Shoptet] MIKE ROFT Shop",
  [ExternalBillingSource.ShopViktorSheen]: "[Shoptet] Viktor Sheen Shop",
  [ExternalBillingSource.ShopWmcz]: "[Shoptet] Warner Music Shop",
  [ExternalBillingSource.ShopPopstar]: "[Shoptet] POPSTAR Tea Shop",
  [ExternalBillingSource.PosMikeRoft]: "[POS] MIKE ROFT",
};

export const BillingSourceNames = {
  wmcz_distribution: "[WMCZ] Digitální distribuce",
  awal_main: "[AWAL] Main",
  awal_second: "[AWAL] Second",
  shop_mike_roft: "[Shoptet] MIKE ROFT Shop",
  shop_viktor_sheen: "[Shoptet] Viktor Sheen Shop",
  shop_wmcz: "[Shoptet] Warner Music Shop",
  shop_popstar: "[Shoptet] POPSTAR Tea Shop",
  pos_mike_roft: "[POS] MIKE ROFT",
};

export const BillingSourceCurrencies = {
  wmcz_distribution: "CZK",
  awal_main: "EUR",
  awal_second: "EUR",
  shop_mike_roft: "CZK",
  shop_viktor_sheen: "CZK",
  shop_wmcz: "CZK",
  shop_popstar: "CZK",
  pos_mike_roft: "CZK",
};

export const BillingSourceVATCoefficients = {
  wmcz_distribution: 1.21,
  awal_main: 1,
  awal_second: 1,
  shop_mike_roft: 1.21,
  shop_viktor_sheen: 1.21,
  shop_wmcz: 1.21,
  shop_popstar: 1.21,
  pos_mike_roft: 1.21,
};
