import React from "react";
import { Subject } from "../../../services/api";
import {
  UseFormRegister,
  FieldValues,
  Control,
  FieldErrors,
  Controller,
} from "react-hook-form";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";

import { classNames, displayGroupOwners } from "../../../util/general";

export const TextInput = ({
  valueKey,
  register,
  errors,
}: {
  valueKey: string;
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: any;
  };
}) => {
  return (
    <>
      <input
        type="text"
        id={valueKey}
        {...register(valueKey)}
        className={classNames(
          errors[valueKey] === undefined
            ? "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900"
            : "border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500",
          "resize-none block w-full py-2 px-3 mt-1 border  shadow-sm focus:outline-none sm:text-sm"
        )}
      />
    </>
  );
};

export const SubjectSelector = ({
  subjects,
  selectedSubjects,
  setSelectedSubjects,
}: {
  subjects: Subject[];
  selectedSubjects: Subject[];
  setSelectedSubjects: React.Dispatch<React.SetStateAction<Subject[]>>;
}) => {
  return (
    <Listbox multiple value={selectedSubjects} onChange={setSelectedSubjects}>
      <div className="relative">
        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none focus:ring-1 sm:text-sm">
          <span className="block truncate">
            {displayGroupOwners(selectedSubjects)}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              className="w-5 h-5 text-neutral-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {subjects.map((subject) => (
            <Listbox.Option
              key={subject.Id}
              className={({ active }) =>
                classNames(
                  active ? "text-white bg-sky-500" : "text-neutral-900",
                  "cursor-default select-none relative py-1.5 pl-3 pr-9 flex"
                )
              }
              value={subject}
            >
              {({ active }) => (
                <>
                  <span
                    className={classNames(
                      selectedSubjects.includes(subject)
                        ? "font-semibold"
                        : "font-normal",
                      "block truncate"
                    )}
                  >
                    {subject.Name}
                  </span>
                  {selectedSubjects.includes(subject) ? (
                    <span
                      className={classNames(
                        active ? "text-white" : "text-sky-500",
                        "absolute inset-y-0 right-0 flex items-center pr-4"
                      )}
                    >
                      <CheckIcon className="w-5 h-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export const GroupTypeSelector = ({
  valueKey,
  control,
  errors,
}: {
  control: Control<FieldValues, unknown>;
  valueKey: string;
  errors: FieldErrors<FieldValues>;
}) => {
  return (
    <Controller
      control={control}
      defaultValue=""
      name={valueKey}
      render={({ field: { value, onChange } }) => (
        <Listbox value={value} onChange={onChange}>
          <div className="relative">
            <Listbox.Button
              className={classNames(
                errors[valueKey] === undefined
                  ? "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900"
                  : "border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500",
                "relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default  focus:outline-none focus:ring-1 sm:text-sm"
              )}
            >
              <span className="block truncate">{value || "\u00A0"}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-neutral-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-96 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {["Album", "EP"].map((type) => (
                <Listbox.Option
                  key={type}
                  className={({ active }) =>
                    classNames(
                      active ? "text-white bg-sky-500" : "text-neutral-900",
                      "cursor-default select-none relative py-1.5 pl-3 pr-9 flex"
                    )
                  }
                  value={type}
                >
                  {({ active }) => (
                    <>
                      <span
                        className={classNames(
                          value === type ? "font-semibold" : "font-normal",
                          "block truncate"
                        )}
                      >
                        {type}
                      </span>
                      {value === type ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-sky-500",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      )}
    />
  );
};
