import React, { Fragment, useState } from "react";

import { classNames } from "../../../util/general";
import {
  ArticleGroup,
  ArticleOwnership,
  SubjectWithArtist,
} from "../../../services/api";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";

const Filters = ({
  subjects,
  groups,
  setQuery,
}: {
  subjects: SubjectWithArtist[] | undefined;
  groups: ArticleGroup[] | undefined;
  setQuery: React.Dispatch<
    React.SetStateAction<{
      ownerIDs?: number[];
      releaseYear?: number;
      groupID?: number;
      articleName?: string;
      ownership?: ArticleOwnership | null;
    }>
  >;
}) => {
  const years = Array.from(
    { length: new Date().getFullYear() - 2015 + 1 },
    (_, i) => new Date().getFullYear() - i
  );

  const [artist, setArtist] = useState<SubjectWithArtist | null>(null);
  const [group, setGroup] = useState<ArticleGroup | null>(null);
  const [fulltextSearch, setFulltextSearch] = useState("");
  const [releaseYear, setReleaseYear] = useState<number | null>(null);
  const [ownership, setOwnership] = useState<
    ArticleOwnership | null | undefined
  >(undefined);

  function getFilters() {
    setQuery({
      ownerIDs: artist ? [artist.Id] : undefined,
      releaseYear: releaseYear || undefined,
      groupID: group?.Id || undefined,
      articleName: fulltextSearch.trim() || undefined,
      ownership: ownership,
    });
  }

  return (
    <>
      <div className="grid w-full grid-cols-6 mb-10 gap-x-4">
        <div>
          <Listbox value={artist} onChange={setArtist}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-neutral-700">
                  Umělec
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none focus:ring-1 sm:text-sm">
                    <span className="block truncate">
                      {artist?.Artist?.Name || "—"}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon
                        className="w-5 h-5 text-neutral-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-sky-500"
                              : "text-neutral-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={null}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {"—"}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-sky-500",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      {subjects
                        ?.filter((s) => s.Artist)
                        ?.map((option, index) => (
                          <Listbox.Option
                            key={index}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-sky-500"
                                  : "text-neutral-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {option.Name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-sky-500",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div>
          <Listbox value={group} onChange={setGroup}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-neutral-700">
                  Album/EP
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none focus:ring-1 sm:text-sm">
                    <span className="block truncate">
                      {group?.GroupName || "—"}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon
                        className="w-5 h-5 text-neutral-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-sky-500"
                              : "text-neutral-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={null}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {"—"}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-sky-500",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      {groups
                        ?.filter((g) =>
                          artist === null
                            ? g.GroupName !== "N/A"
                            : g.GroupName.includes(artist.Name) &&
                              g.GroupName !== "N/A"
                        )
                        .map((option, index) => (
                          <Listbox.Option
                            key={index}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-sky-500"
                                  : "text-neutral-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {option.GroupName}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-sky-500",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div>
          <label
            htmlFor="fulltextSearch"
            className="block text-sm font-medium text-neutral-700"
          >
            Název
          </label>
          <input
            type="text"
            name="fulltextSearch"
            id="fulltextSearch"
            className="block w-full px-3 py-2 mt-1 border shadow-sm border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none sm:text-sm"
            value={fulltextSearch}
            onChange={(e) => setFulltextSearch(e.target.value)}
          />
        </div>
        <div>
          <Listbox value={releaseYear} onChange={setReleaseYear}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-neutral-700">
                  Rok vydání
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none focus:ring-1 sm:text-sm">
                    <span className="block truncate">{releaseYear || "—"}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon
                        className="w-5 h-5 text-neutral-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-sky-500"
                              : "text-neutral-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={null}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {"—"}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-sky-500",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      {years.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-sky-500"
                                : "text-neutral-900",
                              "cursor-default select-none relative py-2 pl-3 pr-9"
                            )
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {option}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-sky-500",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div>
          <Listbox value={ownership} onChange={setOwnership}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-neutral-700">
                  Vlastnictví
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border shadow-sm cursor-default border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none focus:ring-1 sm:text-sm">
                    <span className="block truncate">
                      {ownership?.toLowerCase() || "—"}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon
                        className="w-5 h-5 text-neutral-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-sky-500"
                              : "text-neutral-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={undefined}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {"—"}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-sky-500",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      {Object.values(ArticleOwnership).map((option, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-sky-500"
                                : "text-neutral-900",
                              "cursor-default select-none relative py-2 pl-3 pr-9"
                            )
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {option.toLowerCase()}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-sky-500",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-sky-500"
                              : "text-neutral-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={null}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              none
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-sky-500",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div className="flex items-end justify-end space-x-2">
          <button
            onClick={() => getFilters()}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"
          >
            Filtrovat
          </button>
        </div>
      </div>
    </>
  );
};

export default Filters;
