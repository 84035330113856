import React, { useState } from "react";
import { PencilIcon } from "@heroicons/react/24/solid";
import EditMetadataModal from "./EditMetadataModal";

const statBoxes = [
  { label: "Digitální distribuce", source: "DistroDigi" },
  { label: "Fyzická distribuce", source: "DistroPhys" },
  {
    label: "Práva na merch",
    source: "MerchRights",
  },
  {
    label: "Zastoupení – booking",
    source: "RepreBooking",
  },
  {
    label: "Zastoupení – reklama",
    source: "RepreAd",
  },
];

const MetadataOverview = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalInfo, setEditModalInfo] = useState<{
    label: string;
    source: string;
  }>({ label: "", source: "" });

  const openEditModal = ({
    label,
    source,
  }: {
    label: string;
    source: string;
  }) => {
    setEditModalInfo({ label, source });
    setEditModalOpen(true);
  };

  return (
    <>
      <EditMetadataModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        title={editModalInfo.label}
      />

      <div className="w-full overflow-hidden bg-white shadow ">
        <div className="grid w-full grid-cols-5">
          {statBoxes.map(({ label, source }, index) => (
            <div
              className="p-6 text-left border-r border-neutral-200 last:border-0"
              key={index}
            >
              <dt className="flex justify-start mb-2 space-x-1 font-medium truncate text-neutral-900">
                <span>{label}</span>
                <button
                  className="inline-flex items-center px-1 text-sm font-medium leading-4 bg-white shadow-sm text-neutral-700 focus:outline-none group"
                  title="Upravit"
                  onClick={() => openEditModal({ label, source })}
                >
                  <PencilIcon className="w-3 h-3 text-neutral-900 group-hover:text-sky-600" />
                </button>
              </dt>

              <dd className="text-xl text-neutral-500">
                {"do "}
                <span className="font-medium text-sky-600">16. 06. 2025</span>
              </dd>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MetadataOverview;
