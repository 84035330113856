import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Transition } from "@headlessui/react";
import { Subject } from "../../../services/api";
import { classNames } from "../../../util/general";

export default function EmailModal({
  open,
  setOpen,
  subject,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subject: Subject | null;
}) {
  if (subject === null) {
    return <></>;
  }

  const [sending, setSending] = useState(false);

  const validationSchema = yup.object().shape({
    targetEmail: yup.string().required(),
    subject: yup.string().required(),
    message: yup.string().required(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      targetEmail: subject.Email,
      subject: "Nové vyúčtování za období XYZ",
      message: "Text ve zprávě",
    },
  };

  const {
    setValue,
    clearErrors,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  async function onSubmit(data: { [x: string]: any }) {}

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="relative z-10 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative overflow-hidden text-left transition-all transform bg-white  shadow-xl sm:my-8 sm:max-w-xl sm:w-full">
                <div className="flex flex-col px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-mail
                  </label>
                  <div className="flex mt-1  shadow-sm">
                    <input
                      type="text"
                      {...register("targetEmail")}
                      className="flex-1 block w-full min-w-0 border-gray-300  focus:border-black focus:ring-black sm:text-sm"
                    />
                  </div>

                  <label
                    htmlFor="subject"
                    className="block mt-6 text-sm font-medium text-gray-700"
                  >
                    Předmět
                  </label>
                  <div className="flex mt-1  shadow-sm">
                    <input
                      type="text"
                      {...register("subject")}
                      className="flex-1 block w-full min-w-0 border-gray-300  focus:border-black focus:ring-black sm:text-sm"
                    />
                  </div>

                  <label
                    htmlFor="about"
                    className="block mt-6 text-sm font-medium text-gray-700"
                  >
                    Zpráva
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="about"
                      name="about"
                      rows={5}
                      className="block w-full border-gray-300  shadow-sm focus:border-black focus:ring-black sm:text-sm"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="py-3 bg-neutral-50 px-6 h-[62px] flex flex-row-reverse">
                  <button
                    type="submit"
                    disabled={sending}
                    className={classNames(
                      sending && "invisible",
                      "inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent  shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                    )}
                  >
                    Odeslat
                  </button>
                  <button
                    type="button"
                    disabled={sending}
                    className={classNames(
                      sending && "invisible",
                      "inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-neutral-700 bg-white border border-neutral-300  shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    )}
                    onClick={() => setOpen(false)}
                  >
                    Zrušit
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
}
