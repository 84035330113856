import React from "react";
import { DocumentIcon as SingleDoc, DocumentDuplicateIcon as MultiDoc } from "@heroicons/react/24/solid";

function taglineDeclension(documents: number) {
  switch (documents) {
    case 1:
      return "přiřazený dokument";

    case 2:
    case 3:
    case 4:
      return "přiřazené dokumenty";

    default:
      return "přiřazených dokumentů";
  }
}

export default function DocumentIcon({ length }: { length: number | undefined }) {
  if (!length) {
    return <></>;
  }

  return (
    <div title={`${length} ${taglineDeclension(length)}`}>
      {length > 1 ? (
        <MultiDoc className="flex-shrink-0 w-5 h-5 text-neutral-400" aria-hidden="true" />
      ) : (
        <SingleDoc className="flex-shrink-0 w-5 h-5 text-neutral-400" aria-hidden="true" />
      )}
    </div>
  );
}
