import React from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";

import { classNames } from "../../../util/general";

const PercentageInput = ({
  rowIndex,
  register,
  errors,
}: {
  rowIndex: number;
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: any;
  };
}) => {
  return (
    <div className="col-span-2">
      {rowIndex === 0 && (
        <label
          htmlFor="percentage"
          className="block text-sm font-medium text-neutral-700"
        >
          Podíl
        </label>
      )}
      <div className="relative">
        <input
          type="text"
          id="percentage"
          {...register(`fragments.${rowIndex}.percentage`)}
          className={classNames(
            errors.fragments?.[rowIndex]?.percentage === undefined
              ? "border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900"
              : "border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500",
            "block w-full py-2 pl-3 pr-6 mt-1 border  shadow-sm focus:outline-none sm:text-sm"
          )}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <span className="text-neutral-400 sm:text-sm">%</span>
        </div>
      </div>
    </div>
  );
};

export default PercentageInput;
