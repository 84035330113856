import React from "react";
import { Listbox } from "@headlessui/react";
import { ArchiveBoxIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../util/general";
import { Project } from "../../../services/api";

const ListboxLocation = (item: Project) => (
  <Listbox.Option
    key={item.Id}
    className={({ active }) =>
      classNames(
        active ? "text-white bg-sky-500" : "text-neutral-900",
        "cursor-default select-none relative py-1.5 px-3"
      )
    }
    value={item}
  >
    {({ selected, active }) => (
      <>
        <div
          className={classNames(
            selected ? "font-semibold" : "font-normal",
            "truncate flex space-x-1.5 items-center justify-start"
          )}
        >
          <span>{item.Name || "nezařazené"}</span>
          {item.Archived && (
            <ArchiveBoxIcon
              className="flex-shrink-0 w-4 h-4"
              aria-hidden="true"
            />
          )}
        </div>
      </>
    )}
  </Listbox.Option>
);

export default ListboxLocation;
