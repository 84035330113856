import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";
import { useApi } from "../../../App";
import { ProjectInfo } from "../../../services/api";

export default function ArchiveModal({
  open,
  setOpen,
  project,
  setShownTab,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  project: ProjectInfo | undefined;
  setShownTab: (id: number) => void;
}) {
  const api = useApi();
  const cancelButtonRef = useRef(null);

  async function onConfirm() {
    if (project === undefined) {
      return;
    }

    const removeResult = await api.projects.archiveProject(project.Id);

    if (removeResult.ok) {
      setOpen(false);
      setShownTab(0);
    }
  }

  if (!project) {
    return <></>;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                {project !== undefined && (
                  <div className="sm:flex sm:items-start">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ArchiveBoxIcon
                        className="w-6 h-6 text-sky-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-neutral-900"
                      >
                        Archivace složky
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-neutral-500">
                          Určitě chcete archivovat tuto složku? Celkový objem
                          prostředků bude vložen mezi nezařazené transakce. Tato
                          akce je nevratná.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onConfirm}
                  >
                    Archivovat
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Zpět
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
