import React from "react";
import { classNames } from "../../../util/general";

const TabBar = ({
  shownTab,
  setShownTab,
  disableTracklist,
}: {
  shownTab: number;
  setShownTab: (id: number) => void;
  disableTracklist: boolean;
}) => {
  return (
    <div className="flex justify-between mb-6">
      <nav className="flex py-px space-x-4" aria-label="Tabs">
        <button
          type="button"
          onClick={() => setShownTab(0)}
          className={classNames(
            shownTab === 0
              ? "bg-sky-600 text-white"
              : "bg-neutral-200 text-neutral-600 hover:text-neutral-700 hover:bg-neutral-300",
            "px-3 py-2 font-medium text-sm "
          )}
          aria-current={shownTab === 0 ? "page" : undefined}
        >
          Metadata
        </button>
        <button
          type="button"
          onClick={() => {
            if (!disableTracklist) setShownTab(1);
          }}
          title={disableTracklist ? "Nejprve vyplň umělce." : ""}
          className={classNames(
            disableTracklist
              ? "opacity-50 cursor-not-allowed"
              : shownTab === 1
              ? "bg-sky-600 text-white"
              : "bg-neutral-200 text-neutral-600 hover:text-neutral-700 hover:bg-neutral-300",
            "px-3 py-2 font-medium text-sm "
          )}
          aria-current={shownTab === 1 ? "page" : undefined}
        >
          Tracklist
        </button>
      </nav>
    </div>
  );
};

export default TabBar;
