import React from "react";
import {
  FieldValues,
  UseFormRegister,
  UseFormWatch,
  UseFormSetValue,
  UseFormClearErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from "react-hook-form";
import { Subject } from "../../../services/api";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../util/general";

import DropdownSelector from "./DropdownSelector";
import PercentageInput from "./PercentageInput";

const FragmentArray = ({
  fieldArrayConfig,
  sumError,
  subjects,
  roles,
  register,
  watch,
  setValue,
  clearErrors,
  errors,
  includeType,
}: {
  fieldArrayConfig: {
    fields: Record<"id", string>[];
    append: UseFieldArrayAppend<FieldValues, any>;
    remove: UseFieldArrayRemove;
  };
  sumError: boolean;
  subjects: Subject[];
  roles: {
    Id: number;
    Name: string;
  }[];
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: {
    [x: string]: any;
  };
  includeType: boolean;
}) => {
  const { fields, append, remove } = fieldArrayConfig;

  function addFragment() {
    const fragmentSum = (fields as any).reduce(
      (sum: number, { percentage }: { percentage: string }) =>
        (sum = sum + parseInt(percentage)),
      0
    );

    append({
      subjectId: 0,
      type: "",
      role: "",
      percentage: Math.min((includeType ? 200 : 100) - fragmentSum, 100),
    });
  }

  return (
    <div>
      {fields.map((item, i) => (
        <div
          key={item.id}
          className="grid w-full grid-cols-12 col-span-6 py-1 gap-x-4"
        >
          <div
            className={classNames(includeType ? "col-span-3" : "col-span-6")}
          >
            <DropdownSelector
              rowIndex={i}
              type={"subjectId"}
              values={subjects}
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
              limitHeight
            />
          </div>
          {includeType && (
            <div className="col-span-3">
              <DropdownSelector
                rowIndex={i}
                type={"fragmentType"}
                values={[
                  { Id: 1, Name: "Dělení nákladů" },
                  { Id: 2, Name: "Dělení výnosů" },
                ]}
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
                errors={errors}
              />
            </div>
          )}

          <div className="col-span-3">
            <DropdownSelector
              rowIndex={i}
              type={"role"}
              values={roles}
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
            />
          </div>

          <div className="grid grid-cols-3 col-span-3">
            <PercentageInput rowIndex={i} register={register} errors={errors} />

            <div className="flex justify-end">
              <div className="flex flex-col">
                <button
                  type="button"
                  className="inline-flex items-center p-1 mt-auto mb-1 ml-4 bg-transparent border border-transparent rounded-full text-neutral-500 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  onClick={() => remove(i)}
                >
                  <XCircleIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="flex justify-between col-span-8 pt-8 pb-4">
        <div className="flex items-center space-x-4">
          {sumError && (
            <span className="text-sm text-red-600">
              Součet podílů neodpovídá {includeType ? "celkům" : "celku"}.
            </span>
          )}
        </div>
        <button
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
          type="button"
          onClick={addFragment}
        >
          Přidat řádek
        </button>
      </div>
    </div>
  );
};

export default FragmentArray;
