import React, { useState, useEffect } from "react";
import { BankTransactionDetails, QueryRefetch } from "../../../util/api";
import {
  classNames,
  formatAmount,
  currencySymbol,
  formatDate,
} from "../../../util/general";
import {
  isCardPayment,
  clipNote,
  formatVariable,
} from "../../../util/transactions";
import SubjectPill from "./SubjectPill";
import DocumentIcon from "./AffiliatedDocumentIcon";
import TransactionDetail from "./TransactionDetail";
import {
  CreditCardIcon,
  BanknotesIcon,
  DocumentChartBarIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { Location } from "../../../services/api";
import { PauseCircleIcon } from "@heroicons/react/24/solid";
import { noteExists } from "../../../util/transactions";

const TransactionRow = ({
  stateIcons = true,
  disabled,
  refetch,
  locations,
  transaction,
  isLastRow,
  approvalMode,
}: {
  stateIcons?: boolean;
  disabled: boolean;
  refetch: QueryRefetch<any>;
  locations: Location[];
  transaction: BankTransactionDetails;
  isLastRow: boolean;
  approvalMode?: boolean;
}) => {
  const [docCount, setDocCount] = useState(0);
  const [detailOpened, setDetailOpened] = useState(false);
  const close = () => setDetailOpened(false);

  const onRowClick = () => {
    setDetailOpened(true);
  };

  useEffect(() => {
    if (transaction.Documents !== undefined) {
      setDocCount(transaction.Documents.length);
    }
  }, [transaction]);

  return (
    <>
      <div
        className={classNames(
          detailOpened
            ? "border-t border-neutral-300"
            : "hover:cursor-pointer hover:bg-neutral-50",
          "grid w-full transition-colors bg-white grid-cols-24"
        )}
        onClick={onRowClick}
      >
        <div
          className={classNames(
            isLastRow && !detailOpened && "rounded-bl-lgg",
            "px-4 py-2.5 col-span-2 text-sm text-left text-neutral-400 whitespace-nowrap border-b border-neutral-200"
          )}
        >
          {`#${transaction.Id}`}
        </div>
        <div className="px-4 py-2.5 col-span-2 text-sm text-left text-neutral-500 whitespace-nowrap border-b border-neutral-200">
          {formatDate(transaction.HappenedAt)}
        </div>

        <div className="col-span-14 px-4 py-2.5 text-sm font-medium text-neutral-900 whitespace-nowrap flex items-center overflow-hidden text-ellipsis border-b border-neutral-200">
          <div className="flex text-sm truncate">
            {transaction.$IsBillingSource ? (
              <DocumentChartBarIcon
                className="flex-shrink-0 w-5 h-5 mr-2 text-neutral-400"
                aria-hidden="true"
              />
            ) : isCardPayment(transaction.Currency, transaction.Variable) ? (
              <CreditCardIcon
                className="flex-shrink-0 w-5 h-5 mr-2 text-neutral-400"
                aria-hidden="true"
              />
            ) : (
              <BanknotesIcon
                className="flex-shrink-0 w-5 h-5 mr-2 text-neutral-400"
                aria-hidden="true"
              />
            )}
            {noteExists(transaction.Note) && (
              <span
                className="mr-2 truncate text-neutral-700"
                title={transaction.Note || undefined}
              >
                {clipNote(transaction.Note)}
              </span>
            )}{" "}
            <span className="font-normal text-neutral-400">
              {formatVariable(transaction.Currency, transaction.Variable)}
            </span>
          </div>
        </div>

        <div
          className={
            "justify-between flex text-sm col-span-3 text-right text-neutral-500 py-2.5 border-b border-neutral-200"
          }
        >
          <div className="flex flex-row-reverse space-x-2 space-x-reverse">
            {stateIcons && (
              <div className="w-5">
                <DocumentIcon length={docCount} />
              </div>
            )}
            {stateIcons &&
              (transaction.State === "PENDING" ? (
                <div title="čeká na schválení">
                  <PauseCircleIcon
                    className="flex-shrink-0 w-5 h-5 text-neutral-400"
                    aria-hidden="true"
                  />
                </div>
              ) : transaction.State === "LOCKED" ||
                transaction.$IsBillingSource ? (
                <div title="zařazení uzamčeno">
                  <LockClosedIcon
                    className="flex-shrink-0 w-5 h-5 text-neutral-400"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <div className="w-5"></div>
              ))}
          </div>
          <div className="font-medium text-neutral-900">
            <span
              className={
                parseFloat(transaction.Amount) < 0
                  ? "text-red-600"
                  : parseFloat(transaction.Amount) > 0
                  ? "text-green-700"
                  : "text-neutral-900"
              }
            >
              {formatAmount(transaction.Amount)}
            </span>{" "}
            {currencySymbol(transaction.Currency)}
          </div>
        </div>

        <div
          className={classNames(
            isLastRow && !detailOpened && "rounded-br-lgg",
            "px-4 py-1.5 text-sm col-span-3 text-right text-neutral-500 flex items-center justify-end border-b border-neutral-200"
          )}
        >
          <div>
            <SubjectPill affiliations={transaction.Affiliations} />
          </div>
        </div>
      </div>
      {detailOpened && (
        <TransactionDetail
          disabled={disabled}
          refetch={refetch}
          locations={locations}
          transaction={transaction}
          isLastRow={isLastRow}
          close={close}
          setDocCount={setDocCount}
          approvalMode={approvalMode}
        />
      )}
    </>
  );
};

export default TransactionRow;
