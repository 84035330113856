import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { XCircleIcon } from "@heroicons/react/24/solid";
import MRFTLogo from "../../../assets/mrft-logo.svg";
import { classNames } from "../../../util/general";
import Loading from "../../../components/Loading";

const errorMessages = {
  "auth/invalid-email": "E-mail je v neplatném tvaru.",
  "auth/user-not-found": "Tento uživatel neexistuje.",
  "auth/wrong-password": "Zadané heslo není správné.",
  "auth/too-many-requests": "Příliš mnoho špatných hesel. Zkus to později.",
};

export default function Login() {
  const [loggingIn, setLoggingIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const auth = getAuth();

  async function attemptSignIn() {
    if (!email || !password || loggingIn) {
      return;
    }

    setLoggingIn(true);

    signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        setError(error.code);
      })
      .finally(() => {
        setLoggingIn(false);
      });
  }

  return (
    <div className="flex flex-col justify-center min-h-screen py-12 bg-neutral-50 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="w-auto h-6 mx-auto" src={MRFTLogo} alt="MIKE ROFT" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 bg-white shadow sm: sm:px-10">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-neutral-700"
              >
                E-mail
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      attemptSignIn();
                    }
                  }}
                  autoComplete="email"
                  required
                  className="block w-full px-3 py-2 text-black border shadow-sm appearance-none placeholder-neutral-400 border-neutral-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-neutral-700"
              >
                Heslo
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError("");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      attemptSignIn();
                    }
                  }}
                  autoComplete="current-password"
                  required
                  className="block w-full px-3 py-2 text-black border shadow-sm appearance-none placeholder-neutral-400 border-neutral-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm"
                />
              </div>
            </div>

            {error && (
              <div className="flex text-sm align-middle text-neutral-500">
                <XCircleIcon className="w-4 h-4 mr-2 mt-0.5" />
                <span>
                  {errorMessages[error as keyof typeof errorMessages] || error}
                </span>
              </div>
            )}

            <div>
              <button
                onClick={attemptSignIn}
                className={classNames(
                  (!email || !password || loggingIn) &&
                    "pointer-events-none opacity-40 cursor-wait",
                  "flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black border border-transparent  shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                )}
              >
                {loggingIn ? <Loading /> : null} Přihlásit se
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
