import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

function Pagination({
  info,
}: {
  info: {
    page: number;
    totalPages: number;
    recordsShown: {
      start: number;
      stop: number;
    };
    totalRecords: number;
  };
}) {
  function renderButtons(currentPage: number, totalPages: number) {
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;
    const prevPage = currentPage - 1;
    const nextPage = currentPage + 1;

    const location = useLocation();

    return (
      <nav className="flex text-neutral-500">
        {/* first button */}
        {!isFirstPage && (
          <Link
            className="p-4 transition-colors border-b-4 border-transparent hover:bg-neutral-100"
            to={`/transactions/${prevPage}${location.search}`}
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </Link>
        )}

        {/* second button */}
        {currentPage > 2 && (
          <Link
            className="p-4 transition-colors border-b-4 border-transparent hover:bg-neutral-100"
            to={`/transactions/1${location.search}`}
          >
            1
          </Link>
        )}

        {/* ellipsis */}
        {currentPage > 3 && (
          <span className="p-4 border-b-4 border-transparent">...</span>
        )}

        {/* previous to current button */}
        {currentPage > 1 && (
          <Link
            className="p-4 transition-colors border-b-4 border-transparent hover:bg-neutral-100"
            to={`/transactions/${prevPage}${location.search}`}
          >
            {prevPage}
          </Link>
        )}

        {/* current button */}
        <Link
          className="p-4 transition-colors border-b-4 border-sky-600 text-neutral-700 hover:bg-neutral-100"
          to={`/transactions/${currentPage}${location.search}`}
        >
          {currentPage}
        </Link>

        {/* subsequent to current button */}
        {currentPage < totalPages && (
          <Link
            className="p-4 transition-colors border-b-4 border-transparent hover:bg-neutral-100"
            to={`/transactions/${nextPage}${location.search}`}
          >
            {nextPage}
          </Link>
        )}

        {/* ellipsis */}
        {currentPage < totalPages - 2 && (
          <span className="p-4 border-b-4 border-transparent">...</span>
        )}

        {/* second to last button */}
        {currentPage < totalPages - 1 && (
          <Link
            className="p-4 transition-colors border-b-4 border-transparent hover:bg-neutral-100"
            to={`/transactions/${totalPages}${location.search}`}
          >
            {totalPages}
          </Link>
        )}

        {/* last button */}
        {!isLastPage && (
          <Link
            className="p-4 transition-colors border-b-4 border-transparent hover:bg-neutral-100"
            to={`/transactions/${nextPage}${location.search}`}
          >
            <ChevronRightIcon className="w-4 h-4" />
          </Link>
        )}
      </nav>
    );
  }

  return (
    <div className="flex items-center justify-between w-full px-4 text-xs font-medium tracking-wider uppercase bg-neutral-50 text-neutral-400">
      <div className="py-4">
        <span className="text-neutral-700">{info.recordsShown.start}</span>
        {"–"}
        <span className="text-neutral-700">{info.recordsShown.stop}</span> z
        celkových <span className="text-neutral-700">{info.totalRecords}</span>{" "}
        záznamů
      </div>
      <div>
        {info.totalPages > 1 && renderButtons(info.page, info.totalPages)}
      </div>
    </div>
  );
}

export default Pagination;
