import React from "react";
import { Accordion } from "@mantine/core";
import Decimal from "decimal.js";
import { ExternalBillingSubjectRevenue } from "../../../services/api";
import { currencySymbol, formatAmount } from "../../../util/general";

function SumOverview({
  subjectSums,
  currency,
}: {
  subjectSums: ExternalBillingSubjectRevenue[];
  currency: string;
}) {
  return (
    <Accordion
      multiple
      chevronPosition="left"
      classNames={{
        root: "border border-neutral-200",
        control: "font-medium text-neutral-900 py-2",
        label: "flex justify-between",
        content: "flex flex-col space-y-1 pt-2 pb-4 px-2",
      }}
    >
      {subjectSums.map(
        ({ subject, turnover, revenue: totalRevenue, articles }) => (
          <Accordion.Item value={subject.Name} key={subject.Id}>
            <Accordion.Control>
              <div className="text-sm font-bold">{subject.Name}</div>
              <div className="flex items-end space-x-1.5 font-mono text-sm text-right text-neutral-500 whitespace-nowrap">
                <div className="font-medium text-neutral-900">
                  {formatAmount(new Decimal(turnover).toFixed(2))}
                </div>
                <div> {currencySymbol(currency)}</div>
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              {totalRevenue !== turnover && (
                <div className="flex justify-between">
                  <div className="text-sm font-medium text-neutral-900">
                    Vrácení nákladů
                  </div>
                  <div className="flex items-end space-x-1.5 font-mono text-sm text-right text-neutral-500 whitespace-nowrap">
                    <div className="font-medium text-neutral-900">
                      {formatAmount(
                        new Decimal(turnover)
                          .minus(new Decimal(totalRevenue))
                          .toFixed(2)
                      )}
                    </div>
                    <div> {currencySymbol(currency)}</div>
                  </div>
                </div>
              )}
              {articles
                .sort((a, b) => parseFloat(b.revenue) - parseFloat(a.revenue))
                .map(({ article, revenue }) => (
                  <div key={article.Id} className="flex justify-between">
                    <div className="text-sm font-medium text-neutral-900">
                      {article.OwnerName} — {article.ArticleName}
                    </div>
                    <div className="flex items-end space-x-1.5 font-mono text-sm text-right text-neutral-500 whitespace-nowrap">
                      <div className="font-medium text-neutral-900">
                        {formatAmount(new Decimal(revenue).toFixed(2))}
                      </div>
                      <div> {currencySymbol(currency)}</div>
                    </div>
                  </div>
                ))}
            </Accordion.Panel>
          </Accordion.Item>
        )
      )}
    </Accordion>
  );
}

export default SumOverview;
