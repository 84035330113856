export function isCardPayment(currency: string, variable: string | null) {
  if (currency === "CZK" && variable === "3019") {
    return true;
  }

  if (currency === "EUR" && variable === "8324") {
    return true;
  }

  return false;
}

export function declineTransactions(number: number) {
  if (number <= 1 && number < 5) {
    return `transakce`;
  }

  return `transakcí`;
}

export function declineRecords(number: number) {
  if (number === 1) {
    return `záznam`;
  }

  if (1 < number && number < 5) {
    return `záznamy`;
  }

  return `záznamů`;
}

export function declineArticles(number: number) {
  if (number === 1) {
    return ["artikl již existuje", "artikl je připraven"];
  }

  if (1 < number && number < 5) {
    return [`artikly již existují`, "artikly jsou připraveny"];
  }

  return [`artiklů již existuje`, "artiklů je připraveno"];
}

export function declineInvalidRows(number: number) {
  if (number === 1) {
    return `neplatný řádek bude přeskočen`;
  }

  if (1 < number && number < 5) {
    return `neplatné řádky budou přeskočeny`;
  }

  return `neplatných řádků bude přeskočeno`;
}

export function noteExists(note: string | null) {
  if (note === null || note === "" || note === " ") {
    return false;
  }

  return true;
}

export function clipNote(note: string | null) {
  if (!note || note === " ") {
    return "";
  }

  if (note.substring(0, 7) === "Nákup: ") {
    return note.substring(7);
  }

  return note;
}

export function formatVariable(currency: string, variable: string | null) {
  if (!variable || isCardPayment(currency, variable)) {
    return "";
  }

  // if (isCardPayment(currency, variable)) {
  //   return "platba kartou";
  // }

  return `VS ${variable}`;
}
