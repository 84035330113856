import React, { useEffect, useState } from "react";
import { currencySymbol, formatAmount } from "../../../util/general";
import { Affiliation, Currency } from "../../../services/api";
import { Deduction } from "../../../types/types";

const DeductionForm = ({
  affiliations,
  deductions,
  setDeductions,
}: // currency,
{
  affiliations: Affiliation[];
  deductions: Deduction[];
  setDeductions: React.Dispatch<React.SetStateAction<Deduction[]>>;
  // currency: string;
}) => {
  const [deductionInput, setDeductionInput] = useState("");

  useEffect(() => console.log(deductions), [deductions]);

  function addDeduction() {
    const value = parseInt(deductionInput);

    if (isNaN(value)) {
      console.error("NaN given");
      return;
    }

    const affiliation = affiliations.find((a) => a.Id === value);
    setDeductionInput("");

    if (affiliation === undefined) {
      console.error("Affiliation with this ID not found");
      return;
    }

    // if (affiliation.Currency !== currency) {
    //   console.error("Affiliation found, but currency does not match");
    //   return;
    // }

    if (deductions.some((d) => d.Id === affiliation.Id)) {
      console.error("Affiliation already deducted");
      return;
    }

    setDeductions([
      ...deductions,
      {
        Id: affiliation.Id,
        Name: affiliation.Title,
        Amount: affiliation.Amount,
        VATDeduction: affiliation.VATDeduction,
        Currency: affiliation.Currency as Currency,
      },
    ]);
  }

  function removeDeduction(id: number) {
    setDeductions(deductions.filter((d) => d.Id !== id));
  }

  return (
    <div className="flex flex-col w-full">
      {deductions.map((d) => (
        <div key={d.Id} className="flex items-center py-1 text-sm">
          <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
            <div className="flex text-sm">
              <p className="mr-2 font-medium truncate text-neutral-900">
                {d.Name}
              </p>
              <p
                className="font-normal cursor-pointer text-neutral-400 hover:text-neutral-900"
                onClick={() => removeDeduction(d.Id)}
              >
                ×
              </p>
            </div>
          </div>
          <div className="flex-shrink-0 text-neutral-500">
            <span className="font-medium text-neutral-900">
              {formatAmount(parseInt(d.Amount) / (1 + d.VATDeduction / 100))}
            </span>{" "}
            {currencySymbol(d.Currency)}
          </div>
        </div>
      ))}

      <div className="flex items-center py-1 text-sm">
        <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
          <div className="flex text-sm">
            <input
              type="text"
              id="affiliationId"
              value={deductionInput}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  addDeduction();
                }
              }}
              onChange={(e) => setDeductionInput(e.target.value)}
              className="block w-full px-3 py-2 mt-1 border shadow-sm  border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none sm:text-sm"
              placeholder="ID záznamu"
            />
          </div>
        </div>
        <div className="flex-shrink-0 text-neutral-500">
          <span
            className="font-medium cursor-pointer text-sky-600"
            onClick={addDeduction}
          >
            Přidat
          </span>
        </div>
      </div>
    </div>
  );
};

export default DeductionForm;
