import React from "react";
import { useQuery } from "react-query";
import { useApi } from "../../../App";
import Loading from "../../../components/Loading";
import {
  classNames,
  currencySymbol,
  formatAmount,
  formatDate,
} from "../../../util/general";
import {
  declineTransactions,
  formatVariable,
  noteExists,
} from "../../../util/transactions";
import ApproveForm from "./ApproveForm";
import { clipNote, isCardPayment } from "../../../util/transactions";
import {
  CreditCardIcon,
  BanknotesIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { CheckBadgeIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

const ApprovalQueue = () => {
  const api = useApi();
  const navigate = useNavigate();

  const { data: locations, isLoading: isLoadingLocations } = useQuery(
    ["locations"],
    async () => await api.locations.getLocations(),
    { select: (x) => x.data.locations }
  );

  const { data, isLoading, refetch } = useQuery(
    ["transactionToApprove"],
    async () => await api.transactions.getTransactionToApprove(),
    { select: (x) => x.data }
  );

  if (isLoading || isLoadingLocations) {
    return (
      <div className="flex justify-center py-16">
        <Loading />
      </div>
    );
  }

  if (locations === undefined || data === undefined) {
    console.error("locations or transaction is undefined");

    return (
      <div className="flex justify-center py-16">
        <div className="text-xl text-neutral-900">
          Something went wrong, see console for details
        </div>
      </div>
    );
  }

  const { transaction } = data;

  return (
    <div className="flex flex-col flex-1 pt-10 pb-16 bg-neutral-100">
      <main className="flex-1">
        <div className="h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
          <div className="flex items-end justify-between mt-6 mb-10">
            <div className="flex space-x-2">
              <button
                className="inline-flex items-center py-2 pl-3 pr-4 text-sm font-medium bg-white border shadow-sm text-neutral-700  border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600"
                onClick={() => navigate("/approve")}
              >
                <ChevronLeftIcon className="w-4 h-4 mr-2 text-neutral-700" />
                Odejít
              </button>
            </div>
            <div className="flex space-x-2">
              <div className="inline-flex items-center py-2 pl-3 pr-4 text-sm font-medium bg-white shadow text-neutral-900">
                <InformationCircleIcon className="w-5 h-5 mr-2 text-neutral-700" />

                {`celkem ${data.totalCount} ${declineTransactions(
                  data.totalCount
                )} ke schválení`}
              </div>
            </div>
          </div>

          <div className="mb-8">
            {transaction === null ? (
              <div className="px-6 py-12 overflow-hidden text-center bg-white shadow rounded-b-lgg">
                <CheckBadgeIcon className="w-12 h-12 mx-auto text-neutral-400" />

                <h3 className="mt-2 text-sm font-medium text-neutral-900">
                  Všechno jsi schválil.
                </h3>
              </div>
            ) : (
              <div className="overflow-visible border-b shadow border-neutral-200 ">
                <div className="grid w-full grid-cols-24">
                  <div className="flex items-center justify-start col-span-2 px-4 text-xs font-medium tracking-wider uppercase border-b rounded-tl-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    ID
                  </div>
                  <div className="flex items-center justify-start col-span-2 px-4 text-xs font-medium tracking-wider uppercase border-b text-neutral-500 border-neutral-200 bg-neutral-50">
                    Datum
                  </div>
                  <div className="px-4 py-3 text-xs font-medium tracking-wider text-left uppercase border-b col-span-17 text-neutral-500 border-neutral-200 bg-neutral-50">
                    Transakce
                  </div>
                  <div className="col-span-3 px-4 py-3 text-xs font-medium tracking-wider text-right uppercase border-b rounded-tl-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    Částka
                  </div>
                </div>

                <div
                  className={classNames(
                    "border-t border-neutral-300",
                    "grid w-full transition-colors bg-white grid-cols-24"
                  )}
                >
                  <div
                    className={classNames(
                      "px-4 py-2.5 col-span-2 text-sm text-left text-neutral-400 whitespace-nowrap border-b border-neutral-200"
                    )}
                  >
                    {`#${transaction.Id}`}
                  </div>
                  <div className="px-4 py-2.5 col-span-2 text-sm text-left text-neutral-500 whitespace-nowrap border-b border-neutral-200">
                    {formatDate(transaction.HappenedAt)}
                  </div>

                  <div className="col-span-17 px-4 py-2.5 text-sm font-medium text-neutral-900 whitespace-nowrap flex items-center overflow-hidden text-ellipsis border-b border-neutral-200">
                    <div className="flex text-sm truncate">
                      {isCardPayment(
                        transaction.Currency,
                        transaction.Variable
                      ) ? (
                        <CreditCardIcon
                          className="flex-shrink-0 w-5 h-5 mr-2 text-neutral-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <BanknotesIcon
                          className="flex-shrink-0 w-5 h-5 mr-2 text-neutral-400"
                          aria-hidden="true"
                        />
                      )}
                      {noteExists(transaction.Note) && (
                        <span
                          className="mr-2 truncate text-neutral-700"
                          title={transaction.Note || undefined}
                        >
                          {clipNote(transaction.Note)}
                        </span>
                      )}{" "}
                      <span className="font-normal text-neutral-400">
                        {formatVariable(
                          transaction.Currency,
                          transaction.Variable
                        )}
                      </span>
                    </div>
                  </div>

                  <div
                    className={
                      "justify-between flex text-sm col-span-3 px-4 text-right text-neutral-500 py-2.5 border-b border-neutral-200"
                    }
                  >
                    <div className="flex flex-row-reverse space-x-2 space-x-reverse">
                      {/* ICONS */}
                    </div>
                    <div className="font-medium text-neutral-900">
                      <span
                        className={
                          parseFloat(transaction.Amount) < 0
                            ? "text-red-600"
                            : parseFloat(transaction.Amount) > 0
                            ? "text-green-700"
                            : "text-neutral-900"
                        }
                      >
                        {formatAmount(transaction.Amount)}
                      </span>{" "}
                      {currencySymbol(transaction.Currency)}
                    </div>
                  </div>
                </div>

                <ApproveForm
                  refetch={refetch}
                  affiliations={transaction.Affiliations}
                  locations={locations}
                  transaction={transaction}
                />
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ApprovalQueue;
