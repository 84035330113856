import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import * as yup from "yup";
import { Location, ProjectInfo } from "../../../services/api";
import Loading from "../../../components/Loading";
import { LocationSelector } from "../../default-projects/components/Inputs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useApi } from "../../../App";
import { classNames } from "../../../util/general";
import { notifications } from "@mantine/notifications";
import { declineRecords } from "../../../util/transactions";

export default function MoveAffilsModal({
  open,
  setOpen,
  locations,
  currentProject,
  selectedRowIds,
  resetTable,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  locations?: Location[];
  currentProject: ProjectInfo;
  selectedRowIds: Record<string, boolean>;
  resetTable: () => void;
}) {
  const api = useApi();
  const cancelButtonRef = useRef(null);
  const [saving, setSaving] = useState(false);

  const selectedAffils = currentProject.Items.filter((_, index) =>
    Object.keys(selectedRowIds).map(Number).includes(index)
  );

  const validationSchema = yup.object().shape({
    targetProject: yup.number().nullable(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      targetProject: 0,
    },
  };

  const { setValue, handleSubmit, watch } = useForm(formOptions);

  async function onSubmit(data: { [x: string]: any }) {
    let targetProjectId = locations?.find(
      (location) => location.Id === data.targetProject
    )?.ProjectId;

    if (data.targetProject === 1) {
      targetProjectId = 0;
    }

    if (targetProjectId === undefined) {
      notifications.show({
        color: "#dc2626",
        title: "Chyba!",
        message: "Není vybrána cílová složka.",
      });

      return;
    } else {
      setSaving(true);

      const result = await api.transactions.categorizeAffiliations({
        subjectId: currentProject.Owner.Id,
        projectId: targetProjectId,
        affiliations: selectedAffils.map((a) => a.Id),
      });

      if (result.ok) {
        setSaving(false);
        setOpen(false);
        resetTable();

        notifications.show({
          color: "#22c55e",
          title: "Povedlo se!",
          message: "Záznamy byly přesunuty.",
        });
      } else {
        console.error(result.error);

        notifications.show({
          color: "#dc2626",
          title: "Něco se nepovedlo.",
          message: result.error?.message,
        });
      }
    }
  }

  if (!locations) {
    return (
      <div className="flex justify-center my-12">
        <Loading />
      </div>
    );
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 text-left transition-all transform bg-white shadow-xl overflow-shown sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="sm:flex sm:items-start">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ArrowRightCircleIcon
                        className="w-6 h-6 text-sky-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex-1 mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-neutral-900"
                      >
                        Přesunout označené
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-neutral-500">
                          Kam chcete přesunout {selectedAffils.length}{" "}
                          {declineRecords(selectedAffils.length)}?
                        </p>
                      </div>

                      <div className="flex flex-col flex-1 my-8 space-y-4">
                        <dl className="flex items-start justify-between w-full">
                          <dt className="block py-2 text-sm font-medium text-neutral-700">
                            Cílová složka
                          </dt>
                          <dd className="w-64">
                            <LocationSelector
                              valueKey="targetProject"
                              locations={locations}
                              watch={watch}
                              setValue={setValue}
                            />
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      disabled={saving}
                      className={classNames(
                        saving ? "cursor-not-allowed" : "cursor-pointer",
                        "inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                      )}
                    >
                      {saving && <Loading />}
                      Přesunout
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zpět
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
