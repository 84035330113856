import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { useApi } from "../../../App";
import Loading from "../../../components/Loading";
import { useQuery } from "react-query";
import {
  currencySymbol,
  formatAmount,
  formatDate,
} from "../../../util/general";
import { usePopper } from "react-popper";

const TransactionPopover = ({
  affiliationId,
  isVirtual,
}: {
  affiliationId: number;
  isVirtual: boolean;
}) => {
  const api = useApi();
  const [opened, setOpened] = useState(false);

  let [referenceElement, setReferenceElement] = useState<Element | null>();
  let [popperElement, setPopperElement] = useState<HTMLElement | null>();

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const { data: transaction, isLoading } = useQuery(
    ["transaction", affiliationId],
    async () =>
      await api.transactions.getTransactionByAffiliation(affiliationId),
    {
      select: (x) => x.data,
      enabled: opened,
    }
  );

  if (isVirtual) {
    return null;
  }

  return (
    <Popover className="relative flex items-center">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="underline cursor-pointer focus:outline-none"
            onClick={() => setOpened(!opened)}
          >
            <MagnifyingGlassCircleIcon
              className="w-5 h-5 ml-2 text-neutral-500"
              aria-hidden="true"
            />
          </Popover.Button>

          {open && (
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="absolute z-10 px-4 mt-3 transform translate-y-24 bg-white border opacity-100 w-96 border-sky-500/50 sm:px-0"
            >
              {isLoading || !transaction ? (
                <div className="flex justify-center my-12 bg-white">
                  <Loading />
                </div>
              ) : (
                <div className="relative flex flex-col p-4 space-y-4 bg-white ">
                  <div className="flex justify-between">
                    <div className="text-left text-neutral-400 whitespace-nowrap">
                      #{transaction.Id}
                    </div>
                    <div className="text-sm text-right text-neutral-500 whitespace-nowrap">
                      {formatDate(transaction.HappenedAt)}
                    </div>
                  </div>
                  <div className="flex text-sm font-medium text-left whitespace-normal text-neutral-900">
                    {transaction.Note}
                  </div>
                  <div className="flex justify-end font-medium text-right text-neutral-700">
                    <div className="font-medium text-neutral-900">
                      <span
                        className={
                          parseFloat(transaction.Amount) < 0
                            ? "text-red-600"
                            : parseFloat(transaction.Amount) > 0
                            ? "text-green-700"
                            : "text-neutral-900"
                        }
                      >
                        {formatAmount(transaction.Amount)}
                      </span>{" "}
                      {currencySymbol(transaction.Currency)}
                    </div>
                  </div>
                </div>
              )}
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};

export default TransactionPopover;
