import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import {
  formatAmount,
  currencySymbol,
  formatDate,
} from "../../../util/general";
import { WarehouseTSOA } from "../../../services/api";
import { usePopper } from "react-popper";

const WarehousePopover = ({ warehouse }: { warehouse: WarehouseTSOA[] }) => {
  let [referenceElement, setReferenceElement] = useState<Element | null>();
  let [popperElement, setPopperElement] = useState<HTMLElement | null>();

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [10, 0],
        },
      },
    ],
  });

  return (
    <Popover className="relative">
      <Popover.Button
        ref={setReferenceElement}
        className="underline cursor-pointer text-neutral-500 hover:no-underline focus:outline-none"
      >
        Log skladu
      </Popover.Button>

      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="absolute z-10 px-4 mt-3 transform -translate-x-1/2 translate-y-0 border opacity-100  w-96 border-neutral-200 sm:px-0"
      >
        <div className="relative flex flex-col px-3 py-4 bg-white ">
          <div className="flex items-center w-full px-2 mb-3 space-x-3">
            <h3 className="text-lg font-medium text-left truncate text-neutral-900">
              Log pohybů
            </h3>
            <div className="flex-1 h-px border-b border-neutral-300"></div>
          </div>

          {warehouse
            .filter((m) => m.Stock > 0)
            .map((movement) => (
              <div
                key={movement.Id}
                className="flex justify-between px-2 py-0.5 transition duration-150 ease-in-out  hover:bg-neutral-50 focus:outline-none"
              >
                <span className="font-medium">
                  {formatDate(movement.BuyingDate)}
                </span>
                <div className="flex items-start space-x-1">
                  <span className="font-medium">
                    {movement.Direction === "OUT" ? "-" : ""}
                    {movement.Stock}
                    {" ks"}
                  </span>
                  <span className="font-normal text-neutral-500">
                    á {formatAmount(movement.BuyingPrice, true)}{" "}
                    {currencySymbol(movement.Currency)}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default WarehousePopover;
