import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "../../../util/general";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "@headlessui/react";
import Loading from "../../../components/Loading";
import { endOfDay, formatISO } from "date-fns";

const EditMetadataModal = ({
  open,
  setOpen,
  title,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}) => {
  const [saving, setSaving] = useState(false);

  const validationSchema = yup.object().shape({
    expirationDate: yup.date().required(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    resetOptions: {
      keepDirtyValues: true,
    },
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  async function onSubmit(data: { [x: string]: any }) {
    setSaving(true);

    const groupCreation = {
      expirationDate: formatISO(endOfDay(data.expirationDate)),
    };

    // const response = await api.articles.groupCreateSingle(groupCreation);
    const response = { ok: true };

    if (response.ok) {
      console.log(response);
      setSaving(false);
      setOpen(false);
    } else {
      console.error(response);
    }
  }

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      open={open}
      onClose={() => {}}
    >
      <div className="flex items-end justify-center min-h-screen px-6 pt-6 pb-20 text-center sm:block sm:p-0">
        <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-700" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <>
          <div className="inline-block w-full max-w-xl my-12 text-left align-middle transition-all transform bg-white shadow-xl ">
            <div className="flex flex-col">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-6 my-8">
                  <Dialog.Title
                    as="h3"
                    className="mb-8 text-lg font-medium leading-6 text-neutral-900"
                  >
                    Změna expirace – {title.toLowerCase()}
                  </Dialog.Title>

                  {saving ? (
                    <div className="flex justify-center w-full h-full py-8">
                      <Loading />
                    </div>
                  ) : (
                    <div className="mt-2">
                      <div className="pt-5 pb-4 bg-white sm:pt-6 sm:pb-4">
                        <div className="flex flex-col flex-1 space-y-4">
                          <dl className="flex items-start justify-between w-full">
                            <dt className="block py-2 text-sm font-medium text-neutral-700">
                              <span>Nové datum expirace</span>
                            </dt>
                            <dd className="w-48">
                              <Controller
                                control={control}
                                defaultValue=""
                                name="expirationDate"
                                render={({ field: { value, onChange } }) => (
                                  <input
                                    onChange={onChange}
                                    type="date"
                                    className="block w-full px-3 py-2 mt-1 border shadow-sm border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none sm:text-sm"
                                  />
                                )}
                              />
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="py-3 w-full bg-neutral-50 px-6 h-[62px] flex flex-row-reverse">
                  <button
                    type="submit"
                    disabled={saving}
                    className={classNames(
                      saving && "invisible",
                      "inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent  shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                    )}
                  >
                    Uložit
                  </button>
                  <button
                    type="button"
                    disabled={saving}
                    className={classNames(
                      saving && "invisible",
                      "inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-neutral-700 bg-white border border-neutral-300  shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    )}
                    onClick={() => setOpen(false)}
                  >
                    Zrušit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      </div>
    </Dialog>
  );
};

export default EditMetadataModal;
