import React from "react";
import { DocumentHeader } from "../../../util/api";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { filenameFromLink } from "../../../util/document";

const DocumentPill = ({
  fileLink,
  index,
  remove,
}: {
  fileLink: string;
  index: number;
  remove: (index: number) => void;
}) => {
  return (
    <div className="inline-flex items-center pr-2 py-0.5 rounded-full text-xs font-medium bg-sky-100 text-sky-700 hover:bg-sky-200">
      <a
        href={fileLink}
        target="_blank"
        title="Přejít na dokument"
        className="pl-3 mr-1"
      >
        {filenameFromLink(fileLink)}
      </a>
      <button
        className="hover:text-sky-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
        title="Odstranit dokument"
        onClick={() => remove(index)}
      >
        <XCircleIcon className="w-4 h-4" />
      </button>
    </div>
  );
};

const DocumentRow = ({
  documents,
  remove,
  openDocModal,
}: {
  documents: DocumentHeader[];
  remove: (index: number) => void;
  openDocModal: () => void;
}) => {
  return (
    <>
      <div
        className={
          "pb-8 flex flex-col px-8 border-b col-span-24 bg-neutral-50 border-neutral-200"
        }
      >
        <div className="flex gap-x-2">
          {documents.map((document, index) => (
            <DocumentPill
              index={index}
              fileLink={document.FileLink}
              remove={remove}
              key={document.FileLink}
            />
          ))}

          <div className="inline-flex items-center pr-2 py-0.5 rounded-full text-xs font-medium bg-sky-100 text-sky-700 hover:bg-sky-200">
            <button
              className="flex pl-2 pr-1 space-x-1.5 hover:text-sky-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
              title="Přidat dokument"
              onClick={openDocModal}
            >
              <PlusCircleIcon className="w-4 h-4" />

              <span>Přidat dokument</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentRow;
