import { getMonth, getYear } from "date-fns";
import { ExactPeriod } from "../services/api";

export const externalPeriods = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export function periodsToString(periods: string[]) {
  if (periods.length === 0) return "\u00A0";

  if (periods.length === 3) {
    const numericPeriods = periods.map((p) => p.split("/").map(Number));

    if (
      numericPeriods[0][1] == numericPeriods[1][1] &&
      numericPeriods[0][1] == numericPeriods[2][1]
    ) {
      return `Q${Math.ceil(numericPeriods[0][0] / 3)}/${numericPeriods[0][1]}`;
    }
  }

  return `${periods.length} měsíc${
    periods.length === 1
      ? ""
      : periods.length > 1 && periods.length < 5
      ? "e"
      : "ů"
  }`;
}

export function quarterToMonths(quarter: number) {
  return [3 * quarter - 2, 3 * quarter - 1, 3 * quarter];
}

export function timeframeToExact(timeframe: string) {
  const [period, year] = timeframe.split("/");

  if (period.startsWith("Q")) {
    return quarterToMonths(parseInt(period[1])).map((m) => ({
      year: parseInt(year),
      month: m,
    }));
  } else {
    return [{ year: parseInt(year), month: parseInt(period) }];
  }
}

export function monthToTimeframe({
  month,
  year,
}: {
  month: string;
  year: string;
}) {
  return `${month.padStart(2, "0")}/${year}`;
}

export function externalToTimeframe(period: string, year: number) {
  if (period.startsWith("Q")) {
    return `${period}/${year}`;
  }

  return `${(externalPeriods.indexOf(period) + 1)
    .toString()
    .padStart(2, "0")}/${year}`;
}

function threeMonthsToQuarter(months: number[], year: number) {
  function compare<T>(a: T[], b: T[]) {
    return (
      a.length === b.length && a.every((value, index) => value === b[index])
    );
  }

  if (compare(months, [1, 2, 3])) return `Q1/${year}`;
  if (compare(months, [4, 5, 6])) return `Q2/${year}`;
  if (compare(months, [7, 8, 9])) return `Q3/${year}`;
  if (compare(months, [10, 11, 12])) return `Q4/${year}`;

  return `???/${year}`;
}

export function exactToTimeframe(periods: ExactPeriod[]) {
  let months = periods.map((p) => p.month).sort((a, b) => a - b);

  if (periods.length === 3) {
    return threeMonthsToQuarter(months, periods[0].year);
  }

  if (periods.length % 3 === 0) {
    const chunks = Array.from({ length: periods.length / 3 }, (_, i) =>
      months.slice(i * 3, i * 3 + 3)
    );

    return chunks
      .map((chunk) => threeMonthsToQuarter(chunk, periods[0].year))
      .join(", ");
  }

  return periods
    .map(
      (period) => `${period.month.toString().padStart(2, "0")}/${period.year}`
    )
    .join(",");
}

export function formatBillingPeriod(periods: ExactPeriod[]) {
  const grouped = periods.reduce((acc, period) => {
    if (!acc[period.year]) acc[period.year] = [];
    acc[period.year].push(period.month);
    return acc;
  }, {} as Record<number, number[]>);

  const sorted = Object.entries(grouped).map(([year, months]) => ({
    year: parseInt(year),
    months: months.sort((a, b) => a - b),
  }));

  return sorted
    .map(({ year, months }) =>
      months.length === 3
        ? `Q${Math.ceil(months[0] / 3)}/${year}`
        : months.length === 1
        ? `${months[0].toString().padStart(2, "0")}/${year}`
        : months.length === 2
        ? `${months[0].toString().padStart(2, "0")}-${months[1]
            .toString()
            .padStart(2, "0")}/${year}`
        : `${months[0].toString().padStart(2, "0")}-${months[months.length - 1]
            .toString()
            .padStart(2, "0")}/${year}`
    )
    .join(", ");
}
